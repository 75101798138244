import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import tramitesReducer from '../slices/tramitesSlice';
import uspostalTestReducer from '../slices/uspostaltestSlice';
import { userSlice } from '../slices/userSlice';

export const store = configureStore({
  reducer: {
    tramites: tramitesReducer,
    uspostalTests: uspostalTestReducer,
    user: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: tramitesReducer,
      },
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
