import { requestData } from "../_customers_ParcGuell";
import { useEffect, useState } from "react";
import { ParcGuellOrder } from "../types";

const status = [
  "completed",
  "await bot",
  "await ok",
  "without card",
  "await mail",
  "avaibility mail",
  "revised",
  "on hold",
  "refund",
  "to review",
  "payment incompleted",
];

const useParcGuellOrders = () => {
  const today = new Date();
  const [orders, setOrders] = useState<ParcGuellOrder[]>([]);
  const [individualSearch, setIndividualSearch] = useState(false);
  const [edit, setEdit] = useState(false);
  const [filters, setFilters] = useState({
    minCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    maxCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    status: status,
    indCriteria: "email",
    indValue: "",
  });

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  const loadOrders = async () => {
    const apiOrders = await requestData(filters);
    setOrders(addAditionalFields(apiOrders));
  };

  const addAditionalFields = (apiOrders: ParcGuellOrder[]) => {
    const modifiedOrders = apiOrders.map((el: any, i: number) => {
      const panelOrder = {
        ...el,
        client: el.client || {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          country: "",
          postcode: "",
        },
        fullName: el.client
          ? `${el.client.firstName} ${el.client.lastName}`
          : "",
        email: el.client.email,
        createdAt: new Date(el.createdAt).toLocaleString(),
      };

      return panelOrder;
    });

    return modifiedOrders;
  };

  const search = async (one: boolean) => {
    const newData = await requestData(filters, one ? true : false);
    setIndividualSearch(one ? true : false);
    setOrders(addAditionalFields(newData));
    setEdit(!edit);
  };

  return { orders, filters, edit, setEdit, setFilters, loadOrders, search };
};

export default useParcGuellOrders;
