import {
  CBadge,
  CButton,
  CCardBody,
  CCol,
  CCollapse,
  CContainer,
  CDataTable,
  CForm,
  CFormGroup,
  CRow,
  CSpinner,
} from "@coreui/react";
import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { IsLogin } from "../../../features/login/IsLogin";
import { Search } from "../../../features/search/Search";
import { CustomSelect } from "../../../features/select/Select";
import { Spinner } from "../../../features/spinner/Spinner";
import { TramitesSelector } from "../../../slices/tramitesSlice";
import {
  changeStatus,
  data,
  dateProtection,
  remove,
  retry,
  provincias,
  downloadMail,
  downloadScreenshot,
  resendBuyEmail,
} from "./_customersTramites";
import { SpecificSearch } from "./../../../features/search/SpecificSearch";
import { TE_RefundForm } from "./TramitesRefundForm";
import { ConfirmEdit } from "./ConfirmEdit";
import { SubordinateOrderForm } from "./SubordinateOrderForm";
import { reportToXlsx } from '../../../features/report-xlsx/report-xlsx'

type OrderInputs = {
  name: string;
  surname: string;
  secondSurname: string;
  dni: string;
  dniType: string;
  caducidad: string;
  numeroSeguridadSocial1: string;
  numeroSeguridadSocial2: string;
  date: string;
  mobile: string;
  email: string;
  tipoVia: string;
  numero: string;
  bis: string;
  bloque: string;
  escalera: string;
  piso: string;
  puerta: string;
  cp: string;
  nombreVia: string;
  localidad: string;
  provincia: string;
  pais: string;
  tipoSolicitud: string;
  orderID: string;
  project: string;
  sms: string;
  numeroReferenciaSS: string;
  date_created: string;
  order_total: string;
  payment_method: string;
  status: string;
  comment: string;
  error: string;
  domain: string;
  attempts: string;
  numeroBeneficiarios: string;
  tag: string;
  ben1: string;
  ben2: string;
  ben3: string;
  ben4: string;
  ben5: string;
  flow: string;
  logs: string;
};

export function CustomersTramites() {
  IsLogin();

  const { tramites, loading } = useSelector(TramitesSelector);
  const defaultOptions = [
    { value: "dni error", label: "dni error" },
    { value: "not registered", label: "not registered" },
    { value: "without mobile", label: "without mobile" },
    { value: "rejected", label: "rejected" },
    { value: "time out", label: "time out" },
    { value: "failed", label: "failed" },
    { value: "failed address", label: "failed address" },
    { value: "failed no match", label: "falied no match" },
    { value: "valid card", label: "valid card" },
    { value: "revised", label: "revised" },
    { value: "completed", label: "completed" },
    { value: "refund", label: "refund" },
    { value: "on hold", label: "on hold" },
    { value: "await response", label: "await response" },
    { value: "await bot", label: "await bot" },
  ];
  const options = [{ value: "pending", label: "pending" }, ...defaultOptions];

  const [details, setDetails]: any[] = useState([]);
  const [items, setItems] = useState(tramites);
  const [showModal, setShowModal] = useState(false);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [edit, setEdit] = useState(false);
  const [orderModalData, setOrderModalData] = useState({
    value: "",
    project: "",
    id: "",
    order_total: "",
  });
  const [subordinateOrderData, setSubordinateOrderData] = useState({
    parentId: "",
    email: "",
    tipovia: "",
    nombrevia: "",
    numero: "",
    bis: "",
    bloque: "",
    escalera: "",
    piso: "",
    puerta: "",
    cp: "",
    provincia: "",
    localidad: "",
    pais: "",
    ben1: {},
    ben2: {},
    ben3: {},
    ben4: {},
    ben5: {},
  });

  const toggleDetails = (index: any) => {
    const position = details.indexOf(index);
    setToggle(!toggle);

    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const refundButtonhandler = (e: React.ChangeEvent<HTMLButtonElement>) => {
    const { value } = e.target;
    const { project, id, order_total } = e.target.dataset;
    if (project && id && order_total) {
      setOrderModalData({ value, project, id, order_total });
      setShowModal(!showModal);
    }
  };

  const subordinateButtonHandler = (
    e: React.ChangeEvent<HTMLButtonElement>
  ) => {
    const {
      id,
      email,
      tipovia,
      nombrevia,
      numero,
      bis,
      bloque,
      escalera,
      piso,
      puerta,
      cp,
      provincia,
      localidad,
      pais,
      ben1,
      ben2,
      ben3,
      ben4,
      ben5,
    } = e.target.dataset;
    if (email && id) {
      setSubordinateOrderData({
        parentId: id,
        email,
        // @ts-ignore
        tipovia,
        // @ts-ignore
        nombrevia,
        // @ts-ignore
        numero,
        // @ts-ignore
        bis,
        // @ts-ignore
        bloque,
        // @ts-ignore
        escalera,
        // @ts-ignore
        piso,
        // @ts-ignore
        puerta,
        // @ts-ignore
        cp,
        // @ts-ignore
        provincia,
        // @ts-ignore
        localidad,
        // @ts-ignore
        pais,
        // @ts-ignore
        ben1,
        // @ts-ignore
        ben2,
        // @ts-ignore
        ben3,
        // @ts-ignore
        ben4,
        // @ts-ignore
        ben5,
      });
      setShowOrderForm(!showOrderForm);
    }
  };

  const onPageChangeHandler = () => {
    setToggle(!toggle);
  };

  const fields = [
    { key: "parentId", label: "Subordinate", _style: { width: "1%" } },
    { key: "fullName", label: "name", _style: { width: "50%" } },
    { key: "date_created", label: "date created" },
    { key: "numeroReferenciaSS", label: "SS ref number" },
    { key: "dni" },
    { key: "email" },
    { key: "tag", _style: { width: "10%" } },
    { key: "status", _style: { width: "10%" } },
    {
      key: "show_details",
      label: "",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];

  const getBadge = (status: any) => {
    switch (status) {
      case "revised":
        return "success";
      case "completed":
        return "success";
      case "pending":
        return "primary";
      case "time out":
        return "warning";
      case "failed":
        return "danger";
      case "failed address":
        return "danger";
      case "valid card":
        return "danger";
      case "failed no match":
        return "danger";
      case "refund":
        return "secondary";
      case "on hold":
        return "danger";
      case "await response":
        return "primary";
      default:
        return "primary";
    }
  };

  const HandleReportToXlsx = () => {
    reportToXlsx(items);
  };

  useEffect(() => {
    const usersData = data(tramites);
    setItems(usersData);
  }, [tramites]);

  return (
    <CContainer>
      <CCol className="d-flex mb-1" xs="12" md="12">
        <CustomSelect options={["Tarjeta sanitaria", "Vida laboral"]} />
        <Search showCompare={false} />
      </CCol>
      <CRow className="mb-5 col-sm-10">
        <CCol sm="12">
          <CForm className="d-flex" action="" method="post">
            <CFormGroup variant="checkbox" className="d-flex ">
              <Select
                defaultValue={defaultOptions}
                options={options}
                isMulti={true}
                placeholder="Select status..."
              />
            </CFormGroup>
          </CForm>
        </CCol>
      </CRow>
      <SpecificSearch options={["name", "email", "orderid", "numberrefss"]} />
      <div className="d-flex">
        <CButton
          onClick={HandleReportToXlsx}
          size="sm"
          color="success"
          className="ml-1"
        >
          Download report
        </CButton>
      </div>
      <Spinner visible={loading} />
      {loading ? (
        ""
      ) : (
        <>
          <CDataTable
            items={items}
            fields={fields}
            columnFilter
            tableFilter
            footer
            itemsPerPageSelect
            itemsPerPage={10}
            hover
            sorter
            pagination
            onPageChange={onPageChangeHandler}
            scopedSlots={{
              parentId: (item: any) => (
                <td style={{ color: "rgb(42, 170, 82)" }}>
                  {item.parentId ? "true" : ""}
                </td>
              ),
              status: (item: any) => (
                <td>
                  <CBadge color={getBadge(item.status)}>{item.status}</CBadge>
                </td>
              ),
              show_details: (item: any, index: any) => {
                return (
                  <td className="py-2">
                    <CButton
                      key={item.id}
                      color="primary"
                      variant="outline"
                      shape="square"
                      size="sm"
                      onClick={() => {
                        toggleDetails(index);
                      }}
                    >
                      {details.includes(index) ? "Hide" : "Show"}
                    </CButton>
                  </td>
                );
              },
              details: (item: any, index: any) => {
                return (
                  <CCollapse show={details.includes(index)}>
                    <CCardBody>
                      <div className="d-flex flex-column justify-content-start">
                        <div className="mb-4">
                          <CButton
                            onClick={retry}
                            data-id={item.id}
                            data-project={item.project}
                            size="sm"
                            color="info"
                          >
                            Retry
                          </CButton>
                          <CButton
                            onClick={remove}
                            data-id={item.id}
                            data-project={item.project}
                            size="sm"
                            color="danger"
                            className="ml-1"
                          >
                            Permanent Delete
                          </CButton>
                          <CButton
                            onClick={dateProtection}
                            data-id={item.id}
                            data-project={item.project}
                            size="sm"
                            color="danger"
                            className="ml-1"
                          >
                            Date Protection
                          </CButton>
                          {/* <CButton onClick={editable} data-id={item.id} data-project={item.project} value="completed" size="sm" color="warning" className="ml-1">
                            Edit order
                          </CButton> */}
                          {/* <CButton onClick={() => setEdit(!edit)} data-id={item.id} data-project={item.project} value="completed" size="sm" color="warning" className="ml-1">
                            Enable Edit
                          </CButton> */}
                          {item.project ===
                            "tramitartarjetasanitariaeuropea-com" &&
                            item.saleid && (
                              <CButton
                                onClick={refundButtonhandler}
                                data-id={item.id}
                                data-project={item.project}
                                data-order_total={item.order_total.substring(
                                  0,
                                  item.order_total.length - 1
                                )}
                                value="refund"
                                size="sm"
                                color="secondary"
                                className="ml-1"
                              >
                                Refund New
                              </CButton>
                            )}
                          <CButton
                            onClick={changeStatus}
                            data-id={item.id}
                            data-project={item.project}
                            value="refund"
                            size="sm"
                            color="secondary"
                            className="ml-1"
                          >
                            Refund
                          </CButton>
                          <CButton
                            onClick={downloadMail}
                            id={item.id}
                            data-project={item.project}
                            value={item.email}
                            size="sm"
                            color="info"
                            className="ml-1"
                          >
                            Download mail
                          </CButton>
                          <CButton
                            onClick={downloadScreenshot}
                            id={item.orderID}
                            data-project={item.project}
                            value={item.id}
                            size="sm"
                            color="info"
                            className="ml-1"
                          >
                            Download screenshot
                          </CButton>
                          <CButton
                            onClick={resendBuyEmail}
                            id={item.orderID}
                            data-project={item.project}
                            value={item.id}
                            size="sm"
                            color="info"
                            className="ml-1"
                          >
                            Resend Email
                          </CButton>
                          {!item.parentId &&
                            item.tipoSolicitud !== "#SOLO_TITULAR" && (
                              <CButton
                                onClick={subordinateButtonHandler}
                                data-id={item.id}
                                data-email={item.email}
                                data-tipovia={item.tipoVia}
                                data-nombrevia={item.nombreVia}
                                data-numero={item.numero}
                                data-bis={item.bis}
                                data-bloque={item.bloque}
                                data-escalera={item.escalera}
                                data-piso={item.piso}
                                data-puerta={item.puerta}
                                data-cp={item.cp}
                                data-provincia={item.provincia}
                                data-localidad={item.localidad}
                                data-pais={item.pais}
                                data-ben1={JSON.stringify(item.ben1)}
                                data-ben2={JSON.stringify(item.ben2)}
                                data-ben3={JSON.stringify(item.ben3)}
                                data-ben4={JSON.stringify(item.ben4)}
                                data-ben5={JSON.stringify(item.ben5)}
                                size="sm"
                                color="info"
                                className="ml-1"
                              >
                                Add Subordinate Order
                              </CButton>
                            )}
                          <div className="d-block">
                            <CSpinner
                              data-loader={item.id}
                              className="download m-auto none"
                              color="info"
                            />
                          </div>
                        </div>
                        <Tabs
                          toggle={toggle}
                          generalEdit={edit}
                          setGeneralEdit={setEdit}
                          data={item}
                        />

                        {item.logs && <Logs logs={item.logs} />}
                      </div>
                      <div id="page"></div>
                    </CCardBody>
                  </CCollapse>
                );
              },
            }}
          />
          <TE_RefundForm
            show={showModal}
            setShow={setShowModal}
            orderData={orderModalData}
          />
          <SubordinateOrderForm
            show={showOrderForm}
            setShow={setShowOrderForm}
            parentOrder={subordinateOrderData}
          />
        </>
      )}
    </CContainer>
  );
}

type LogsProps = {
  logs: {
    _id: string;
    fecha: string;
    hora: string;
    flujo: string;
    accion: string;
    errores: string;
    status: string;
    agente: string;
  }[];
};

const Logs: React.FC<LogsProps> = ({ logs }) => {
  const [showLogs, setShowLogs] = useState(false);
  const colors: { [key: string]: string } = {
    "Edit order": "#FFCC0F",
    refund: "#CED2D8",
    "download mail": "#0D86FF",
    "date protection": "#E03232",
    retry: "#0D86FF",
  };
  const renderLogs = (log: any) => {
    // const details = "";
    const details =
      log.info && log.status !== "refund" && log.info.split("|")[0] === "update" //! Be careful status dependency
        ? editDetails(log.info)
        : log.info;
    return (
      <tbody key={log._id}>
        <tr
          style={{
            background:
              log.accion.split(":")[0] === "New Attempt"
                ? "#4f5d73"
                : colors[log.accion],
            color:
              log.accion === "download mail" ||
              log.accion.split(":")[0] === "New Attempt"
                ? "#ffffff"
                : "",
          }}
        >
          <th scope="row">{log.fecha}</th>
          <td>{log.hora}</td>
          <td>{log.flujo}</td>
          <td>{log.accion}</td>
          <td>{log.errores}</td>
          <td>{log.status}</td>
          <td>{log.agente}</td>
        </tr>
        {log.info ? (
          <tr>
            <th colSpan={7}>&#8593; Details &#8593;</th>
          </tr>
        ) : null}
        {log.info ? details : null}
      </tbody>
    );
  };

  const editDetails = (info: any) => {
    const row: any[] = info.split("|");
    row.shift();

    return row.map((el, i) => {
      const rowInfo = el.split("//");
      return (
        <tr key={i}>
          <td colSpan={3}>{rowInfo[0]}</td>
          {/* <td colSpan={4}>{rowInfo[1].replace(";", " --> ")}</td> */}
          <td colSpan={4}>
            {rowInfo[1]
              ? rowInfo[1].replace(";", " --> ")
              : " | Error al registrar cambios |"}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <button
        className="btn btn-primary"
        type="button"
        data-coreui-toggle="collapse"
        data-coreui-target="#logs"
        aria-expanded="false"
        aria-controls="logs"
        onClick={() => setShowLogs(!showLogs)}
      >
        {!showLogs ? "Show Logs" : "Hide Logs"}
      </button>
      <CCollapse show={showLogs}>
        <table className="table table-sm table-light mt-2 table-borderless">
          <thead className="thead-dark">
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Flujo</th>
              <th>Acción</th>
              <th>Tags</th>
              <th>Status</th>
              <th>Agente</th>
            </tr>
          </thead>
          {(logs && logs.length) > 0 ? (
            logs.map(renderLogs)
          ) : (
            <tbody>
              <tr>
                <td colSpan={7}>This order has no logs.</td>
              </tr>
            </tbody>
          )}
        </table>
      </CCollapse>
    </>
  );
};

type Props = {
  data: {
    id: string;
    nSoporte: string;
    name: string;
    surname: string;
    secondSurname: string;
    dni: string;
    dniType: string;
    caducidad: string;
    numeroSeguridadSocial1: string;
    numeroSeguridadSocial2: string;
    date: string;
    mobile: string;
    email: string;
    tipoVia: string;
    numero: string;
    bis: string;
    bloque: string;
    escalera: string;
    piso: string;
    puerta: string;
    cp: string;
    nombreVia: string;
    localidad: string;
    provincia: string;
    pais: string;
    tipoSolicitud: string;
    orderID: string;
    project: string;
    sms: string;
    numeroReferenciaSS: string;
    date_created: string;
    order_total: string;
    payment_method: string;
    status: string;
    comment: string;
    error: string;
    domain: string;
    attempts: string;
    numeroBeneficiarios: string;
    tag: string;
    ben1: any;
    ben2: any;
    ben3: any;
    ben4: any;
    ben5: any;
    flow: string;
    logs: any;
  };
  generalEdit: boolean;
  setGeneralEdit: Function;
  toggle: boolean;
};

const Tabs: React.FC<Props> = ({
  data,
  generalEdit,
  toggle,
  setGeneralEdit,
}) => {
  const {
    id,
    nSoporte,
    name,
    surname,
    secondSurname,
    dni,
    dniType,
    caducidad,
    numeroSeguridadSocial1: ssNum1,
    numeroSeguridadSocial2: ssNum2,
    date,
    mobile,
    email,
    tipoVia,
    numero,
    bis,
    bloque,
    escalera,
    piso,
    puerta,
    cp,
    nombreVia,
    localidad,
    provincia: province,
    pais,
    tipoSolicitud,
    orderID,
    project,
    sms,
    numeroReferenciaSS,
    date_created,
    order_total,
    payment_method,
    status,
    comment,
    error,
    domain,
    attempts,
    numeroBeneficiarios,
    tag,
    ben1,
    ben2,
    ben3,
    ben4,
    ben5,
    flow,
  } = data;
  const inputStyle = {
    backgroundColor: "transparent",
    border: 0,
    borderBottom: "1px solid black",
  };
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    setEdit(false);
  }, [toggle]);
  const setDefaultValues = () => {
    return {
      nombre: name,
      primerApellido: surname,
      segundoApellido: secondSurname,
      movil: mobile,
      date,
      parte1NAf: ssNum1,
      parte2NAf: ssNum2,
      email,
      tipo: dniType,
      numeroDni: dni,
      caducidad: caducidad,
      tipoVia,
      nombreVia,
      numero,
      bis,
      bloque,
      escalera,
      piso,
      puerta,
      cp,
      provincia: province,
      localidad,
      pais,
      sms,
      numeroReferenciaSS,
      status,
      comment,
      error,
      tipoSolicitud,
    };
  };
  const dataPreview = setDefaultValues();
  const [form, setForm] = useState(setDefaultValues);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const statusOptions = [
    "pending",
    "await bot",
    "valid card",
    "time out",
    "failed",
    "failed address",
    "failed no match",
    "completed",
    "revised",
    "refund",
    "on hold",
    "dni error",
    "not registered",
    "without mobile",
    "rejected",
    "await response",
  ];
  const requestTypeOptions = [
    {
      val: "#SOLO_TITULAR",
      text: "Sólo para el titular",
    },
    {
      val: "#TITULAR_Y_BENEFICIARIO",
      text: "Para el titular y beneficiarios",
    },
    {
      val: "#SOLO_BENEFICIARIO",
      text: "Sólo para beneficiarios",
    },
  ];

  const onInputChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onEditToggle = (): void => {
    setForm(setDefaultValues);
    setEdit(!edit);
    edit ? setGeneralEdit(true) : setGeneralEdit(false);
  };

  const onSubmitForm = (e: any): void => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  return (
    <>
      <form onSubmit={onSubmitForm} data-id={id} data-project={project}>
        <CButton
          onClick={onEditToggle}
          data-id={id}
          data-project={project}
          value="completed"
          size="sm"
          color="warning"
          className="ml-1"
        >
          {edit ? "Disable edit" : "Enable Edit"}
        </CButton>
        <div className="d-flex justify-content-between p-2">
          <div className="d-flex flex-row text-left w-100">
            <table className="table table-light table-striped table-borderless table-hover mr-3">
              <thead className="thead-dark">
                <tr>
                  <th colSpan={2} className="text-center">
                    Personal information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="pl-4" scope="row">
                    Name
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={name}>
                      <input
                        value={form.nombre}
                        name="nombre"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    F. Surname
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={surname}>
                      <input
                        value={form.primerApellido}
                        name="primerApellido"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    L. Surname
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={secondSurname}>
                      <input
                        value={form.segundoApellido}
                        name="segundoApellido"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    NIF Type
                  </th>
                  <td className="text-right pr-4">
                    <TableSelect
                      toggle={edit}
                      val={dniType == "1" ? "DNI" : "NIE"}
                    >
                      <select
                        value={form.tipo}
                        name="tipo"
                        onChange={onInputChange}
                        style={inputStyle}
                      >
                        <option value="1">DNI</option>
                        <option value="6">NIE</option>
                      </select>
                    </TableSelect>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    NIF
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={dni}>
                      <input
                        value={form.numeroDni}
                        name="numeroDni"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    NIF Expiration
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={caducidad}>
                      <input
                        value={form.caducidad}
                        name="caducidad"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Número Soporte
                  </th>
                  <td className="text-right pr-4">{data.nSoporte}</td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    SS Number
                  </th>
                  <td className="text-right pr-4">
                    {edit ? (
                      <Fragment>
                        <TableInput toggle={edit} val={ssNum1}>
                          <input
                            value={form.parte1NAf}
                            name="parte1NAf"
                            onChange={onInputChange}
                            style={inputStyle}
                            maxLength={2}
                            type="number"
                          />
                        </TableInput>
                        <TableInput toggle={edit} val={ssNum2}>
                          <input
                            value={form.parte2NAf}
                            name="parte2NAf"
                            onChange={onInputChange}
                            style={inputStyle}
                            maxLength={10}
                            type="number"
                          />
                        </TableInput>
                      </Fragment>
                    ) : (
                      `${ssNum1} ${ssNum2}`
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Birth date
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={date}>
                      <input
                        value={form.date}
                        name="date"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Mobile
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={mobile}>
                      <input
                        value={form.movil}
                        name="movil"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Email
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={email}>
                      <input
                        value={form.email}
                        name="email"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-light table-striped table-borderless table-hover ml-3">
              <thead className="thead-dark">
                <tr>
                  <th colSpan={2} className="text-center">
                    Address information
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="pl-4" scope="row">
                    Road Type
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={tipoVia}>
                      <input
                        value={form.tipoVia}
                        name="tipoVia"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Road Name
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={nombreVia}>
                      <input
                        value={form.nombreVia}
                        name="nombreVia"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Road Number
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={numero}>
                      <input
                        value={form.numero}
                        name="numero"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Bis
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={bis}>
                      <input
                        value={form.bis}
                        name="bis"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Block
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={bloque}>
                      <input
                        value={form.bloque}
                        name="bloque"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Stairs
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} name="escalera" val={escalera}>
                      <input
                        value={form.escalera}
                        name="escalera"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Door
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} name="puerta" val={puerta}>
                      <input
                        value={form.puerta}
                        name="puerta"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Postal Code
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={cp}>
                      <input
                        value={form.cp}
                        name="cp"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Floor
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={piso}>
                      <input
                        value={form.piso}
                        name="piso"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Localidad
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={localidad}>
                      <input
                        value={form.localidad}
                        name="localidad"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Province
                  </th>
                  <td className="text-right pr-4">
                    {province ? (
                      <TableSelect
                        toggle={edit}
                        val={
                          provincias().filter((e) => e[0] === province)[0][1]
                        }
                      >
                        <select
                          value={form.provincia}
                          name="provincia"
                          onChange={onInputChange}
                          style={inputStyle}
                        >
                          {provincias().map((e, i) => (
                            <option key={i} value={e[0]}>
                              {e[1]}
                            </option>
                          ))}
                        </select>
                      </TableSelect>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Pais
                  </th>
                  <td className="text-right pr-4">
                    <TableInput toggle={edit} val={pais}>
                      <input
                        value={form.pais}
                        name="pais"
                        onChange={onInputChange}
                        style={inputStyle}
                        type="text"
                      />
                    </TableInput>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <table className="table table-light table-striped table-borderless table-hover">
          <thead className="thead-dark">
            <tr>
              <th colSpan={4} className="text-center">
                Información de orden
              </th>
            </tr>
          </thead>
          <tbody className="text-left">
            <tr>
              <th className="w-25 pl-4" scope="row">
                Status
              </th>
              <td className="w-25 text-right pr-4">
                <TableSelect toggle={edit} val={status}>
                  <select
                    value={form.status}
                    name="status"
                    onChange={onInputChange}
                    style={inputStyle}
                  >
                    {statusOptions.map((status, i) => (
                      <option key={i} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </TableSelect>
              </td>
              <th className="w-25 pl-4" scope="row">
                OrderID
              </th>
              <td className="w-25 text-right pr-4">
                <span>{orderID}</span>
              </td>
            </tr>
            <tr>
              <th className="pl-4" scope="row">
                Domain
              </th>
              <td className="text-right pr-4">{domain}</td>
              <th className="pl-4" scope="row">
                Created at
              </th>
              <td className="text-right pr-4">{date_created}</td>
            </tr>
            <tr>
              <th className="pl-4" scope="row">
                Payment method
              </th>
              <td className="text-right pr-4">{payment_method}</td>
              <th className="pl-4" scope="row">
                Order total
              </th>
              <td className="text-right pr-4">{order_total}</td>
            </tr>
            <tr>
              <th className="pl-4" scope="row">
                Project
              </th>
              <td className="text-right pr-4">{project}</td>
              <th className="pl-4" scope="row">
                SMS
              </th>
              <td className="text-right pr-4">
                <TableInput toggle={edit} val={sms}>
                  <input
                    value={form.sms}
                    name="sms"
                    onChange={onInputChange}
                    style={inputStyle}
                    type="text"
                  />
                </TableInput>
              </td>
            </tr>
            <tr>
              <th className="pl-4" scope="row">
                Nº Referecia SS
              </th>
              <td className="text-right pr-4">
                <TableInput toggle={edit} val={numeroReferenciaSS}>
                  <input
                    value={form.numeroReferenciaSS}
                    name="numeroReferenciaSS"
                    onChange={onInputChange}
                    style={inputStyle}
                    type="text"
                  />
                </TableInput>
              </td>
              <th className="pl-4" scope="row">
                Attemps
              </th>
              <td className="text-right pr-4">{attempts}</td>
            </tr>
            <tr>
              <th className="pl-4" scope="row">
                Flow
              </th>
              <td className="text-right pr-4">{flow}</td>
              <th className="pl-4" scope="row">
                Tag
              </th>
              <td className="text-right pr-4">{tag}</td>
            </tr>
            <tr>
              <th className="pl-4" scope="row">
                Request type
              </th>
              <td className="text-right pr-4">
                <TableSelect toggle={edit} val={tipoSolicitud}>
                  <select
                    value={form.tipoSolicitud}
                    name="tipoSolicitud"
                    onChange={onInputChange}
                    style={inputStyle}
                  >
                    {requestTypeOptions.map((el, i) => (
                      <option key={i} value={el.val}>
                        {el.text}
                      </option>
                    ))}
                  </select>
                </TableSelect>
              </td>
              <th className="pl-4" scope="row">
                Number of Beneficiaries
              </th>
              <td className="text-right pr-4">{numeroBeneficiarios}</td>
            </tr>
            <tr>
              <th className="pl-4" scope="row">
                Error
              </th>
              <td className="text-right pr-4">
                <TableArea toggle={edit} val={error}>
                  <textarea
                    value={form.error}
                    name="error"
                    onChange={onInputChange}
                    cols={30}
                    rows={5}
                  ></textarea>
                </TableArea>
              </td>
              <th className="pl-4" scope="row">
                Comment
              </th>
              <td className="text-right pr-4">
                <TableArea toggle={edit} val={comment}>
                  <textarea
                    value={form.comment}
                    name="comment"
                    onChange={onInputChange}
                    cols={30}
                    rows={5}
                  ></textarea>
                </TableArea>
              </td>
            </tr>
          </tbody>
        </table>
        {ben1 && ben1.nombre && (
          <table className="table table-light table-striped table-borderless table-hover">
            <thead className="thead-dark">
              <tr>
                <th colSpan={5}>Beneficiaries</th>
              </tr>
            </thead>
            <tbody>
              {[ben1, ben2, ben3, ben4, ben5].map((ben, index) => {
                return (
                  ben &&
                  ben.nombre && (
                    <React.Fragment key={index}>
                      <tr>
                        <th
                          scope="row"
                          rowSpan={3}
                          style={{ verticalAlign: "middle" }}
                        >
                          #{index + 1}
                        </th>
                        <th>Name</th>
                        <td>
                          {/* <TableInput toggle={edit} name={`ben${index + 1}nombre`} val={"null" || ben.nombre} /> */}
                          {ben.nombre}
                        </td>
                        <th>Birth Date</th>
                        <td>
                          {/* <TableInput toggle={edit} name={`ben${index + 1}fechaNacimiento`} val={"null" || ben.fechaNacimiento} /> */}
                          {ben.fechaNacimiento}
                        </td>
                      </tr>
                      <tr>
                        <th>F. Surname</th>
                        <td>
                          {/* <TableInput toggle={edit} name={`ben${index + 1}apellido1`} val={"null" || ben.primerApellido} /> */}
                          {ben.primerApellido}
                        </td>
                        <th>Tipo NIF</th>
                        <td>
                          {/* <TableInput toggle={edit} name={`ben${index + 1}typedni`} val={"null" || ben.identificacion.tipo} /> */}
                          {ben.identificacion && ben.identificacion.tipo
                            ? ben.identificacion.tipo === "1"
                              ? "DNI"
                              : ben.identificacion.tipo === "6"
                              ? "NIE"
                              : ""
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <th>L. Surname</th>
                        <td>
                          {/* <TableInput toggle={edit} name={`ben${index + 1}apellido2`} val={"null" || ben.segundoApellido} /> */}
                          {ben.segundoApellido}
                        </td>
                        <th>NIF</th>
                        <td>
                          {/* <TableInput toggle={edit} name={`ben${index + 1}dni`} val={"null" || ben.identificacion.numero} /> */}
                          {ben.identificacion.numero}
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                );
              })}
            </tbody>
          </table>
        )}
        {edit && (
          <CButton
            type="submit"
            data-id={id}
            data-project={project}
            size="sm"
            color="warning"
            className="ml-1"
          >
            Save
          </CButton>
        )}
      </form>
      <ConfirmEdit
        setEdit={setEdit}
        operationType="Edit"
        id={id}
        project={project}
        data={form}
        show={showConfirmation}
        preview={dataPreview}
        setShow={setShowConfirmation}
      />
    </>
  );
};

interface ITableInputProps {
  toggle: boolean;
  val: string;
  [x: string]: any;
}

const TableInput = ({
  toggle,
  val,
  children,
}: ITableInputProps): JSX.Element => {
  return toggle ? children : <>{val}</>;
};

const TableSelect = ({ toggle, val, children }: ITableInputProps) => {
  return toggle ? children : <>{val}</>;
  // children : <>{name === 'dniType' ? val === "1" ? 'DNI' : 'NIE' : name !== 'provincia' ? val : getCurrentOption(val, opts)}</>
};

const TableArea = ({ toggle, val, children }: ITableInputProps) => {
  return toggle ? (
    children
  ) : (
    <div style={{ maxHeight: "150px", overflowY: "auto" }}>{val}</div>
  );
  // children : <>{name === 'dniType' ? val === "1" ? 'DNI' : 'NIE' : name !== 'provincia' ? val : getCurrentOption(val, opts)}</>
};
