import axios from "axios";
import { subSeconds, addDays } from "date-fns";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";
import { ParcGuellOrder } from "./types";

export const setCorrectMaxDate = (maxDate: Date) =>
  subSeconds(addDays(new Date(maxDate), 1), 1).toISOString();

export const requestData = async (
  filters: any,
  one: boolean = false
): Promise<ParcGuellOrder[]> => {  
  const url = process.env.REACT_APP_API_PARC_GUELL;
  const token = localStorage.getItem("parcGuellToken");
  // const token = await authIQ();
  if (!token) return [];

  try {
    const { data } = one
      ? await axios.get(
          `${url}/api/orders?${filters.indCriteria}=${filters.indValue}`,
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
      : await axios.get(
          `${url}/api/orders?minDate=${filters.minCreatedAt.toISOString()}&maxDate=${setCorrectMaxDate(
            filters.maxCreatedAt
          )}${filters.status
            .map((status: string) => `&status=${status}`)
            .join("")}`,
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
    console.log(data);

    return data;
    // return data.map((el: any, i: number) => {
    //   const panelOrder = {
    //     ...el,
    //     client: el.client || {
    //       firstName: "",
    //       lastName: "",
    //       email: "",
    //       phoneNumber: "",
    //       country: "",
    //       postcode: "",
    //     },
    //     fullName: el.client
    //       ? `${el.client.firstName} ${el.client.lastName}`
    //       : "",
    //     email: el.client.email,
    //     createdAt: new Date(el.createdAt).toLocaleString(),
    //   };

    //   return panelOrder;
    // });
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const paymentIncompletedToCompleted = async () => {
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, update it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const url = `${process.env.REACT_APP_API_COLOSSEUM}/api/orders/payment-incompleted-to-completed`;
      const token = localStorage.getItem("colosseumToken");
      const isOrdesToCompleted = await axios.post(url, "", {
        headers: { Authorization: "Bearer " + token },
      });
      if (isOrdesToCompleted) {
        Swal.fire("Update!", "", "success");
      } else {
        Swal.fire("Error", "error");
      }
    }
  });
};

export const swalResetTickets = (_id: string) => {
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, update it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const url = process.env.REACT_APP_API_COLOSSEUM;
      const token = localStorage.getItem("colosseumToken");
      if (!token) {
        Swal.fire("Error", "error");
        return;
      }
      const { data }: { data: boolean } = await axios.get(
        `${url}/api/orders/sendTickets/${_id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      if (data) {
        Swal.fire("Update!", "", "success");
      } else {
        Swal.fire("Error2", "error");
      }
    }
  });
};

export const resendTickets = async (_id: string) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");
  // const token = await authIQ();
  if (!token) return "false";

  try {
    const { data }: { data: boolean } = await axios.get(
      `${url}/api/orders/sendTickets/${_id}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return `${data}`;
  } catch (error) {
    console.log(error);
    return "false";
  }
};

export const updateOrder = async (_id: any, order: any) => {
  const arrTitularProperties = ["email", "firstName", "lastName"];
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");

  const e = Object.keys(order)
    .filter((key) => !arrTitularProperties.includes(key))
    .reduce(
      (res: { [x: string]: any }, key) => ((res[key] = order[key]), res),
      {}
    );
  const keys = Object.keys(order);

  const client = {
    client: {
      ...(keys.includes("email") && { email: order.email }),
      ...(keys.includes("firstName") && { firstName: order.firstName }),
      ...(keys.includes("lastName") && { lastName: order.lastName }),
    },
  };

  const body = {
    ...(Object.keys(client.client).length > 0 && client),
    ...(Object.keys(e).length > 0 && e),
  };

  if (!token) return null;
  try {
    await axios.patch(`${url}/api/orders/${_id}`, body, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const retrySendEmail = async (_id: any) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");
  if (!token) return false;

  try {
    await axios.get(`${url}/api/orders/${_id}/retry`, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const authIQ = async (pwd: string) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const localUser = localStorage.getItem("username");
  try {
    const { data } = await axios.post(`${url}/auth/login`, {
      email: `${localUser}`,
      password: pwd,
    });
    return data.access_token;
  } catch (error) {
    return null;
  }
};

export const downloadBill = async (id: string[]) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");
  if (!token) return false;

  const query = `?id=${id.join("&id=")}`;

  try {
    const response = await axios.get(`${url}/api/orders/bills${query}`, {
      headers: { Authorization: "Bearer " + token },
    });

    if (!response || !response.data) return false;

    response.data.forEach((bill: any) => {
      fileDownload(Buffer.from(bill.file), bill.name);
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const uploadFile = async (fileName: {
  order: string;
  name: string;
  file: File;
}) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");
  if (!token) return false;

  try {
    const form = new FormData();
    form.append("files", fileName.file, fileName.file.name);

    await axios.patch(`${url}/api/orders/${fileName.order}/file`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const addLog = async (_id: string, body: any) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");
  const agent = localStorage.getItem("username");
  // console.log("Edit order>>" + parsed);

  try {
    const response = await axios.post(
      `${url}/api/orders/logs`,
      {
        _id,
        status: "-",
        accion: "Edit order>>" + body,
        by: agent?.split("@")[0],
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );

    if (!response || !response.data) return false;
  } catch (error) {
    return false;
  }
};

export const newManualPurchase = async (manualPurchaseData: {
  [x: string]: string;
}) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");
  const agent = localStorage.getItem("username");

  try {
    const response = await axios.post(
      `${url}/api/orders/manual`,
      {
        _id: manualPurchaseData.id,
        cardNumber: manualPurchaseData.cardNumber,
        trackingNumber: manualPurchaseData.trackingNumber,
        agent: agent?.split("@")[0],
        email: manualPurchaseData.email,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (!response || !response.data) return false;

    return response.data ? true : false;
  } catch (error) {
    return false;
  }
};

export const makeRefund = async (_id: string, refund: any) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");

  try {
    const response = await axios.patch(
      `${url}/api/orders/${_id}/refund`,
      {
        amount: refund.amount,
        refundType: refund.refundType,
        reason: refund.reason,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    console.log(response.data);

    if (!response || !response.data || response.data === false) return false;

    return response.data ? true : false;
  } catch (error) {
    return false;
  }
};

export const getScreenshotsBot = async (wc_ref: string) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");

  try {
    const response = await axios.get(
      `${url}/api/orders/screenshots/${wc_ref}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (!response || !response.data || response.data === false) return false;

    response.data.forEach((screenshot: string, i: number) => {
      fileDownload(Buffer.from(screenshot, "base64"), `${i + 1}_${wc_ref}.png`);
    });

    return response.data ? true : false;
  } catch (error) {
    return false;
  }
};
