import { CCol, CCollapse, CRow } from "@coreui/react";
import { FC, FormEvent, Fragment, useEffect, useState } from "react";
import { DetailsProps } from "../types";
import {
  getScreenshotsBot,
  resendTickets,
} from "../_customers_Colosseum";
import { OrderInput } from "./OrderInput";

const defaultForm = {
  email: "",
  status: "",
  firstName: "",
  lastName: "",
  visitTime: "",
  timeRange: "",
  ticketsDate: "",
  purchaseMail: "",
};

export const OrderDetails: FC<DetailsProps> = ({
  activeEdit,
  order,
  setFloating,
  setRefundModal,
  form,
  setForm,
  styles,
  onSaveAttempt,
  onManualPurchase,
  setFileName,
}) => {
  const [edit, setEdit] = useState(false);
  const [sent, setSent] = useState("false");
  const [id, setId] = useState("");
  const [seeLogs, setSeeLogs] = useState(false);

  useEffect(() => {
    setId(order._id);
  }, []);

  const handleEdit = () => {
    if (!edit) {
      setForm({
        email: order.client.email,
        status: order.status,
        firstName: order.client.firstName,
        lastName: order.client.lastName,
        visitTime: order.visitTime,
        timeRange: order.timeRange,
        ticketsDate: order.ticketsDate,
        trackingNumber: order.trackingNumber,
        purchaseMail: order.purchaseMail,
      });
    } else {
      setForm(defaultForm);
    }
    setEdit(!edit);
  };

  useEffect(() => {
    setEdit(false);
  }, [activeEdit]);

  useEffect(() => {
    setTimeout(() => {
      if (sent !== "") setSent("");
    }, 2000);
  }, [sent]);

  const submitHandler = (e: any) => {
    e.preventDefault();
  };

  const resendHandler = async (e: any) => {
    e.preventDefault();
    // const sent = await retrySendEmail(order._id);
    if (true) {
      setFloating(true);
    }
  };

  const setToClipboard = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(e.target.innerHTML);
    setFloating(true);
  };

  const changeHandler = (e: any) => {
    e.target.name === "ticketsDate"
      ? setForm({
          ...form,
          [e.target.name]: e.target.value.split("-").reverse().join("/"),
        })
      : setForm({ ...form, [e.target.name]: e.target.value });
    console.log(form);
  };

  const handleSentTickets = async (_id: string) => {
    const isSent = await resendTickets(_id);
    if (isSent === "true") setSent("true");
    if (isSent === "false") setSent("false");
  };

  const handleStatusChange = (_id: string, name: string, status: string) => {
    setForm({ ...form, [name]: status });
    onSaveAttempt({
      _id: order._id,
      ...(order.status !== status && {
        status: { field: "status", old: order.status, new: status },
      }),
    });
  };

  const handleFileUpload = async (e: FormEvent<HTMLInputElement>) => {
    const rootFile = e.currentTarget.value;
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    //@ts-ignore
    if (e.target.files[0])
      setFileName({
        order: order._id,
        name: (rootFile.split("/").pop() + "").split("\\").pop(),
        file: file,
      });
  };

  const onSaveHandler = () => {
    onSaveAttempt({
      _id: order._id,
      ...(order.client.email !== form.email && {
        email: { field: "email", old: order.client.email, new: form.email },
      }),
      ...(order.status !== form.status && {
        status: { field: "status", old: order.status, new: form.status },
      }),
      ...(order.client.firstName !== form.firstName && {
        firstName: {
          field: "firstName",
          old: order.client.firstName,
          new: form.firstName,
        },
      }),
      ...(order.client.lastName !== form.lastName && {
        lastName: {
          field: "lastName",
          old: order.client.lastName,
          new: form.lastName,
        },
      }),
      ...(order.timeRange !== form.timeRange && {
        timeRange: {
          field: "timeRange",
          old: order.timeRange,
          new: form.timeRange,
        },
      }),
      ...(order.trackingNumber !== form.trackingNumber && {
        trackingNumber: {
          field: "trackingNumber",
          old: order.trackingNumber,
          new: form.trackingNumber,
        },
      }),
      ...(order.ticketsDate !== form.ticketsDate && {
        ticketsDate: {
          field: "ticketsDate",
          old: order.ticketsDate,
          new: form.ticketsDate,
        },
      }),
      ...(order.purchaseMail !== form.purchaseMail && {
        purchaseMail: {
          field: "purchaseMail",
          old: order.purchaseMail,
          new: form.purchaseMail,
        },
      }),
    });
  };

  const logsHandler = () => {
    setSeeLogs(!seeLogs);
  };

  const manualPurchaseHanlder = () => {
    onManualPurchase(order._id);
  };

  const refundHandler = () => {
    setRefundModal(order);
  };

  const screenshotsHandler = () => {
    getScreenshotsBot(order.wc_ref);
  };

  const renderLogs = (log: any, i: number) => {
    return (
      <>
        <tr key={i} style={{ backgroundColor: "#dedede", fontWeight: 500 }}>
          {log.date ? (
            <td style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              {new Date(log.date).toLocaleDateString()}
            </td>
          ) : (
            <td style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              {log.fecha}
            </td>
          )}
          {log.date ? (
            <td style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              {new Date(log.date).toLocaleTimeString()}
            </td>
          ) : (
            <td style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              {log.hora}
            </td>
          )}
          <td style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            {log.status}
          </td>
          <td style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            {log.accion.indexOf("New Error url") !== -1
              ? log.accion.split("?")[0]
              : log.accion.indexOf("Edit order>>") !== -1
              ? log.accion.split(">>")[0]
              : log.accion}
          </td>
          <td style={{ paddingTop: "5px", paddingBottom: "5px" }}>{log.by}</td>
        </tr>
        {log.accion.indexOf("Edit order>>") !== -1 && (
          <tr key={i + "-details"}>
            <td colSpan={5}>↑ Details ↑</td>
          </tr>
        )}
        {log.accion.indexOf("Edit order>>") !== -1 &&
          log.accion
            .split(">>")[1]
            .split("&&")
            .map((el: any, i: number) => {
              const singleLog = el.split("||");
              return singleLog.length === 3 ? (
                <tr key={i + "-log-detailed"}>
                  <td>
                    <b>{singleLog[0]}</b>
                  </td>
                  <td colSpan={2}>{singleLog[1]}</td>
                  <td colSpan={2}>{singleLog[2]}</td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <b>{singleLog[0]}</b>
                  </td>
                  <td colSpan={2}>Undefined</td>
                  <td colSpan={2}>{singleLog[1]}</td>
                </tr>
              );
            })}
      </>
    );
  };

  const renderTickets = (
    ticket: { type: string; amount: number },
    index: number
  ): JSX.Element => (
    <div key={index} className={styles.row}>
      <div>
        {ticket.type.charAt(0).toUpperCase() + ticket.type.substring(1)}
      </div>
      <div>{ticket.amount}</div>
    </div>
  );

  const renderCreditCard = (creditCard: string) => {
    const visible =
      creditCard
        .substring(0, creditCard.length - 4)
        .split("")
        .map((el, i) => ((i + 1) % 4 === 0 ? "* " : "*"))
        .join("") + creditCard.slice(-4);
    // return creditCard;
    return visible;
  };

  return (
    <Fragment>
      <CRow>
        <CCol>
          {edit ? (
            <Fragment>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={handleEdit}
              >
                Cancel
              </button>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={onSaveHandler}
              >
                Save
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={handleEdit}
              >
                Enable Edit
              </button>
              {/* <button
                style={{
                  border: "0",
                  borderRadius: "5px",
                  outline: "none",
                  background: !sent.length
                    ? "rgb(249, 177, 21)"
                    : sent === "true"
                    ? "lightgreen"
                    : "red",
                  fontWeight: 500,
                  padding: "5px 10px",
                  color: sent === "false" ? "#fff" : "#000",
                }}
                onClick={() => swalResetTickets(order._id)}
              >
                Send Ticket mail
              </button> */}
              {/* <label
                htmlFor={`ticket-file-${order._id}`}
                className="btn btn-info"
                style={{
                  border: "1px solid #ccc",
                  display: "inline-block",
                  padding: "6px 12px",
                  cursor: "pointer",
                }}
              >
                Upload Ticket File
              </label>
              <input
                type="file"
                name=""
                id={`ticket-file-${order._id}`}
                style={{ display: "none" }}
                onChange={handleFileUpload}
              /> */}
              <button
                className="btn btn-info"
                style={{
                  border: "1px solid #ccc",
                  display: "inline-block",
                  padding: "6px 12px",
                }}
                onClick={logsHandler}
              >
                Logs
              </button>
              {/* <button
                className="btn btn-info"
                style={{
                  border: "1px solid #ccc",
                  display: "inline-block",
                  padding: "6px 12px",
                }}
                onClick={manualPurchaseHanlder}
              >
                Manual Purchase
              </button> */}
              {/* {order.wc_ref ? (
                <button
                  className="btn btn-warning"
                  style={{
                    border: "1px solid #ccc",
                    display: "inline-block",
                    padding: "6px 12px",
                  }}
                  onClick={screenshotsHandler}
                >
                  Screenshots
                </button>
              ) : (
                ""
              )} */}
              {/* {order.saleId ? (
                <button
                  className="btn btn-secondary"
                  style={{
                    border: "1px solid #ccc",
                    display: "inline-block",
                    padding: "6px 12px",
                  }}
                  onClick={refundHandler}
                >
                  Refund
                </button>
              ) : (
                ""
              )} */}
            </Fragment>
          )}
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCollapse show={seeLogs}>
            <table
              style={{
                width: "100%",
                border: "1px solid #ccc",
                marginBottom: "10px",
              }}
            >
              <colgroup>
                <col span={1} style={{ width: "10%" }} />
                <col span={1} style={{ width: "10%" }} />
                <col span={1} style={{ width: "15%" }} />
                <col span={1} style={{ width: "55%" }} />
                <col span={1} style={{ width: "10%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th className="log-fecha">Fecha</th>
                  <th className="log-hora">Hora</th>
                  <th className="log-status">Status</th>
                  <th className="log-accion">Acción</th>
                  <th className="log-by">By</th>
                </tr>
              </thead>
              <tbody>
                {order.logs.length ? (
                  order.logs.map(renderLogs)
                ) : (
                  <tr>
                    <td colSpan={5}>No logs available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </CCollapse>
        </CCol>
      </CRow>
      <form onSubmit={submitHandler} data-id={order._id}>
        <CRow>
          <CCol>
            <h5 className={styles.rowHeader}>Order</h5>
            <div className={styles.row}>
              <div>Status</div>
              <div>
                <select
                  style={{
                    border: 0,
                    borderBottom: "1px solid black",
                    background: "transparent",
                    padding: 0,
                    outline: "none",
                  }}
                  name="status"
                  value={order.status}
                  onChange={({ target }) =>
                    handleStatusChange(order._id, target.name, target.value)
                  }
                >
                  <option value="completed">Completed</option>
                  <option value="await bot">Await Bot</option>
                  <option value="await ok">Await OK</option>
                  <option value="await mail">Await Mail</option>
                  <option value="without card">Without Card</option>
                  <option value="revised">Revised</option>
                  <option value="on hold">On Hold</option>
                  <option value="refund">Refund</option>
                  <option value="to review">To Review</option>
                  <option value="payment incompleted">
                    Payment Incompleted
                  </option>
                </select>
              </div>
            </div>
            {/* <div className={styles.row}>
              <div>Session IDs</div>
              <div>
                {order.sessionIds && order.sessionIds.length
                  ? order.sessionIds.join(", ")
                  : "-"}
              </div>
            </div> */}
            <div className={styles.row}>
              <div>Transaction_id</div>
              <div>
                {order.qontoIds && order.qontoIds.length
                  ? order.qontoIds.join(", ")
                  : "-"}
              </div>
            </div>
            <div className={styles.row}>
              <div>Qonto Amount</div>
              <div>
                {order.qontoAmount.map((num) => String(num) + " €").join(", ")}
              </div>
            </div>
            <div className={styles.row}>
              <div>Sale ID</div>
              <div>{order.saleId}</div>
            </div>
            <div className={styles.row}>
              <div>Ticket Type</div>
              <div onClick={setToClipboard}>{order.ticketType}</div>
            </div>
            <div className={styles.row}>
              <div>WC ID</div>
              <div onClick={setToClipboard}>{order.wc_id || ""}</div>
            </div>
            <div className={styles.row}>
              <div>WC Reference</div>
              <div onClick={setToClipboard}>{order.wc_ref || ""}</div>
            </div>
            <OrderInput
              edit={edit}
              value={form.purchaseMail}
              id={order._id}
              handleChange={changeHandler}
              name="purchaseMail"
              styles={styles}
            >
              <div>{order.purchaseMail || ""}</div>
            </OrderInput>
            {/* <div className={styles.row}>
              <div>Card Number</div>
              <div onClick={setToClipboard}>
                {order.cardnumber ? renderCreditCard(order.cardnumber) : ""}
              </div>
            </div> */}
            {/* <div className={styles.row}>
              <div>SMS Code</div>
              <div>{order.smsCode || ""}</div>
            </div> */}
            <OrderInput
              edit={edit}
              value={form.trackingNumber}
              id={order._id}
              handleChange={changeHandler}
              name="trackingNumber"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.trackingNumber || ""}</div>
            </OrderInput>
            {/* <div className={styles.row}>
              <div>Nº Tracking</div>
              <div>{order.trackingNumber ? order.trackingNumber : ""}</div>
            </div> */}
            <div className={styles.row}>
              <div>Visit Time</div>
              <div>{order.visitTime ? order.visitTime : ""}</div>
            </div>
            <div className={styles.row}>
              <div>Price</div>
              <div>{order.totalPrice || 0} €</div>
            </div>
            <div className={styles.row}>
              <div>Created at</div>
              <div>{order.createdAt}</div>
            </div>
            <div className={styles.row}>
              <div>Error</div>
              <div>{order.error || ""}</div>
            </div>
            <div className={styles.row}>
              <div>Url Error</div>
              <div>{order.urlError ? order.urlError.split("?")[0] : ""}</div>
            </div>
            <div className={styles.row}>
              <div>Displayed Error</div>
              <div>{order.errorDisplayed ? order.errorDisplayed : ""}</div>
            </div>
            {/* <div className={styles.row}>
              <div>Attempts</div>
              <div>{order.attempts || 0}</div>
            </div> */}
            <h5 className={styles.rowHeader}>Tickets</h5>
            {order.tickets.map(renderTickets)}
            <div
              style={{
                borderBottom: "1px solid #333",
                width: "75%",
                margin: "auto",
              }}
            ></div>
            <OrderInput
              edit={edit}
              value={form.ticketsDate}
              id={order._id}
              handleChange={changeHandler}
              name="ticketsDate"
              styles={styles}
              dateInput
            >
              <div onClick={setToClipboard}>{order.ticketsDate || ""}</div>
            </OrderInput>
            <OrderInput
              edit={edit}
              value={form.timeRange}
              id={order._id}
              handleChange={changeHandler}
              name="timeRange"
              styles={styles}
              select
            >
              <div onClick={setToClipboard}>{order.timeRange || ""}</div>
            </OrderInput>
          </CCol>
          {order.client && (
            <CCol>
              <h5 className={styles.rowHeader}>Client</h5>
              <OrderInput
                edit={edit}
                value={form.firstName}
                id={order._id}
                handleChange={changeHandler}
                name="firstName"
                styles={styles}
              >
                <div onClick={setToClipboard}>
                  {order.client.firstName || ""}
                </div>
              </OrderInput>
              <OrderInput
                edit={edit}
                value={form.lastName}
                id={order._id}
                handleChange={changeHandler}
                name="lastName"
                styles={styles}
              >
                <div onClick={setToClipboard}>
                  {order.client.lastName || ""}
                </div>
              </OrderInput>
              <OrderInput
                edit={edit}
                value={form.email}
                id={order._id}
                handleChange={changeHandler}
                name="email"
                styles={styles}
              >
                <div onClick={setToClipboard}>{order.client.email || ""}</div>
              </OrderInput>
              <div className={styles.row}>
                <div>Phone Number</div>
                <div>{order.client.phoneNumber || ""}</div>
              </div>
              <div className={styles.row}>
                <div>Address</div>
                <div>{order.client.address}</div>
              </div>
              <div className={styles.row}>
                <div>Postcode</div>
                <div>{order.client.postcode}</div>
              </div>
              <div className={styles.row}>
                <div>Country Code</div>
                <div>{order.client.country}</div>
              </div>
              <div className={styles.row}>
                <div>ID Number</div>
                <div>{order.client.idNumber || ""}</div>
              </div>
            </CCol>
          )}
        </CRow>
        {/* {order.arrErrors.length > 0 ? (
          <CRow>
            <CCol>
              <table
                className="table"
                style={{ width: "100%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    <td
                      style={{
                        background: "#ccc",
                        padding: "10px 5px",
                        fontWeight: "bold",
                      }}
                    >
                      Error Logs
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {order.arrErrors.map((el: string) => (
                    <tr>
                      <td style={{ padding: "10px 5px" }}>{el}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CCol>
          </CRow>
        ) : (
          ""
        )} */}
      </form>
    </Fragment>
  );
};
