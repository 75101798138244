import axios from "axios";
import Swal from "sweetalert2";
import XLSX from "xlsx";
const html2pdf = require("html2pdf.js");

export const data = (uspostalTests: any) => {
  return uspostalTests.map((uspostalTest: any) => {
    // TODO remove if when enpoint /orders/modify fields are added to the DDBB
    if (uspostalTest.order) {
      uspostalTest = uspostalTest.order;
    }
    return {
      id: uspostalTest._id,
      date_created: uspostalTest.date_created
        ? uspostalTest.date_created.split("T")[0]
        : "",
      id_wc: uspostalTest.id_wc,
      email: uspostalTest.email,
      status: uspostalTest.status,
      name: uspostalTest.name,
      paymentMethod: uspostalTest.payment_method,
      project: uspostalTest.project,
      domain: uspostalTest.domain,
      password: uspostalTest.password,
      product: uspostalTest.product,
      order_total: uspostalTest.order_total,
      logs: uspostalTest.logs ? uspostalTest.logs : undefined,
      saleId: uspostalTest.saleId,
    };
  });
};

type RefundInputs = {
  reason: string;
  otherReason: string;
};

type RefundOrderInfo = {
  id: string;
  value: string;
  project: string;
  order_total: string;
};

//* New refund function
export const changeStatusByRefund = async (
  refundData: RefundInputs,
  { id, value, project, order_total }: RefundOrderInfo
): Promise<boolean> => {
  const url = process.env.REACT_APP_USPOSTALTEST_BACKEND_URL;
  const token = localStorage.getItem("token");
  const body = {
    total: order_total,
    reason:
      refundData.reason === "Otro" ? refundData.otherReason : refundData.reason,
  };
  try {
    const { status, data } = await axios.patch(
      `${url}/order/refund/${id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if ((status === 200 || status === 201) && data) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const resendEmail = async (_id: string, email: string) => {
  const url = process.env.REACT_APP_USPOSTALTEST_BACKEND_URL;
  const token = localStorage.getItem("token");
  const body = { email };

  try {
    const { status, data } = await axios.post(
      `${url}/orders/${_id}/resendCredentials`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data && data === true) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const changeStatus = (e: any) => {
  const { id, value } = e.target;
  const url = `${process.env.REACT_APP_USPOSTALTEST_BACKEND_URL}/orders/${id}`;
  const token = localStorage.getItem("token");
  const body = { status: value };
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, change it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const order = await axios.patch(`${url}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (order.status === 200 || order.status === 201) {
        Swal.fire("Update!", "changed status.", "success");
      } else {
        Swal.fire("Error", "error");
      }
    }
  });
};

export const remove = (e: any) => {
  const { id } = e.target;
  const url = `${process.env.REACT_APP_USPOSTALTEST_BACKEND_URL}/orders/${id}`;
  const token = localStorage.getItem("token");
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const order = await axios.delete(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (order.status === 200 || order.status === 201) {
        Swal.fire("Delete!", "Your order has been deleted.", "success");
      } else {
        Swal.fire("Error", "error");
      }
    }
  });
};

export const showTrackLog = async (value: string) => {
  const body = { email: value };
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_USPOSTALTEST_BACKEND_URL}/users-web/logs`;
  const { data }: any = await axios.post(`${url}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (data.length === 0) {
    return [];
  }
  const track = data.map((record: any) => ({
    email: record.email,
    date: record.hour,
    ip: record.ip,
    url: record.url,
  }));
  return track;
};

export const trackUser = async (e: any) => {
  const { id } = e.target;
  const $loader = document.querySelector(`[data-id='${id}']`);
  $loader?.classList.remove("none");
  const { value } = e.target;
  const body = { email: value };
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_USPOSTALTEST_BACKEND_URL}/users-web/logs`;
  const orders: any = await axios.post(`${url}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (orders.data.length === 0) {
    Swal.fire({
      icon: "error",
      title: "No tracking available",
    });
    $loader?.classList.add("none");
    return;
  }
  const track = orders.data;
  let data: any = [];
  for (let i = 0; i < track.length; i++) {
    let user = {
      email: track[i].email,
      date: track[i].hour,
      ip: track[i].ip,
      url: track[i].url,
    };
    data.push(user);
  }
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "");
  let today = Date.now();
  let nameFile = `${value}-${today}.xlsx`;
  XLSX.writeFile(wb, nameFile);
  $loader?.classList.add("none");
};

export const downloadMail = async (e: any) => {
  const email = e.target.value;
  const { id } = e.target;
  const $loader = <HTMLElement>document.querySelector(`[data-id='${id}']`);
  $loader?.classList.remove("none");
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_USPOSTALTEST_BACKEND_URL}/features/emails/${email}`;
  const messageEmail: any = await axios.get(`${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  let message = messageEmail.data;
  if (message.length === 0) {
    Swal.fire({
      icon: "error",
      title: "No email available",
    });
    $loader?.classList.add("none");
    return;
  }
  printPDF(message, email, $loader);
};

const printPDF = async (
  message: string,
  email: string,
  $loader: HTMLElement
) => {
  const $emailPDF = <HTMLElement>document.querySelector("#page");
  $emailPDF.innerHTML = message;
  await html2pdf($emailPDF, {
    jsPDF: {
      format: "a4",
      y: 0,
      x: 0,
    },
    imageType: "image/jpeg",
    output: `${email}.pdf`,
  });
  $loader?.classList.add("none");
  $emailPDF.innerHTML = "";
};
