export const statusCount = (uspostalTests: any) => {
  _data = {
    refund: 0,
    completed: 0,
    chargeback: 0,
  };
  for (let i = 0; i < uspostalTests.length; i++) {
    _sumStatusInData(uspostalTests[i]);
  }
  return _data;
};

const _sumStatusInData = (uspostalTest: any): void => {
  if (uspostalTest.refund) {
    _data.refund = _data.refund + 1;
  }
  if (uspostalTest.completed) {
    _data.completed = _data.completed + 1;
  }
  if (uspostalTest.chargeback) {
    _data.chargeback = _data.chargeback + 1;
  }
};

export const conversionData = (uspostalTest: any[]) => {
  const conversions = uspostalTest.filter(
    (order) => order.order.status !== "pending"
  );
  if (!conversions.length) return [];

  let arrDomains: { domain: string; orders: number; rate: string }[] = [];
  conversions.forEach((order) => {
    if (order.order.status === "pending") return [];
    if (!order.order || !order.order.project) return [];

    const parsedDomain: string = order.order.project.replace("-", "."),
      arrRegisteredDomains = arrDomains.map((log) => log.domain);

    !arrRegisteredDomains.includes(parsedDomain)
      ? arrDomains.push({
          domain: parsedDomain,
          orders: 1,
          rate: "",
        })
      : arrDomains.filter((log) => log.domain === parsedDomain)[0].orders++;
  });

  const result = arrDomains.map((log) => ({
    ...log,
    rate: log.orders / conversions.length,
  }));

  return result;
};

let _data: any = {
  refund: 0,
  completed: 0,
  chargeback: 0,
};

export const tableSummary = (orders: any[], domainConversion: any) => {
  let arrDomains: { domain: string }[] = [];
  orders
    .filter((order) => order.order.status !== "pending")
    .forEach((order) => {
      if (!order.order || !order.order.project) return [];

      const parsedDomain: string = order.order.project.replace("-", "."),
        arrRegisteredDomains = arrDomains.map((log) => log.domain);

      if (!arrRegisteredDomains.includes(parsedDomain))
        arrDomains.push({ domain: parsedDomain });
    });

  const summary = arrDomains.map((log) => {
    const parseDomain = log.domain.replace(".", "-"),
      domainPendings = orders.filter(
        ({ order }) =>
          order.project === parseDomain && order.status === "pending"
      ),
      domainCompleted = orders.filter(
        ({ order }) =>
          order.project === parseDomain && order.status !== "pending"
      ),
      completed = orders.filter(({ order }) => order.status !== "pending");

    return {
      domain: log.domain,
      orders: domainCompleted.length,
      orderPct: domainCompleted.length / completed.length * 100,
      orderPay: domainPendings.length,
      crPct: domainCompleted.length / domainPendings.length,
    };
  });

  return summary;
};
