import { CCol, CRow } from "@coreui/react";
import { FC, Fragment, useEffect, useState } from "react";
import { DetailsProps } from "../types";
import { retrySendEmail, updateOrder } from "../_customers_IQ";
import { OrderInput } from "./OrderInput";

const defaultForm = {
  name: "",
  lastName: "",
  age: "",
  email: "",
  status: "",
};

export const OrderDetails: FC<DetailsProps> = ({
  activeEdit,
  order,
  setFloating,
  form,
  setForm,
  styles,
  onSaveAttempt,
}) => {
  const [edit, setEdit] = useState(false);
  const handleEdit = () => {
    if (!edit) {
      setForm({
        name: order.name,
        lastName: order.lastName,
        age: order.age,
        email: order.email,
        status: order.status,
      });
    } else {
      setForm(defaultForm);
    }
    setEdit(!edit);
  };

  useEffect(() => {
    setEdit(false);
  }, [activeEdit]);

  const submitHandler = (e: any) => {
    e.preventDefault();
  };

  const resendHandler = async (e: any) => {
    e.preventDefault();
    // const sent = await retrySendEmail(order._id);
    if (true) {
      setFloating(true);
    }
  };

  const setToClipboard = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(e.target.innerHTML);
    setFloating(true);
  };

  const changeHandler = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSaveHandler = () => {
    onSaveAttempt({
      _id: order._id,
      ...(order.name !== form.name && {
        name: { field: "name", old: order.name, new: form.name },
      }),
      ...(order.lastName !== form.lastName && {
        lastName: {
          field: "lastName",
          old: order.lastName,
          new: form.lastName,
        },
      }),
      ...(order.age !== form.age && {
        age: { field: "age", old: order.age, new: form.age },
      }),
      ...(order.email !== form.email && {
        email: { field: "email", old: order.email, new: form.email },
      }),
      ...(order.status !== form.status && {
        status: { field: "status", old: order.status, new: form.status },
      }),
    });
  };

  return (
    <Fragment>
      <CRow>
        <CCol>
          {edit ? (
            <Fragment>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={handleEdit}
              >
                Cancel
              </button>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={onSaveHandler}
              >
                Save
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={handleEdit}
              >
                Enable Edit
              </button>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={resendHandler}
              >
                Resend Email
              </button>
            </Fragment>
          )}
        </CCol>
      </CRow>
      <form onSubmit={submitHandler} data-id={order._id}>
        <CRow>
          <CCol>
            <h5 className={styles.rowHeader}>Order</h5>
            <div className={styles.row}>
              <div>Order ID</div>
              <div onClick={setToClipboard}>{order.orderId}</div>
            </div>
            <div className={styles.row}>
              <div>Payment Method</div>
              <div>{order.paymentMethod}</div>
            </div>
            <div className={styles.row}>
              <div>Price</div>
              <div>{order.priceTotal} €</div>
            </div>
            <div className={styles.row}>
              <div>Product</div>
              <div>{order.product}</div>
            </div>
            <div className={styles.row}>
              <div>Project</div>
              <div>{order.project}</div>
            </div>
            <div className={styles.row}>
              <div>Serial Number</div>
              <div onClick={setToClipboard}>{order.serialNumber}</div>
            </div>
            <div className={styles.row}>
              <div>Created at</div>
              <div>{order.createdAt}</div>
            </div>
            <OrderInput
              edit={edit}
              value={form.status}
              id={order._id}
              handleChange={changeHandler}
              name="status"
              styles={styles}
              select
            >
              <div>{order.status}</div>
            </OrderInput>
          </CCol>
          <CCol>
            <h5 className={styles.rowHeader}>Client</h5>
            <OrderInput
              edit={edit}
              value={form.name}
              id={order._id}
              handleChange={changeHandler}
              name="name"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.name}</div>
            </OrderInput>
            <OrderInput
              edit={edit}
              value={form.lastName}
              id={order._id}
              handleChange={changeHandler}
              name="lastName"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.lastName}</div>
            </OrderInput>
            <OrderInput
              edit={edit}
              value={form.age}
              id={order._id}
              handleChange={changeHandler}
              name="age"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.age}</div>
            </OrderInput>
            <OrderInput
              edit={edit}
              value={form.email}
              id={order._id}
              handleChange={changeHandler}
              name="email"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.email}</div>
            </OrderInput>
            <div className={styles.row}>
              <div>Gender</div>
              <div>{order.gender}</div>
            </div>
            <div className={styles.row}>
              <div>IQ Score</div>
              <div>{order.score.totalScore}</div>
            </div>
            <div className={styles.row}>
              <div>Street</div>
              <div>{order.street ? order.street :''}</div>
            </div>
            <div className={styles.row}>
              <div>CP</div>
              <div>{order.cp ? order.cp : ''}</div>
            </div>
            <div className={styles.row}>
              <div>State</div>
              <div>{order.state ? order.state : ''}</div>
            </div>
          </CCol>
        </CRow>
      </form>
    </Fragment>
  );
};
