
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userSelector } from "../../slices/userSlice";

export const Logout = () => {
  
  const history = useHistory();
  
  const handleOnClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    history.push('/login');
  }

  const { isSuccess } = useSelector(
    userSelector
  );

  return(
    <div className="pr-2 d-flex align-items-center">
    {
      !isSuccess ? '' :
      <button onClick={handleOnClick} className="bg-danger border-0 rounded">
        Logout
      </button>
    }
    </div>
  )
}