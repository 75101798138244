import { CButton, CModalBody, CModalFooter } from "@coreui/react";
import React, { useState, ChangeEvent, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import Modal from "../../../features/modal/Modal";
import { changeStatusByRefund, resendEmail } from "./_customersUspostalTest";

type ResendInputs = {
  email: string;
};

interface Props {
  show: boolean;
  setShow: Function;
  orderData: {
    value: string;
    project: string;
    id: string;
    order_total: string;
    email: string;
  };
}

export const USPostal_ResendCredentials: React.FC<Props> = ({
  show,
  setShow,
  orderData,
}) => {
  const [email, setEmail] = useState("");
  const [calling, setCalling] = useState(false);
  const [log, setLog] = useState("");

  useEffect(() => {
    setEmail(orderData.email);
  }, [orderData.email]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResendInputs>();
  const onSubmit: SubmitHandler<ResendInputs> = async (resendData) => {
    setCalling(true);
    const isRefundDone = await resendEmail(orderData.id, resendData.email);
    isRefundDone ? setLog("Credentials sent.") : setLog("Mail Service Error.");
    setTimeout(() => {
      toggleAndReset();
    }, 2000);
  };

  const toggleAndReset = () => {
    setShow(!show);
    setEmail("");
    setCalling(false);
    setLog("");
  };

  const onChangeEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  return (
    <Modal
      show={show}
      title="Resend Credentials"
      toggleTrigger={toggleAndReset}
    >
      <CModalBody>
        <form
          className="d-flex flex-column justify-content-between"
          style={{ backgroundColor: "#3c4b64", height: "100%" }}
          action="#"
          id="resendForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="d-flex flex-column align-items-left">
            <div className="w-75 text-left">
              <h4>Customer mail </h4>
              <input
                className="form-control mt-3 mb-1"
                {...register("email")}
                disabled={calling}
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={onChangeEmailHandler}
              />
            </div>
          </div>
        </form>
      </CModalBody>
      <CModalFooter className="border-top-0">
        <CButton color="secondary" onClick={toggleAndReset} disabled={calling}>
          Cancel
        </CButton>
        <CButton
          className="text-light border-dark"
          disabled={email === "" || calling}
          type="submit"
          form="resendForm"
          style={{ backgroundColor: "rgba(0,0,21,0.3)" }}
        >
          Confirm
        </CButton>{" "}
        <div id="refundLog" className="d-block w-100 pt-1">
          {log && <span>{log}</span>}
        </div>
      </CModalFooter>
    </Modal>
  );
};
