
import { useDispatch } from "react-redux";
import { useState } from 'react';
import { CButton, CContainer, CCol, CForm, CFormGroup, CInput, CLabel, CRow, CSwitch } from '@coreui/react';

import { fetchTramites } from "../../slices/tramitesSlice";
import { fetchUspostalTest } from "../../slices/uspostaltestSlice";
import { submit } from './_search';

export const Search = ({showCompare}: any) => {
  const [compare, setCompare] = useState(false);
  const dispatch = useDispatch();
  
  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    let $project: any = document.querySelector("#select");

    if(!$project || $project.value === '-'){
      alert('Choose project')
      return;
    }
    const { url, dateInit, dateFinish, status, project } = submit();
    
    let urlAbosulte = `${url}/orders/modify?${dateInit}${dateFinish}${status}`;
    if(project === 'uspostaltest') {
      dispatch(fetchUspostalTest(urlAbosulte))
    }else {
      dispatch(fetchTramites(urlAbosulte));
    }
  }

  const handleOnChangeSwitch = (e: any) => {
    setCompare(e.target.checked);
  }

  return (
    <CContainer>
      <CRow>
        <CCol sm="12">
          <CForm action="" method="post" onSubmit={handleOnSubmit}>
            <CFormGroup className="d-flex align-items-center w-75">
              <CLabel htmlFor="nf-date" className="w-50">Date Init</CLabel>
              <CInput
                type="date"
                id="nf-date-init"
                name="nf-date-init"
                placeholder="Enter date.."
                autoComplete="date"
              />
       
              <CLabel htmlFor="nf-date" className="w-50">Date Finish</CLabel>
              <CInput
                type="date"
                id="nf-date-finish"
                name="nf-date-finish"
                placeholder="Enter date.."
                autoComplete="date"
              />
            <CButton 
              type='submit'
              key='button1'
              color='info'
              size='sm'
              className="m-2"
            >Search</CButton>
            </CFormGroup>
            {
              compare ?
              <CFormGroup className="d-flex align-items-center w-75">
                <CLabel htmlFor="nf-date" className="w-50">Date Init</CLabel>
                <CInput
                  type="date"
                  id="nf-date-init-compare"
                  name="nf-date-init"
                  placeholder="Enter date.."
                  autoComplete="date"
                />
        
                <CLabel htmlFor="nf-date" className="w-50">Date Finish</CLabel>
                <CInput
                  type="date"
                  id="nf-date-finish-compare"
                  name="nf-date-finish"
                  placeholder="Enter date.."
                  autoComplete="date"
                />
              </CFormGroup>
                : ''
            }
            
          </CForm>
        </CCol>
        <CCol sm="12">

        </CCol>
      </CRow>
      {showCompare === false ? '' :
        <CRow className="d-flex align-items-center justify-content-end">
          <CLabel htmlFor="nf-date" className="m-2">Compare</CLabel>
          <CSwitch onChange={handleOnChangeSwitch}
            key='switch'
            color="primary"
            shape= 'pill'
            size='sm'
          />
        </CRow>
      }
    </CContainer>
  )
}