import to from "await-to-js";
import axios, { AxiosResponse } from "axios";
import { addDays, subSeconds } from "date-fns";

type AuthType = AxiosResponse<{ access_token: string }>;

export class APIParcGuell {
  private readonly url = <string>process.env.REACT_APP_API_PARC_GUELL;
  private readonly user = localStorage.getItem("username");
  private readonly token = localStorage.getItem("parcGuellToken");
  private readonly headers = {
    headers: { Authorization: "Bearer " + this.token },
  };

  private _setCorrectMaxDate(maxDate: Date) {
    return subSeconds(addDays(maxDate, 1), 1);
  }

  private async authSagrada(pwd: string) {
    const [err, response] = await to<AuthType>(
      axios.post(`${this.url}/auth/login`, {
        email: `${this.user}`,
        password: pwd,
      })
    );
    return err || !response ? null : response.data.access_token;
  }

  async requestData(filters: any) {
    if (!this.token) throw new Error("No API");
    const minDate = filters.minCreatedAt.toISOString();
    const maxDate = this._setCorrectMaxDate(filters.maxCreatedAt).toISOString();
    const query = `minDate=${minDate}&maxDate=${maxDate}`;
    const [err, response] = await to(
      axios.get(`${this.url}/api/reports/report?${query}`, this.headers)
    );
    if (err || !response) throw new Error("No data API");
    console.log(response.data);

    return {
      total: Object.values(response.data).reduce<number>(
        (prev: any, next: any) => Number(prev) + Number(next),
        0
      ),
      status: response.data,
    };
  }
}

// export const requestData = async (
//   filters: any
// ): Promise<{ total: number; status: any }> => {
//   const url = process.env.REACT_APP_API_PARC_GUELL;
//   const token = localStorage.getItem("parcGuellToken");
//   if (!token) throw new Error("No API");
//   try {
//     const { data } = await axios.get(
//       `${url}/api/reports/report?minDate=${filters.minCreatedAt.toISOString()}&maxDate=${setCorrectMaxDate(
//         filters.maxCreatedAt
//       ).toISOString()}`,
//       {
//         headers: { Authorization: "Bearer " + token },
//       }
//     );
//     console.log(data);

//     return {
//       total: Object.values(data).reduce<number>(
//         (prev: any, next: any) => Number(prev) + Number(next),
//         0
//       ),
//       status: data,
//     };
//   } catch (error) {
//     console.log(error);
//     return {
//       total: 0,
//       status: {
//         completed: 0,
//         "await bot": 0,
//         "await ok": 0,
//         "await mail": 0,
//         revised: 0,
//         refund: 0,
//         "on hold": 0,
//         "payment incompleted": 0,
//         "to review": 0,
//       },
//     };
//   }
// };
