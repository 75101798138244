
import { differenceInDays } from 'date-fns';

export const chartsWeek = (tramites: Itramite[]) => {
    const data = _data();
    let labels: any = [];
  for (let i = 0; i < tramites.length; i++) {
    const include = labels.findIndex((label: number) => label === tramites[i].week);
    if(include === -1 || labels.length === 0) {
      labels.push(tramites[i].week)
    }
  }
  data.labels = labels;
  _createPositionInArrayData(labels, data);
  tramites.map((tramite: Itramite) => {
    // agrega en la misma posicion de labels un +1 en el objecto data
    let typeCharts = 'week';
    _sumStatusInData(tramite, labels, data, typeCharts);
  });
  return data;
}

export const chartsMonth = (tramites: Itramite[]) => {
  const data = _data();
  let labels: number[] = [];
  for (let i = 0; i < tramites.length; i++) {
    const include = labels.findIndex((label: number) => label === tramites[i].month);
    if(include === -1 || labels.length === 0) {
      labels.push(tramites[i].month);
    }
  }
  data.labels = labels;
  _createPositionInArrayData(labels, data);
  tramites.map((tramite: any) => {
    // agrega en la misma posicion de labels un +1 en el objecto data
    let typeCharts = 'month';
    _sumStatusInData(tramite, labels, data, typeCharts);
  });
  return data;
}

export const chartsDay = (tramites: Itramite[]) => {
  const data = _data();
  let labels: any[] = [];
  for (let i = 0; i < tramites.length; i++) {
    const include = labels.findIndex((label: string) => label === `${tramites[i].day}/${tramites[i].month}`);
    if(include === -1 || labels.length === 0) {
      labels.push(`${tramites[i].day}/${tramites[i].month}`)
    }
  }
  data.labels = labels;

  _createPositionInArrayData(labels, data);
  tramites.map((tramite: any) => {
    // agrega en la misma posicion de labels un +1 en el objecto data
    let typeCharts = 'day';
    _sumStatusInData(tramite, labels, data, typeCharts);
  });
  return data;
}

export const chartsCR = (tramites: any) => {
  const arrRevised: number[] = []
  const arrTotalOrders: number[] = []
  const arrDates: Date[] = tramites
    .map((log:any) => {
      const d = new Date(log.order.createdAt);
      return new Date(d.getFullYear(), d.getMonth(), d.getDate());
    })
    .sort((a: Date, b: Date) => a.getTime() - b.getTime());

  // Data
  const dataOrders = Array.from(new Set(arrDates.map(el=> el.getTime()))).map((date) => {
    const allDayOrders = tramites.filter((log: any) => {  
      const d = new Date(log.order.createdAt);    
      return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime() === date
    }).length;
    
    arrTotalOrders.push(allDayOrders);
    
    const revisedOrders: number = tramites.filter((log: any) => {  
      const d = new Date(log.order.createdAt);
      return log.order.status === "revised" && new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime() === date
    }).length;
    arrRevised.push(revisedOrders);
    
    return Number(((revisedOrders / allDayOrders) * 100).toFixed(2));
  });
  
  // Labels
  const arrDateOrders: string[] = arrDates
  .sort((a: Date, b: Date) => a.getTime() - b.getTime())
  .map((el: Date) => el.toLocaleDateString().slice(0, -5));
  const prevLabels = Array.from(new Set(arrDateOrders));
  
  return {
    labels: prevLabels,
    data: dataOrders,
    revised: arrRevised,
    total: arrTotalOrders
  }
}

export const chartsHour = (tramites: Itramite[]) => {
  const data = _data();
  let labels: any = [];

  const diffInDays = _showChartsPerHour();
  if(diffInDays > 1) {
    return data;
  }
  for (let i = 0; i < tramites.length; i++) {
    const include = labels.findIndex((label: string) => label === `${tramites[i].day}/${tramites[i].month}-${tramites[i].hour}`);
    if(include === -1 || labels.length === 0) {
      labels.push(`${tramites[i].day}/${tramites[i].month}-${tramites[i].hour}`)
    }
  }

  data.labels = labels.sort((a: string, b: string) => parseInt(a.split('-')[1]) - parseInt(b.split('-')[1]));
  
  _createPositionInArrayData(labels, data);
  tramites.map((tramite: any) => {
    // agrega en la misma posicion de labels un +1 en el objecto data
    let typeCharts = 'hour';
    _sumStatusInData(tramite, labels, data, typeCharts);
  });
  return data;
}

const _createPositionInArrayData = (labels: any[], data: any) => {
  labels.map(() => {
    data.pending.push(0);
    data.timeout.push(0);
    data.failed.push(0);
    data.failedAddress.push(0);
    data.failedMatch.push(0);
    data.revised.push(0);
    data.refund.push(0);
    data.completed.push(0);
    data.validCard.push(0);
  });
}

const _sumStatusInData = (tramite: Itramite, labels: any[], data: any, typeCharts: string): void => {
  for (let j = 0; j < labels.length; j++) {
    let condicional = _condicional(tramite, typeCharts);
    if(condicional === labels[j]) {
      if(tramite.pending) {
        data.pending[j] = data.pending[j]+1;
      }
      if(tramite.timeout) {
        data.timeout[j] = data.timeout[j]+1;
      }
      if(tramite.failed) {
        data.failed[j] = data.failed[j]+1;
      }
      if(tramite.failedAddress) {
        data.failedAddress[j] = data.failedAddress[j]+1;
      }
      if(tramite.failedMatch) {
        data.failedMatch[j] = data.failedMatch[j]+1;
      }
      if(tramite.revised) {
        data.revised[j] = data.revised[j]+1;
      }
      if(tramite.refund) {
        data.refund[j] = data.refund[j]+1;
      }
      if(tramite.completed) {
        data.completed[j] = data.completed[j]+1;
      }
      if(tramite.validCard) {
        data.validCard[j] = data.validCard[j]+1;
      }
    }
  }
}

const _condicional = (tramite: Itramite, typeCharts: string) => {
  let response: string | number = '';
  switch (typeCharts) {
    case 'week':
      response = tramite.week;
      break;
    case 'month':
      response = tramite.month;
      break;
    case 'day':
      response = `${tramite.day}/${tramite.month}`;
      break;
    case 'hour':
      response = `${tramite.day}/${tramite.month}-${tramite.hour}`;
      break;
    default:
      break;
  }
  return response;
}

const _showChartsPerHour = (): number => {
  const today = `${new Date().getFullYear()}/${new Date().getMonth()+1}/${new Date().getDate()}`;
  let $dateInit = document.querySelector('#nf-date-init') as HTMLInputElement;
  let dateInit = $dateInit !== null && $dateInit !== undefined ? $dateInit.value : today;
  let $dateFinish = document.querySelector('#nf-date-finish') as HTMLInputElement;
  let dateFinish = $dateFinish !== null && $dateFinish.value.length > 0 ? $dateFinish.value : today;
  return differenceInDays(new Date(dateFinish), new Date(dateInit));
}

const _data = () => {
  let data: any = {
    pending: [],
    timeout: [],
    failed: [],
    failedAddress: [],
    failedMatch: [],
    revised: [],
    validCard: [],
    refund: [],
    completed: [],
    labels: []
  }
  return data;
}

interface Itramite {
  day: number,
  failedAddress: string,
  pending: string,
  timeout: string,
  failed: string,
  failedMatch: string,
  revised: string,
  validCard: string,
  refund: string,
  completed: string,
  hour: number,
  month: number,
  order_total: string,
  status: string,
  timeStamp: string,
  week: number
}