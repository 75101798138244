import { CButton, CCol, CCollapse, CContainer, CRow } from "@coreui/react";
import { FC, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { FilterProps, StatusProps } from "./types";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// @ts-ignore
import * as locales from "react-date-range/dist/locale";

export const VitalsCustomersFilters: FC<FilterProps> = ({
  filters,
  setFilters,
  searchHandler,
  searchOneHandler,
}) => {
  const status = [
    "pending",
    "onhold",
    "completed",
    "revised",
    "timeout",
    "refund",
    "chargeback",
  ];

  const [showFilters, setShowFilters] = useState(false);
  const [state, setState] = useState([
    {
      startDate: filters.minCreatedAt,
      endDate: filters.maxCreatedAt,
      key: "selection",
    },
  ]);
  useEffect(() => {
    setFilters({
      ...filters,
      minCreatedAt: state[0].startDate,
      maxCreatedAt: state[0].endDate,
    });
  }, [state]);
  const showHandler = () => setShowFilters(!showFilters);
  const resetFiltersHandler = () => {
    const d = new Date();
    setState([
      {
        startDate: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
        endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
        key: "selection",
      },
    ]);
    setFilters({
      minCreatedAt: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
      maxCreatedAt: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
      status: status.filter((el) => el !== "pending" && el !== "onhold"),
      indCriteria: "orderId",
      indValue: "",
    });
  };
  const filterStatusHandler = ({ target }: any) => {
    const i = filters.status.indexOf(target.name);
    const newFilterStatus =
      i !== -1
        ? filters.status.filter((status) => status !== target.name)
        : filters.status.concat(target.name);
    setFilters({
      ...filters,
      status: newFilterStatus,
    });
  };

  const onChangeInputHandler = (e: any) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <CContainer fluid>
      <CRow>
        <CCol>
          <h3>Filters</h3>
        </CCol>
        <CCol>
          <CButton color="primary" onClick={showHandler} className={"m-1"}>
            {showFilters ? "Close filters" : "Open filters"}
          </CButton>
          <CButton
            color="primary"
            onClick={resetFiltersHandler}
            className={"m-1"}
          >
            Reset filters
          </CButton>
        </CCol>
        <CCol>
          <CButton onClick={searchHandler} color="primary" className={"m-1"}>
            Search
          </CButton>
        </CCol>
      </CRow>
      <CCollapse show={showFilters}>
        <CRow>
          <CCol sm="12" md="12" lg="6">
            <DateRangePicker
              onChange={(item: any) => {
                setState([item.selection]);
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              weekStartsOn={1}
              locale={locales.enGB}
              maxDate={new Date()}
              ranges={state}
              direction="horizontal"
            />
          </CCol>
          <CCol sm="0" md="0" lg="2"></CCol>
          <CCol sm="12" md="12" lg="4">
            <div
              style={{
                border: "1px solid #bbb",
                padding: "0.75em",
              }}
            >
              <h4>Status</h4>
              <StatusFilter
                arrStatus={status}
                status={filters.status}
                handler={filterStatusHandler}
              />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <h5>Find an Order</h5>
          </CCol>
        </CRow>
        <CRow style={{ alignItems: "center" }}>
          <CCol>
            <div>
              <select
                name="indCriteria"
                value={filters.indCriteria}
                onChange={onChangeInputHandler}
              >
                <option value="orderId">Order ID</option>
                <option value="email">Email</option>
              </select>
              <div style={{ margin: "0 10px", display: "inline" }}>=</div>
              <input
                type="text"
                name="indValue"
                id=""
                value={filters.indValue}
                onChange={onChangeInputHandler}
              />
            </div>
          </CCol>
          <CCol>
            <CButton
              color="primary"
              onClick={searchOneHandler}
              className={"m-1"}
            >
              Search order
            </CButton>
          </CCol>
        </CRow>
      </CCollapse>
    </CContainer>
  );
};

const StatusFilter: FC<StatusProps> = ({ arrStatus, status, handler }) => {
  const renderStatus = (el: string, i: number) => {
    return (
      <div
        key={i}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "130px",
          margin: "0.5rem 0",
          alignItems: "center",
        }}
      >
        <input
          type="checkbox"
          name={el}
          value="true"
          checked={status.includes(el)}
          onChange={handler}
        />
        <label style={{ margin: "0 0.5rem" }} htmlFor={el}>
          {el.charAt(0).toUpperCase() + el.slice(1)}
        </label>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {arrStatus.reverse().map(renderStatus)}
    </div>
  );
};
