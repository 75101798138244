
import { differenceInDays } from 'date-fns';

export const chartsWeek = (uspostalTests: IuspostalTest[]) => {
    const data = _data();
    let labels: any = [];
  for (let i = 0; i < uspostalTests.length; i++) {
    const include = labels.findIndex((label: number) => label === uspostalTests[i].week);
    if(include === -1 || labels.length === 0) {
      labels.push(uspostalTests[i].week)
    }
  }
  data.labels = labels;
  _createPositionInArrayData(labels, data);
  uspostalTests.map((uspostalTest: IuspostalTest) => {
    // agrega en la misma posicion de labels un +1 en el objecto data
    let typeCharts = 'week';
    _sumStatusInData(uspostalTest, labels, data, typeCharts);
  });
  return data;
}

export const chartsMonth = (uspostalTests: IuspostalTest[]) => {
  const data = _data();
  let labels: number[] = [];
  for (let i = 0; i < uspostalTests.length; i++) {
    const include = labels.findIndex((label: number) => label === uspostalTests[i].month);
    if(include === -1 || labels.length === 0) {
      labels.push(uspostalTests[i].month);
    }
  }
  data.labels = labels;
  _createPositionInArrayData(labels, data);
  uspostalTests.map((uspostalTest: any) => {
    // agrega en la misma posicion de labels un +1 en el objecto data
    let typeCharts = 'month';
    _sumStatusInData(uspostalTest, labels, data, typeCharts);
  });
  return data;
}

export const chartsDay = (uspostalTests: IuspostalTest[]) => {
  const data = _data();
  let labels: any[] = [];
  for (let i = 0; i < uspostalTests.length; i++) {
    const include = labels.findIndex((label: string) => label === `${uspostalTests[i].day}/${uspostalTests[i].month}`);
    if(include === -1 || labels.length === 0) {
      labels.push(`${uspostalTests[i].day}/${uspostalTests[i].month}`)
    }
  }
  data.labels = labels;

  _createPositionInArrayData(labels, data);
  uspostalTests.map((uspostalTest: any) => {
    // agrega en la misma posicion de labels un +1 en el objecto data
    let typeCharts = 'day';
    _sumStatusInData(uspostalTest, labels, data, typeCharts);
  });
  return data;
}

export const chartsHour = (uspostalTests: IuspostalTest[]) => {
  const data = _data();
  let labels: any = [];

  const diffInDays = _showChartsPerHour();
  if(diffInDays > 1) {
    return data;
  }
  for (let i = 0; i < uspostalTests.length; i++) {
    const include = labels.findIndex((label: string) => label === `${uspostalTests[i].day}/${uspostalTests[i].month}-${uspostalTests[i].hour}`);
    if(include === -1 || labels.length === 0) {
      labels.push(`${uspostalTests[i].day}/${uspostalTests[i].month}-${uspostalTests[i].hour}`)
    }
  }

  data.labels = labels.sort((a: string, b: string) => parseInt(a.split('-')[1]) - parseInt(b.split('-')[1]));
  
  _createPositionInArrayData(labels, data);
  uspostalTests.map((uspostalTest: any) => {
    // agrega en la misma posicion de labels un +1 en el objecto data
    let typeCharts = 'hour';
    _sumStatusInData(uspostalTest, labels, data, typeCharts);
  });
  return data;
}

const _createPositionInArrayData = (labels: any[], data: any) => {
  labels.map(() => {
    data.chargeback.push(0);
    data.refund.push(0);
    data.completed.push(0);
  });
}

const _sumStatusInData = (uspostalTest: IuspostalTest, labels: any[], data: any, typeCharts: string): void => {
  for (let j = 0; j < labels.length; j++) {
    let condicional = _condicional(uspostalTest, typeCharts);
    if(condicional === labels[j]) {
      if(uspostalTest.chargeback) {
        data.chargeback[j] = data.chargeback[j]+1;
      }
      if(uspostalTest.refund) {
        data.refund[j] = data.refund[j]+1;
      }
      if(uspostalTest.completed) {
        data.completed[j] = data.completed[j]+1;
      }
    }
  }
}

const _condicional = (uspostalTest: IuspostalTest, typeCharts: string) => {
  let response: string | number = '';
  switch (typeCharts) {
    case 'week':
      response = uspostalTest.week;
      break;
    case 'month':
      response = uspostalTest.month;
      break;
    case 'day':
      response = `${uspostalTest.day}/${uspostalTest.month}`;
      break;
    case 'hour':
      response = `${uspostalTest.day}/${uspostalTest.month}-${uspostalTest.hour}`;
      break;
    default:
      break;
  }
  return response;
}

const _showChartsPerHour = (): number => {
  const today = `${new Date().getFullYear()}/${new Date().getMonth()+1}/${new Date().getDate()}`;
  let $dateInit = document.querySelector('#nf-date-init') as HTMLInputElement;
  let dateInit = $dateInit !== null && $dateInit !== undefined ? $dateInit.value : today;
  let $dateFinish = document.querySelector('#nf-date-finish') as HTMLInputElement;
  let dateFinish = $dateFinish !== null && $dateFinish.value.length > 0 ? $dateFinish.value : today;
  return differenceInDays(new Date(dateFinish), new Date(dateInit));
}

const _data = () => {
  let data: any = {
    chargeback: [],
    refund: [],
    completed: [],
    labels: []
  }
  return data;
}

interface IuspostalTest {
  day: number,
  chargeback: string,
  refund: string,
  completed: string,
  hour: number,
  month: number,
  order_total: string,
  status: string,
  IuspostalTest: string,
  week: number
}