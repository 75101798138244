import { CButton, CCol, CRow } from "@coreui/react";
import { FC, Fragment, useEffect, useState } from "react";
import { DetailsProps } from "../types";
import { retrySendEmail, updateOrder } from "../_customersVitals";
import { OrderInput } from "./OrderInput";

const defaultForm = { status: "" };

export const OrderDetails: FC<DetailsProps> = ({
  activeEdit,
  order,
  setFloating,
  form,
  setForm,
  styles,
  onSaveAttempt,
}) => {
  const [edit, setEdit] = useState(false);
  const handleEdit = () => {
    if (!edit) {
      setForm({
        name: order.name,
        lastName: order.lastName,
        age: order.age,
        email: order.email,
        status: order.status,
      });
    } else {
      setForm(defaultForm);
    }
    setEdit(!edit);
  };

  useEffect(() => {
    setEdit(false);
  }, [activeEdit]);

  const submitHandler = (e: any) => {
    e.preventDefault();
  };

  const resendHandler = async (e: any) => {
    e.preventDefault();
    // const sent = await retrySendEmail(order._id);
    if (true) {
      setFloating(true);
    }
  };

  const setToClipboard = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(e.target.innerHTML);
    setFloating(true);
  };

  const changeHandler = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSaveHandler = () => {
    onSaveAttempt({
      _id: order._id,
      ...(order.status !== form.status && {
        status: { field: "status", old: order.status, new: form.status },
      }),
    });
  };

  return (
    <Fragment>
      <CRow>
        <CCol>
          {/* <CButton color="warning" onClick={handleEdit}>Edit Order</CButton> */}
          {edit ? (
            <Fragment>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={handleEdit}
              >
                Cancel
              </button>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={onSaveHandler}
              >
                Save
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={handleEdit}
              >
                Enable Edit
              </button>
            </Fragment>
          )}
        </CCol>
      </CRow>
      <form onSubmit={submitHandler} data-id={order._id}></form>
      <table className="table table-sm table-light table-striped table-borderless table-hover mr-3">
        <thead className="thead-dark">
          <tr>
            <th colSpan={6} className="text-center">
              General
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="pl-4" scope="row">
              Status
            </th>
            <td className="text-right pr-4">
              {edit ? (
                <select
                  style={{
                    border: 0,
                    borderBottom: "1px solid black",
                    background: "transparent",
                    padding: 0,
                  }}
                  value={form.status}
                  name="status"
                  id={"status_" + order._id}
                  onChange={changeHandler}
                >
                  <option value="pending">Pending</option>
                  <option value="onhold">On Hold</option>
                  <option value="completed">Completed</option>
                  <option value="revised">Revised</option>
                  <option value="timeout">Timeout</option>
                  <option value="refund">Refund</option>
                  <option value="chargeback">Changeback</option>
                </select>
              ) : (
                order.status
              )}
            </td>
            <StaticInput name="Order ID" value={order.orderId} />
            <StaticInput name="Package" value={order.package} />
          </tr>
          <tr>
            <StaticInput name="Place" value={order.place} />
            <StaticInput name="Year registered" value={order.yearRegistered} />
            <StaticInput name="priceTotal" value={order.priceTotal} />
          </tr>
          <tr>
            <StaticInput name="Applicant" value={order.applicant} />
            <StaticInput name="Birth date" value={order.birthDate} />
            <StaticInput name="Email" value={order.emailClient} />
          </tr>
          <tr>
            <StaticInput name="Project" value={order.project} />
            <StaticInput name="Product" value={order.product} />
            <StaticInput name="Quantity" value={order.quantity} />
          </tr>
        </tbody>
      </table>
      <table className="table table-sm table-light table-striped table-borderless table-hover mr-3">
        <thead className="thead-dark">
          <tr>
            <th colSpan={6} className="text-center">
              Delivery
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <StaticInput
              name="Contact"
              value={`
                ${order.deliveryDetails.forename} ${order.deliveryDetails.surname}
              `}
            />
            <StaticInput name="Address" value={order.deliveryDetails.address} />
            <StaticInput name="Town" value={order.deliveryDetails.town} />
          </tr>
          <tr>
            <StaticInput name="Country" value={order.deliveryDetails.country} />
            <StaticInput
              name="Zip Code"
              value={order.deliveryDetails.zipCode}
            />
            <StaticInput
              name="Priority"
              value={order.deliveryDetails.priority}
            />
          </tr>
        </tbody>
      </table>

      {order.marriage && (
        <table className="table table-sm table-light table-striped table-borderless table-hover mr-3">
          <thead className="thead-dark">
            <tr>
              <th colSpan={6} className="text-center">
                Marriage
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <StaticInput
                name="Person 1"
                value={`${order.marriage.party1Forenames} ${order.marriage.party1Surname}`}
              />
              <StaticInput
                name="Person 2"
                value={`${order.marriage.party2Forenames} ${order.marriage.party2Surname}`}
              />
              <StaticInput
                name="Date of Marriage"
                value={order.marriage.marriageDate}
              />
            </tr>
          </tbody>
        </table>
      )}
      {order.death && (
        <table className="table table-sm table-light table-striped table-borderless table-hover mr-3">
          <thead className="thead-dark">
            <tr>
              <th colSpan={8} className="text-center">
                Death
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <StaticInput
                name="Deceased"
                value={`${order.death.forenameOfDeceased} ${order.death.surnameOfDeceased}`}
              />
              <StaticInput
                name="Father"
                value={`${order.death.fatherForename} ${order.death.fatherSurname}`}
              />
              <StaticInput
                name="Mother"
                value={`${order.death.motherForename} ${order.death.motherSurname}`}
              />
              <StaticInput name="Age" value={order.death.ageOfDeceased} />
            </tr>
            <tr>
              <StaticInput name="Date" value={order.death.dateOfDeceased} />
              <StaticInput
                name="Relationship"
                value={order.death.relationshipDeceased}
              />
              <StaticInput
                name="Place"
                value={order.death.placeOfDeceased}
                colspan={2}
              />
            </tr>
          </tbody>
        </table>
      )}

      {order.birth && (
        <table className="table table-sm table-light table-striped table-borderless table-hover mr-3">
          <thead className="thead-dark">
            <tr>
              <th colSpan={6} className="text-center">
                Birth
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <StaticInput
                name="Born"
                value={`${order.birth.forename} ${order.birth.subjectSurname}`}
              />
              <StaticInput
                name="Father"
                value={`${order.birth.fatherForename} ${order.birth.fatherSurname}`}
              />
              <StaticInput
                name="Mother"
                value={`${order.birth.motherForename} ${order.birth.motherSurname}`}
              />
            </tr>
          </tbody>
        </table>
      )}
      {/* <CRow>
        <CCol>
          {edit ? (
            <Fragment>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={handleEdit}
              >
                Cancel
              </button>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={onSaveHandler}
              >
                Save
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={handleEdit}
              >
                Enable Edit
              </button>
              <button
                style={{ border: "1px solid #f9b115", outline: "none" }}
                onClick={resendHandler}
              >
                Resend Email
              </button>
            </Fragment>
          )}
        </CCol>
      </CRow>
      <form onSubmit={submitHandler} data-id={order._id}>
        <CRow>
          <CCol>
            <h5 className={styles.rowHeader}>Order</h5>
            <div className={styles.row}>
              <div>Order ID</div>
              <div onClick={setToClipboard}>{order.orderId}</div>
            </div>
            <div className={styles.row}>
              <div>Payment Method</div>
              <div>{order.paymentMethod}</div>
            </div>
            <div className={styles.row}>
              <div>Price</div>
              <div>{order.priceTotal} €</div>
            </div>
            <div className={styles.row}>
              <div>Product</div>
              <div>{order.product}</div>
            </div>
            <div className={styles.row}>
              <div>Project</div>
              <div>{order.project}</div>
            </div>
            <div className={styles.row}>
              <div>Serial Number</div>
              <div onClick={setToClipboard}>{order.serialNumber}</div>
            </div>
            <div className={styles.row}>
              <div>Created at</div>
              <div>{order.createdAt}</div>
            </div>
            <OrderInput
              edit={edit}
              value={form.status}
              id={order._id}
              handleChange={changeHandler}
              name="status"
              styles={styles}
              select
            >
              <div>{order.status}</div>
            </OrderInput>
          </CCol>
          <CCol>
            <h5 className={styles.rowHeader}>Client</h5>
            <OrderInput
              edit={edit}
              value={form.name}
              id={order._id}
              handleChange={changeHandler}
              name="name"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.name}</div>
            </OrderInput>
            <OrderInput
              edit={edit}
              value={form.lastName}
              id={order._id}
              handleChange={changeHandler}
              name="lastName"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.lastName}</div>
            </OrderInput>
            <OrderInput
              edit={edit}
              value={form.age}
              id={order._id}
              handleChange={changeHandler}
              name="age"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.age}</div>
            </OrderInput>
            <OrderInput
              edit={edit}
              value={form.email}
              id={order._id}
              handleChange={changeHandler}
              name="email"
              styles={styles}
            >
              <div onClick={setToClipboard}>{order.email}</div>
            </OrderInput>
            <div className={styles.row}>
              <div>Gender</div>
              <div>{order.gender}</div>
            </div>
            <div className={styles.row}>
              <div>IQ Score</div>
              <div>{order.score.totalScore}</div>
            </div>
          </CCol>
        </CRow>
      </form> */}
    </Fragment>
  );
};

const StaticInput: FC<{ name: string; value: string; colspan?: number }> = ({
  name,
  value,
  colspan = 1,
}) => {
  return (
    <Fragment>
      <th className="pl-4" scope="row" colSpan={colspan}>
        {name}
      </th>
      <td className="text-right pr-4" colSpan={colspan}>
        {value}
      </td>
    </Fragment>
  );
};
