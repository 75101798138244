import "./subordinate.sass";
import { CButton, CModalBody } from "@coreui/react";
import React, { useState } from "react";
import Modal from "../../../features/modal/Modal";
import { createSubordinateOrder } from "./_customersTramites";

interface Props {
  show: boolean;
  setShow: Function;
  parentOrder: {
    email: string;
    parentId: string;
    tipovia: string;
    nombrevia: string;
    numero: string;
    bis: string;
    bloque: string;
    escalera: string;
    piso: string;
    puerta: string;
    cp: string;
    provincia: string;
    localidad: string;
    pais: string;
    ben1: any;
    ben2: any;
    ben3: any;
    ben4: any;
    ben5: any;
  };
}

type Ben = {
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento: string;
  identificacion: {
    tipo: string;
    numero: string;
  };
};

const ben = {
  nombre: "",
  primerApellido: "",
  segundoApellido: "",
  fechaNacimiento: "//",
  identificacion: {
    tipo: "",
    numero: "",
  },
};

const defFormData = {
  nombre: "",
  primerApellido: "",
  segundoApellido: "",
  movil: "",
  date: "",
  numeroSeguridadSocial: {
    parte1NAf: "",
    parte2NAf: "",
  },
  identificacion: {
    tipo: "",
    numero: "",
    caducidad: "",
  },
};

export const SubordinateOrderForm: React.FC<Props> = ({
  show,
  setShow,
  parentOrder,
}) => {
  const toggleAndReset = () => {
    setShow(!show);
    setFormData(defFormData);
    setb1(false);
    setb2(false);
    setb3(false);
    setb4(false);
    setb5(false);
  };

  const [showLogs, setShowLogs] = useState("");
  const [b1, setb1] = useState(false);
  const [b2, setb2] = useState(false);
  const [b3, setb3] = useState(false);
  const [b4, setb4] = useState(false);
  const [b5, setb5] = useState(false);
  const [formData, setFormData] = useState(defFormData);

  const onInput = (e: any) => {
    const target: { name: string; value: string; type: string } = e.target;
    let value = target.value;
    if (value && target.type === "date") {
      value = value.split("-").reverse().join("/");
    }
    if (value && target.name === "numero") {
      const nifType = isNaN(+value.substr(0, 1)) ? "6" : "1";

      return setFormData({
        ...formData,
        identificacion: {
          ...formData.identificacion,
          tipo: nifType,
          [target.name]: value,
        },
      });
    }
    if (["caducidad"].includes(target.name))
      return setFormData({
        ...formData,
        identificacion: {
          ...formData.identificacion,
          [target.name]: value,
        },
      });
    if (target.name === "ss" && value.length > 2)
      return setFormData({
        ...formData,
        numeroSeguridadSocial: {
          parte1NAf: value.substr(0, 2),
          parte2NAf: value.substr(2),
        },
      });
    setFormData({ ...formData, [target.name]: value });
  };

  const onChecked = (e: any) => {
    const { target } = e;
    const { name } = target;
    if (target.checked) {
      switch (name) {
        case "b1":
          setb1(true);
          break;
        case "b2":
          setb2(true);
          break;
        case "b3":
          setb3(true);
          break;
        case "b4":
          setb4(true);
          break;
        case "b5":
          setb5(true);
          break;
      }
    } else {
      switch (name) {
        case "b1":
          setb1(false);
          break;
        case "b2":
          setb2(false);
          break;
        case "b3":
          setb3(false);
          break;
        case "b4":
          setb4(false);
          break;
        case "b5":
          setb5(false);
          break;
      }
    }
  };

  const defaultOrder = {
    attempts: 1,
    parentId: parentOrder.parentId,
    highPriority: false,
    domain: "tramite-electronico.com",
    status: "pending",
    date_created: new Date(),
    numeroReferenciaSS: "",
    project: "tramitartarjetasanitariaeuropea-com",
    tipoSolicitud: "#SOLO_BENEFICIARIO",
    numeroBeneficiarios: "0",
    orderID: Date.now().toString(),
    order_total: "0€",
    titular: {
      email: parentOrder.email,
      domicilio: {
        tipovia: parentOrder.tipovia,
        nombrevia: parentOrder.nombrevia,
        numero: parentOrder.numero,
        bis: parentOrder.bis,
        bloque: parentOrder.bloque,
        escalera: parentOrder.escalera,
        piso: parentOrder.piso,
        puerta: parentOrder.puerta,
        cp: parentOrder.cp,
        provincia: parentOrder.provincia,
        localidad: parentOrder.localidad,
        pais: parentOrder.pais,
      },
    },
    logs: [],
    ben1: ben,
    ben2: ben,
    ben3: ben,
    ben4: ben,
    ben5: ben,
  };

  const renderSelectBens = () => {
    const { ben1, ben2, ben3, ben4, ben5 } = parentOrder;
    const arrB = [b1, b2, b3, b4, b5];
    const renderBen = (ben: Ben, i: number) => {
      if (!ben || !ben.nombre) return null;
      const { nombre, primerApellido, segundoApellido } = ben;
      const parsedBen = {
        nombre: nombre.charAt(0).toUpperCase() + nombre.slice(1),
        primerApellido:
          primerApellido.charAt(0).toUpperCase() + primerApellido.slice(1),
        segundoApellido:
          segundoApellido.charAt(0).toUpperCase() + segundoApellido.slice(1),
      };
      return ben && ben.nombre ? (
        <div className="ben-row">
          <input
            type="checkbox"
            name={`b${i + 1}`}
            checked={arrB[i]}
            onChange={onChecked}
          />
          <div>{`${parsedBen.nombre} ${parsedBen.primerApellido} ${parsedBen.segundoApellido}`}</div>
        </div>
      ) : (
        ""
      );
    };
    return (
      <div style={{ marginTop: "10px" }}>
        <h4>Beneficiaries</h4>
        {[
          Object.keys(ben1).length ? JSON.parse(ben1) : {},
          Object.keys(ben2).length ? JSON.parse(ben2) : {},
          Object.keys(ben3).length ? JSON.parse(ben3) : {},
          Object.keys(ben4).length ? JSON.parse(ben4) : {},
          Object.keys(ben5).length ? JSON.parse(ben5) : {},
        ].map(renderBen)}
      </div>
    );
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const { ben1, ben2, ben3, ben4, ben5 } = parentOrder;

    const arrBen = [ben1, ben2, ben3, ben4, ben5];
    const objBen = {
      ben1: ben,
      ben2: ben,
      ben3: ben,
      ben4: ben,
      ben5: ben,
    };
    const validBens = [b1, b2, b3, b4, b5]
      .map((b, i) => (b ? JSON.parse(arrBen[i]) : null))
      .filter((el) => el !== null);

    validBens.forEach((ben, i) => {
      switch (i) {
        case 0:
          objBen.ben1 = ben;
          break;
        case 1:
          objBen.ben2 = ben;
          break;
        case 2:
          objBen.ben3 = ben;
          break;
        case 3:
          objBen.ben4 = ben;
          break;
        case 4:
          objBen.ben5 = ben;
          break;
      }
    });

    const subordinateOrder = {
      ...defaultOrder,
      ...objBen,
      numeroBeneficiarios: "" + validBens.length,
      titular: { ...defaultOrder.titular, ...formData },
    };
    const isValid = valForm();
    const logsFc = () => {
      setShowLogs("All fields are required");
      setTimeout(() => {
        setShowLogs("");
      }, 2000);
    };
    isValid ? setShowLogs("") : logsFc();

    if (isValid) {
      const response = await createSubordinateOrder(subordinateOrder);
      if (response == true || response == "true") {
        setShowLogs("Order created successfully. Retry sent.");
        setTimeout(() => {
          setShowLogs("");
          toggleAndReset();
        }, 2000);
      } else {
        setShowLogs("Error. Contact with development team.");
        setTimeout(() => {
          setShowLogs("");
          toggleAndReset();
        }, 2000);
      }
    }
  };

  const valForm = () => {
    const {
      nombre,
      primerApellido,
      segundoApellido,
      movil,
      identificacion: { numero, tipo },
    } = formData;
    return !nombre ||
      !primerApellido ||
      !segundoApellido ||
      !movil ||
      !tipo ||
      !numero
      ? false
      : true;
  };

  const pasteHandler = (e: any) => {
    // @ts-ignore
    let paste = (e.clipboardData || window.clipboardData).getData('text');
    console.log(paste);
    e.target.value = paste.split("/").reverse().join("-");
  }

  return (
    <Modal
      show={show}
      title="Subordinate Order (WIP)"
      toggleTrigger={toggleAndReset}
    >
      <CModalBody>
        <form
          className="d-flex flex-column justify-content-between"
          style={{ backgroundColor: "#3c4b64", height: "100%" }}
          action="#"
          id="subordinateForm"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <div className="orderType">Order Type: SOLO BENEFICIARIOS</div>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex flex-column align-items-start input-modal-wrapper">
              <label htmlFor="">Name</label>
              <input
                className="input-modal"
                name="nombre"
                onInput={onInput}
                type="text"
                value={formData.nombre}
              />
            </div>
            <div className="d-flex flex-column align-items-start input-modal-wrapper">
              <label htmlFor="">DNI/NIE</label>
              <input
                className="input-modal"
                name="numero"
                onInput={onInput}
                value={formData.identificacion.numero}
                type="text"
              />
            </div>
            <div className="d-flex flex-column align-items-start input-modal-wrapper">
              <label htmlFor="">F. Surname</label>
              <input
                className="input-modal"
                name="primerApellido"
                onInput={onInput}
                value={formData.primerApellido}
                type="text"
              />
            </div>
            <div className="d-flex flex-column align-items-start input-modal-wrapper">
              <label htmlFor="">NIF Expiration</label>
              <input
                className="input-modal"
                name="caducidad"
                onInput={onInput}
                onPaste={pasteHandler}
                value={formData.identificacion.caducidad
                  .split("/")
                  .reverse()
                  .join("-")}
                type="date"
              />
            </div>
            <div className="d-flex flex-column align-items-start input-modal-wrapper">
              <label htmlFor="">L. Surname</label>
              <input
                className="input-modal"
                name="segundoApellido"
                onInput={onInput}
                value={formData.segundoApellido}
                type="text"
              />
            </div>
            <div className="d-flex flex-column align-items-start input-modal-wrapper">
              <label htmlFor="">Birth Date</label>
              <input
                className="input-modal"
                name="date"
                onChange={onInput}
                onPaste={pasteHandler}
                value={formData.date.split("/").reverse().join("-")}
                type="date"
              />
            </div>
            <div className="d-flex flex-column align-items-start input-modal-wrapper">
              <label htmlFor="">Phone Number</label>
              <input
                className="input-modal"
                name="movil"
                onInput={onInput}
                value={formData.movil}
                type="tel"
              />
            </div>
            <div className="d-flex flex-column align-items-start input-modal-wrapper">
              <label htmlFor="">SS Number</label>
              <input
                className="input-modal"
                name="ss"
                onInput={onInput}
                type="number"
              />
            </div>
          </div>
          {renderSelectBens()}
          <div>
            <CButton
              type="submit"
              className="text-light border-dark btn-success mt-4 mb-2"
            >
              Create Order
            </CButton>
          </div>
          <div className={`${showLogs.length ? "" : "hidden"}`}>{showLogs}</div>
        </form>
      </CModalBody>
    </Modal>
  );
};
