import axios from "axios";
import { subSeconds, addDays } from "date-fns";

export const setCorrectMaxDate = (maxDate: Date) =>
  subSeconds(addDays(maxDate, 1), 1);

export const requestData = async (filters: any, one: boolean = false) => {
  const url = process.env.REACT_APP_API_VITALS + "/api";
  const token = localStorage.getItem("vitalsToken");
  // const token = await authIQ();
  if (!token) return null;

  try {
    const { data } = one
      ? await axios.get(
          `${url}/orders?${filters.indCriteria}=${filters.indValue}`,
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
      : await axios.get(
          `${url}/orders?minDate=${
            filters.minCreatedAt
          }&maxDate=${setCorrectMaxDate(
            filters.maxCreatedAt
          )}&status=${filters.status.toString()}`,
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

    return data.map((el: any, i: number) => ({
      ...el,
      applicant: `${el.deliveryDetails.forename} ${el.deliveryDetails.surname}`,
      createdAt: new Date(el.createdAt).toLocaleDateString(),
    }));
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const updateOrder = async (_id: any, order: any) => {
  const url = process.env.REACT_APP_API_VITALS + "/api";
  const token = localStorage.getItem("vitalsToken");
  // const token = await authIQ();
  if (!token) return null;

  try {
    await axios.patch(`${url}/orders/${_id}`, order, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const retrySendEmail = async (_id: any) => {
  const url = process.env.REACT_APP_API_VITALS + "/api";
  const token = localStorage.getItem("vitalsToken");
  if (!token) return false;

  try {
    await axios.get(`${url}/orders/${_id}/retry`, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
