import axios from "axios";
import Swal from "sweetalert2";
const html2pdf = require("html2pdf.js");

export const data = (tramites: any) => {
  return tramites.map((tramite: any) => {
    // TODO remove if when enpoint /orders/modify fields are added to the DDBB
    if (tramite.order) {
      tramite = tramite.order;
    }
    return {
      id: tramite._id,
      parentId: tramite.parentId ? tramite.parentId : "",
      nSoporte: tramite.titular.identificacion.nSoporte ? tramite.titular.identificacion.nSoporte : "",
      tipoVia: tramite.titular.domicilio.tipoVia,
      mobile: tramite.titular.movil,
      date: tramite.titular.date,
      sms: tramite.sms,
      nombreVia: tramite.titular.domicilio.nombreVia,
      numero: tramite.titular.domicilio.numero,
      bis: tramite.titular.domicilio.bis,
      bloque: tramite.titular.domicilio.bloque,
      escalera: tramite.titular.domicilio.escalera,
      piso: tramite.titular.domicilio.piso,
      puerta: tramite.titular.domicilio.puerta,
      cp: tramite.titular.domicilio.cp,
      localidad: tramite.titular.domicilio.localidad,
      provincia: tramite.titular.domicilio.provincia,
      pais: tramite.titular.domicilio.pais,
      direccion: tramite.titular.domicilio.direccion,
      fullName: `${tramite.titular.nombre} ${tramite.titular.primerApellido} ${tramite.titular.segundoApellido}`,
      date_created: tramite.date_created.split("T")[0],
      numeroReferenciaSS: tramite.numeroReferenciaSS
        ? tramite.numeroReferenciaSS
        : "",
      numeroSeguridadSocial1: tramite.titular.numeroSeguridadSocial.parte1NAf,
      numeroSeguridadSocial2: tramite.titular.numeroSeguridadSocial.parte2NAf,
      dni: tramite.titular.identificacion.numero,
      dniType: tramite.titular.identificacion.tipo,
      caducidad: tramite.titular.identificacion.caducidad
        ? tramite.titular.identificacion.caducidad
        : "",
      email: tramite.titular.email,
      status: tramite.status,
      tag: tramite.tag ? tramite.tag : "",
      flow: tramite.flow ? tramite.flow : "",
      name: tramite.titular.nombre,
      surname: tramite.titular.primerApellido,
      secondSurname: tramite.titular.segundoApellido,
      orderID: tramite.orderID,
      project: tramite.project,
      attempts: tramite.attempts,
      domain: tramite.domain,
      tipoSolicitud: tramite.tipoSolicitud,
      numeroBeneficiarios: tramite.numeroBeneficiarios,
      order_total: tramite.order_total,
      error: tramite.error,
      saleid: tramite.saleid,
      ben1: tramite.ben1
        ? {
            identificacion: {
              tipo:
                tramite.ben1.identificacion && tramite.ben1.identificacion.tipo
                  ? tramite.ben1.identificacion.tipo
                  : "",
              numero:
                tramite.ben1.identificacion &&
                tramite.ben1.identificacion.numero
                  ? tramite.ben1.identificacion.numero
                  : "",
            },
            nombre: tramite.ben1.nombre,
            primerApellido: tramite.ben1.primerApellido,
            segundoApellido: tramite.ben1.segundoApellido,
            fechaNacimiento: tramite.ben1.fechaNacimiento
              ? tramite.ben1.fechaNacimiento
              : "",
          }
        : undefined,
      ben2: tramite.ben2
        ? {
            identificacion: {
              tipo:
                tramite.ben2.identificacion && tramite.ben2.identificacion.tipo
                  ? tramite.ben2.identificacion.tipo
                  : "",
              numero:
                tramite.ben2.identificacion &&
                tramite.ben2.identificacion.numero
                  ? tramite.ben2.identificacion.numero
                  : "",
            },
            nombre: tramite.ben2.nombre,
            primerApellido: tramite.ben2.primerApellido,
            segundoApellido: tramite.ben2.segundoApellido,
            fechaNacimiento: tramite.ben2.fechaNacimiento
              ? tramite.ben2.fechaNacimiento
              : "",
          }
        : undefined,
      ben3: tramite.ben3
        ? {
            identificacion: {
              tipo:
                tramite.ben3.identificacion && tramite.ben3.identificacion.tipo
                  ? tramite.ben3.identificacion.tipo
                  : "",
              numero:
                tramite.ben3.identificacion &&
                tramite.ben3.identificacion.numero
                  ? tramite.ben3.identificacion.numero
                  : "",
            },
            nombre: tramite.ben3.nombre,
            primerApellido: tramite.ben3.primerApellido,
            segundoApellido: tramite.ben3.segundoApellido,
            fechaNacimiento: tramite.ben3.fechaNacimiento
              ? tramite.ben3.fechaNacimiento
              : "",
          }
        : undefined,
      ben4: tramite.ben4
        ? {
            identificacion: {
              tipo:
                tramite.ben4.identificacion && tramite.ben4.identificacion.tipo
                  ? tramite.ben4.identificacion.tipo
                  : "",
              numero:
                tramite.ben4.identificacion &&
                tramite.ben4.identificacion.numero
                  ? tramite.ben4.identificacion.numero
                  : "",
            },
            nombre: tramite.ben4.nombre,
            primerApellido: tramite.ben4.primerApellido,
            segundoApellido: tramite.ben4.segundoApellido,
            fechaNacimiento: tramite.ben4.fechaNacimiento
              ? tramite.ben4.fechaNacimiento
              : "",
          }
        : undefined,
      ben5: tramite.ben5
        ? {
            identificacion: {
              tipo:
                tramite.ben5.identificacion && tramite.ben5.identificacion.tipo
                  ? tramite.ben5.identificacion.tipo
                  : "",
              numero:
                tramite.ben5.identificacion &&
                tramite.ben5.identificacion.numero
                  ? tramite.ben5.identificacion.numero
                  : "",
            },
            nombre: tramite.ben5.nombre,
            primerApellido: tramite.ben5.primerApellido,
            segundoApellido: tramite.ben5.segundoApellido,
            fechaNacimiento: tramite.ben5.fechaNacimiento
              ? tramite.ben5.fechaNacimiento
              : "",
          }
        : undefined,
      logs: tramite.logs ? tramite.logs : undefined,
    };
  });
};
type RefundInputs = {
  reason: string;
  otherReason: string;
  amount: string;
};

type RefundOrderInfo = {
  id: string;
  value: string;
  project: string;
  order_total: string;
};

export const createLogEntry = async (
  id: string,
  token: string,
  type: string,
  info?: string
) => {
  const url = `${process.env.REACT_APP_TARJETA_SANITARIA_URL}/order/${id}/logs`;
  const agent = localStorage.getItem("username");
  const now = new Date();
  let body = {};
  const bodyTypes: { [key: string]: any } = {
    refund: {
      flujo: "",
      accion: "refund",
      errores: "",
      status: "refund",
    },
    update: {
      flujo: "",
      accion: "Edit order",
      errores: "",
      status: "",
    },
    "download mail": {
      flujo: "",
      accion: "download mail",
      errores: "",
      status: "",
    },
    protection: {
      flujo: "",
      accion: "date protection",
      errores: "",
      status: "",
    },
    retry: {
      flujo: "",
      accion: "retry",
      errores: "",
      status: "",
    },
  };
  for (const key in bodyTypes) {
    if (key.indexOf(type) >= 0) {
      body = bodyTypes[key];
    }
  }
  try {
    const { status, data } = await axios.post(
      url,
      {
        fecha: now.toLocaleDateString("en-GB"),
        hora: now.toLocaleTimeString("en-GB").split(":").slice(0, 2).join(":"),
        agente: agent?.split("@")[0],
        info,
        ...body,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

//* New refund function
export const changeStatusByRefund = async (
  refundData: RefundInputs,
  { id, value, project, order_total }: RefundOrderInfo
): Promise<boolean> => {
  const url =
    project === "tramitartarjetasanitariaeuropea-com"
      ? `${process.env.REACT_APP_TARJETA_SANITARIA_URL}/order/refund/${id}`
      : `${process.env.REACT_APP_API_VIDA_LABORAL_URL}/order/refund/${id}`;
  const token = localStorage.getItem("token");
  const body = {
    refundType: refundData.amount,
    reason:
      refundData.reason === "Otro" ? refundData.otherReason : refundData.reason,
    total: order_total,
  };
  try {
    const { status, data } = await axios.patch(`${url}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if ((status === 200 || status === 201) && data) {
      createLogEntry(
        id,
        <string>token,
        "refund",
        `Refund reason: ${
          refundData.reason === "Otro"
            ? refundData.otherReason
            : refundData.reason
        }; Amount: ${
          refundData.amount === "Total"
            ? order_total + " €"
            : (+order_total * 0.8).toFixed(2) + " €"
        }`
      );
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const changeStatus = (e: any) => {
  const { value } = e.target;
  const { project, id } = e.target.dataset;

  let url = "";
  if (project === "tramitartarjetasanitariaeuropea-com") {
    url = `${process.env.REACT_APP_TARJETA_SANITARIA_URL}/order/${id}`;
  } else {
    url = `${process.env.REACT_APP_API_VIDA_LABORAL_URL}/order/${id}`;
  }
  const token = localStorage.getItem("token");
  const body = { status: value };
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, change it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const order = await axios.patch(`${url}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (order.status === 200 || order.status === 201) {
        createLogEntry(id, <string>token, "refund");
        Swal.fire("Update!", "changed status.", "success");
      } else {
        Swal.fire("Error", "error");
      }
    }
  });
};

export const remove = (e: any) => {
  const { project, id } = e.target.dataset;
  let url = "";
  if (project === "tramitartarjetasanitariaeuropea-com") {
    url = `${process.env.REACT_APP_TARJETA_SANITARIA_URL}/order/${id}`;
  } else {
    url = `${process.env.REACT_APP_API_VIDA_LABORAL_URL}/order/${id}`;
  }
  const token = localStorage.getItem("token");
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const order = await axios.delete(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (order.status === 200 || order.status === 201) {
        Swal.fire("Delete!", "Your order has been deleted.", "success");
      } else {
        Swal.fire("Error", "error");
      }
    }
  });
};

export const retry = async (e: any) => {
  const { project, id } = e.target.dataset;
  let url = "";
  const $loader = <HTMLElement>document.querySelector(`[data-loader='${id}']`);
  $loader?.classList.remove("none");
  if (project === "tramitartarjetasanitariaeuropea-com") {
    url = `${process.env.REACT_APP_TARJETA_SANITARIA_URL}`;
  } else {
    url = `${process.env.REACT_APP_API_VIDA_LABORAL_URL}`;
  }
  const token: any = localStorage.getItem("token");
  const order = await axios.get(`${url}/order/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (order.status !== 200 && order.status !== 201) {
    Swal.fire({
      icon: "error",
      title: "error",
    });
    return;
  }
  if (project === "tramitartarjetasanitariaeuropea-com") {
    const retryTarjetaSanitaria = await _retryTarjetaSanitaria(
      order,
      url,
      token
    );
    if (retryTarjetaSanitaria) {
      Swal.fire({
        icon: "success",
        title: "activating scraping",
      });
      createLogEntry(id, <string>token, "retry");
      $loader?.classList.add("none");
      return;
    }
  } else {
    const retryVidaLaboral = await _retryVidaLaboral(order, url, token);
    if (retryVidaLaboral) {
      Swal.fire({
        icon: "success",
        title: "activating scraping",
      });
      createLogEntry(id, <string>token, "retry");
      $loader?.classList.add("none");
      return;
    }
  }
  Swal.fire({
    icon: "error",
    title: "Error",
  });
  $loader?.classList.add("none");
};

const _retryVidaLaboral = async (
  order: any,
  url: string,
  token: any
): Promise<boolean> => {
  const body = order.data;
  const retryVidaLaboral: boolean = await axios.post(`${url}/bot/start`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return retryVidaLaboral;
};

const _retryTarjetaSanitaria = async (
  order: any,
  url: string,
  token: any
): Promise<boolean> => {
  const body = {
    email: order.data.titular.email,
    orderID: order.data.orderID,
  };
  const retryTarjetaSanitaria: boolean = await axios.post(
    `${url}/client/sendurl`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return retryTarjetaSanitaria;
};

export const dateProtection = (e: any) => {
  const { project, id } = e.target.dataset;
  let url = "";
  if (project === "tramitartarjetasanitariaeuropea-com") {
    url = `${process.env.REACT_APP_TARJETA_SANITARIA_URL}/order/${id}`;
  } else {
    url = `${process.env.REACT_APP_API_VIDA_LABORAL_URL}/order/${id}`;
  }
  const token = localStorage.getItem("token");
  const body = {
    titular: {
      nombre: "*",
      primerApellido: "*",
      segundoApellido: "*",
      date: "*",
      movil: "*",
      numeroSeguridadSocial: {
        parte1NAf: "*",
        parte2NAf: "*",
      },
      email: "*",
      identificacion: {
        tipo: "1",
        numero: "*",
      },
      domicilio: {
        tipoVia: "*",
        nombreVia: "*",
        numero: "*",
        bis: "*",
        bloque: "*",
        escalera: "*",
        piso: "*",
        puerta: "*",
        cp: "*",
        provincia: "*",
        pais: "*",
        direccion: "*",
      },
    },
  };
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, change it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const order = await axios.patch(`${url}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (order.status === 200 || order.status === 201) {
        Swal.fire("Update!", "changed status.", "success");
        createLogEntry(id, <string>token, "protection");
      } else {
        Swal.fire("Error", "error");
      }
    }
  });
};

export const update = async (
  body: any,
  id: string,
  project: string,
  log: any
) => {
  console.log("1:" + log);
  const url =
    project === "tramitartarjetasanitariaeuropea-com"
      ? `${process.env.REACT_APP_TARJETA_SANITARIA_URL}/order/${id}`
      : `${process.env.REACT_APP_API_VIDA_LABORAL_URL}/order/${id}`;
  const token = localStorage.getItem("token");
  const order = await axios.patch(`${url}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (order.status === 200 || order.status === 201) {
    createLogEntry(
      id,
      <string>token,
      "update",
      "update|" + log.map((el: any) => `${el[0]}//${el[1]};${el[2]}`).join("|")
    );
    return true;
  } else {
    return false;
  }
  //   }
  // });
};

const _updateVidaLaboral = (e: any) => {
  const _data: any = _getData(e);
  return {
    titular: {
      nombre: _data.name.value ? _data.name.value : _data.name.placeholder,
      primerApellido: _data.surname.value
        ? _data.surname.value
        : _data.surname.placeholder,
      segundoApellido: _data.secondSurname.value
        ? _data.secondSurname.value
        : _data.secondSurname.placeholder,
      movil:
        _data.mobile && _data.mobile.value !== ""
          ? _data.mobile.value
          : _data.mobile.placeholder,
      date:
        _data.date && _data.date.value !== ""
          ? _data.date.value
          : _data.date.placeholder,
      numeroSeguridadSocial: {
        parte1NAf: _data.numeroSeguridadSocial1.value
          ? _data.numeroSeguridadSocial1.value
          : _data.numeroSeguridadSocial1.placeholder,
        parte2NAf: _data.numeroSeguridadSocial2.value
          ? _data.numeroSeguridadSocial2.value
          : _data.numeroSeguridadSocial2.placeholder,
      },
      email: _data.email.value ? _data.email.value : _data.email.placeholder,
      identificacion: {
        tipo: _data.dniType.value
          ? _data.dniType.value
          : _data.dniType.placeholder,
        numero: _data.dni.value ? _data.dni.value : _data.dni.placeholder,
      },
      domicilio: {
        tipoVia: _data.tipoVia.value
          ? _data.tipoVia.value
          : _data.tipoVia.placeholder,
        nombreVia: _data.nombreVia.value
          ? _data.nombreVia.value
          : _data.nombreVia.placeholder,
        numero: _data.numero.value
          ? _data.numero.value
          : _data.numero.placeholder,
        bis: _data.bis.value ? _data.bis.value : _data.bis.placeholder,
        bloque: _data.bloque.value
          ? _data.bloque.value
          : _data.bloque.placeholder,
        escalera: _data.escalera.value
          ? _data.escalera.value
          : _data.escalera.placeholder,
        piso: _data.piso.value ? _data.piso.value : _data.piso.placeholder,
        puerta: _data.puerta.value
          ? _data.puerta.value
          : _data.puerta.placeholder,
        cp: _data.cp.value ? _data.cp.value : _data.cp.placeholder,
        provincia: _data.provincia.value,
        pais: _data.pais.value ? _data.pais.value : _data.pais.placeholder,
      },
    },
    sms:
      _data.sms && _data.sms.value !== ""
        ? _data.sms.value
        : _data.sms.placeholder,
    numeroReferenciaSS: _data.numeroReferenciaSS.value
      ? _data.numeroReferenciaSS.value
      : _data.numeroReferenciaSS.placeholder,
    status: _data.status.value ? _data.status.value : _data.status.placeholder,
    comment: _data.comment.value
      ? _data.comment.value
      : _data.comment.placeholder,
    error: _data.error.value ? _data.error.value : _data.error.placeholder,
    tipoSolicitud: _data.tipoSolicitud.value
      ? _data.tipoSolicitud.value
      : _data.tipoSolicitud.placeholder,
  };
};

const _updateTarjetaSanitaria = (e: any) => {
  const _data: any = _getData(e);
  return {
    titular: {
      nombre: _data.name.value ? _data.name.value : _data.name.placeholder,
      primerApellido: _data.surname.value
        ? _data.surname.value
        : _data.surname.placeholder,
      segundoApellido: _data.secondSurname.value
        ? _data.secondSurname.value
        : _data.secondSurname.placeholder,
      movil:
        _data.mobile && _data.mobile.value !== ""
          ? _data.mobile.value
          : _data.mobile.placeholder,
      date:
        _data.date && _data.date.value !== ""
          ? _data.date.value
          : _data.date.placeholder,
      numeroSeguridadSocial: {
        parte1NAf: _data.numeroSeguridadSocial1.value
          ? _data.numeroSeguridadSocial1.value
          : _data.numeroSeguridadSocial1.placeholder,
        parte2NAf: _data.numeroSeguridadSocial2.value
          ? _data.numeroSeguridadSocial2.value
          : _data.numeroSeguridadSocial2.placeholder,
      },
      email: _data.email.value ? _data.email.value : _data.email.placeholder,
      identificacion: {
        tipo: _data.dniType.value
          ? _data.dniType.value
          : _data.dniType.placeholder,
        numero: _data.dni.value ? _data.dni.value : _data.dni.placeholder,
      },
      domicilio: {
        tipoVia: _data.tipoVia.value
          ? _data.tipoVia.value
          : _data.tipoVia.placeholder,
        nombreVia: _data.nombreVia.value
          ? _data.nombreVia.value
          : _data.nombreVia.placeholder,
        numero: _data.numero.value
          ? _data.numero.value
          : _data.numero.placeholder,
        bis: _data.bis.value ? _data.bis.value : _data.bis.placeholder,
        bloque: _data.bloque.value
          ? _data.bloque.value
          : _data.bloque.placeholder,
        escalera: _data.escalera.value
          ? _data.escalera.value
          : _data.escalera.placeholder,
        piso: _data.piso.value ? _data.piso.value : _data.piso.placeholder,
        puerta: _data.puerta.value
          ? _data.puerta.value
          : _data.puerta.placeholder,
        cp: _data.cp.value ? _data.cp.value : _data.cp.placeholder,
        provincia: _data.provincia.value,
        localidad: _data.localidad.value,
        pais: _data.pais.value ? _data.pais.value : _data.pais.placeholder,
      },
    },
    sms: _data.sms.value ? _data.sms.value : _data.sms.placeholder,
    numeroReferenciaSS: _data.numeroReferenciaSS.value
      ? _data.numeroReferenciaSS.value
      : _data.numeroReferenciaSS.placeholder,
    status: _data.status.value ? _data.status.value : _data.status.placeholder,
    comment: _data.comment.value
      ? _data.comment.value
      : _data.comment.placeholder,
    error: _data.error.value ? _data.error.value : _data.error.placeholder,
    tipoSolicitud: _data.tipoSolicitud.value
      ? _data.tipoSolicitud.value
      : _data.tipoSolicitud.placeholder,
  };
};

const _getData = (e: any) => {
  let id = e.target.dataset.id;
  return {
    name: document.querySelector(`[data-id='${id}'] #name`),
    surname: document.querySelector(`[data-id='${id}'] #surname`),
    secondSurname: document.querySelector(`[data-id='${id}'] #secondSurname`),
    dni: document.querySelector(`[data-id='${id}'] #dni`),
    dniType: document.querySelector(`[data-id='${id}'] #dniType`),
    numeroSeguridadSocial1: document.querySelector(
      `[data-id='${id}'] #numeroSeguridadSocial1`
    ),
    numeroSeguridadSocial2: document.querySelector(
      `[data-id='${id}'] #numeroSeguridadSocial2`
    ),
    date: document.querySelector(`[data-id='${id}'] #date`),
    mobile: document.querySelector(`[data-id='${id}'] #mobile`),
    email: document.querySelector(`[data-id='${id}'] #email`),
    tipoVia: document.querySelector(`[data-id='${id}'] #tipoVia`),
    numero: document.querySelector(`[data-id='${id}'] #numero`),
    bis: document.querySelector(`[data-id='${id}'] #bis`),
    bloque: document.querySelector(`[data-id='${id}'] #bloque`),
    escalera: document.querySelector(`[data-id='${id}'] #escalera`),
    piso: document.querySelector(`[data-id='${id}'] #piso`),
    puerta: document.querySelector(`[data-id='${id}'] #puerta`),
    cp: document.querySelector(`[data-id='${id}'] #cp`),
    nombreVia: document.querySelector(`[data-id='${id}'] #nombreVia`),
    provincia: document.querySelector(`[data-id='${id}'] #provincia`),
    localidad: document.querySelector(`[data-id='${id}'] #localidad`),
    pais: document.querySelector(`[data-id='${id}'] #pais`),
    sms: document.querySelector(`[data-id='${id}'] #sms`),
    numeroReferenciaSS: document.querySelector(
      `[data-id='${id}'] #numeroReferenciaSS`
    ),
    status: document.querySelector(`[data-id='${id}'] #status`),
    comment: document.querySelector(`[data-id='${id}'] #comment`),
    error: document.querySelector(`[data-id='${id}'] #error`),
    ben1nombre: document.querySelector(`[data-id='${id}'] #ben1nombre`),
    ben1apellido1: document.querySelector(`[data-id='${id}'] #ben1apellido1`),
    ben1apellido2: document.querySelector(`[data-id='${id}'] #ben1apellido2`),
    ben1fechanacimiento: document.querySelector(
      `[data-id='${id}'] #ben1fechanacimiento`
    ),
    ben1dni: document.querySelector(`[data-id='${id}'] #ben1dni`),
    ben1typedni: document.querySelector(`[data-id='${id}'] #ben1typedni`),
    ben2nombre: document.querySelector(`[data-id='${id}'] #ben2nombre`),
    ben2apellido1: document.querySelector(`[data-id='${id}'] #ben2apellido1`),
    ben2apellido2: document.querySelector(`[data-id='${id}'] #ben2apellido2`),
    ben2fechanacimiento: document.querySelector(
      `[data-id='${id}'] #ben2fechanacimiento`
    ),
    ben2dni: document.querySelector(`[data-id='${id}'] #ben2dni`),
    ben2typedni: document.querySelector(`[data-id='${id}'] #ben2typedni`),
    ben3nombre: document.querySelector(`[data-id='${id}'] #ben3nombre`),
    ben3apellido1: document.querySelector(`[data-id='${id}'] #ben3apellido1`),
    ben3apellido2: document.querySelector(`[data-id='${id}'] #ben3apellido2`),
    ben3fechanacimiento: document.querySelector(
      `[data-id='${id}'] #ben3fechanacimiento`
    ),
    ben3dni: document.querySelector(`[data-id='${id}'] #ben3dni`),
    ben3typedni: document.querySelector(`[data-id='${id}'] #ben3typedni`),
    ben4nombre: document.querySelector(`[data-id='${id}'] #ben4nombre`),
    ben4apellido1: document.querySelector(`[data-id='${id}'] #ben4apellido1`),
    ben4apellido2: document.querySelector(`[data-id='${id}'] #ben4apellido2`),
    ben4fechanacimiento: document.querySelector(
      `[data-id='${id}'] #ben4fechanacimiento`
    ),
    ben4dni: document.querySelector(`[data-id='${id}'] #ben4dni`),
    ben4typedni: document.querySelector(`[data-id='${id}'] #ben4typedni`),
    ben5nombre: document.querySelector(`[data-id='${id}'] #ben5nombre`),
    ben5apellido1: document.querySelector(`[data-id='${id}'] #ben5apellido1`),
    ben5apellido2: document.querySelector(`[data-id='${id}'] #ben5apellido2`),
    ben5fechanacimiento: document.querySelector(
      `[data-id='${id}'] #ben5fechanacimiento`
    ),
    ben5dni: document.querySelector(`[data-id='${id}'] #ben5dni`),
    ben5typedni: document.querySelector(`[data-id='${id}'] #ben5typedni`),
    numeroBeneficiarios: document.querySelector(
      `[data-id='${id}'] #numeroBeneficiarios`
    ),
    tipoSolicitud: document.querySelector(`[data-id='${id}'] #tipoSolicitud`),
  };
};

export const provincias = () => {
  let provincias = {
    "03": "ALACANT/ALICANTE",
    "02": "ALBACETE",
    "04": "ALMERÍA",
    "01": "ARABA/ÁLAVA",
    "33": "ASTURIAS",
    "06": "BADAJOZ",
    "08": "BARCELONA",
    "48": "BIZKAIA",
    "09": "BURGOS",
    "39": "CANTABRIA",
    "12": "CASTELLÓ/CASTELLÓN",
    "51": "CEUTA",
    "13": "CIUDAD REAL",
    "15": "CORUÑA, A",
    "16": "CUENCA",
    "10": "CÁCERES",
    "11": "CÁDIZ",
    "14": "CÓRDOBA",
    "20": "GIPUZKOA",
    "17": "GIRONA",
    "18": "GRANADA",
    "19": "GUADALAJARA",
    "21": "HUELVA",
    "22": "HUESCA",
    "07": "ILLES BALEARS",
    "23": "JAÉN",
    "24": "LEÓN",
    "25": "LLEIDA",
    "27": "LUGO",
    "28": "MADRID",
    "52": "MELILLA",
    "30": "MURCIA",
    "29": "MÁLAGA",
    "31": "NAVARRA",
    "32": "OURENSE",
    "34": "PALENCIA",
    "35": "PALMAS, LAS",
    "36": "PONTEVEDR",
    "26": "RIOJA, LA",
    "37": "SALAMANCA",
    "38": "SANTA CRUZ DE TENERIFE",
    "40": "SEGOVIA",
    "41": "SEVILLA",
    "42": "SORIA",
    "43": "TARRAGONA",
    "44": "TERUEL",
    "45": "TOLEDO",
    "47": "VALLADOLID",
    "46": "VALÈNCIA/VALENCIA",
    "49": "ZAMORA",
    "50": "ZARAGOZA",
    "05": "ÁVILA",
  };
  return Object.entries(provincias);
};

export const editable = () => {
  let $editable = document.querySelectorAll(".editable");
  for (let i = 0; i < $editable.length; i++) {
    $editable[i].classList.toggle("none");
  }
};

export const downloadMail = async (e: any) => {
  const email = e.target.value;
  const { project } = e.target.dataset;
  const { id } = e.target;
  const $loader = <HTMLElement>document.querySelector(`[data-loader='${id}']`);
  $loader?.classList.remove("none");
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_TRAMITES_BACKEND_URL}/api/features/emails/?email=${email}&project=${project}`;
  const messageEmail: any = await axios.get(`${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  let message = messageEmail.data;
  if (message.length < 200) {
    Swal.fire({
      icon: "error",
      title: "No email available",
    });
    $loader?.classList.add("none");
    return;
  }
  _printPDF(message, email, $loader);
  createLogEntry(id, <string>token, "download mail");
};

export const downloadScreenshot = async (e: any) => {
  const orderID = e.target.id;
  const id = e.target.value;
  const $loader = <HTMLElement>document.querySelector(`[data-loader='${id}']`);
  $loader?.classList.remove("none");
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_TRAMITES_BACKEND_URL}/api/tarjeta-sanitaria/logs-images/${orderID}`;
  const response: any = await axios.get(`${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  let images = response.data;
  for (let i = 0; i < images.length; i++) {
    await wait(1000);
    let link = document.createElement("a");
    link.download = `file${i}_${orderID}`;
    link.href = "data:image/png;base64," + images[i];
    link.click();
  }
  $loader?.classList.add("none");
  if (images.length === 0) {
    Swal.fire({
      icon: "error",
      title: "No screenshot available",
    });
  }
};

export const createSubordinateOrder = async (data: any) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_TRAMITES_BACKEND_URL}/api/tarjeta-sanitaria/orders`;
  const response: any = await axios.post(`${url}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

export const resendBuyEmail = async (e: any) => {
  const id = e.target.value;
  const $loader = <HTMLElement>document.querySelector(`[data-loader='${id}']`);
  $loader?.classList.remove("none");
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_TRAMITES_BACKEND_URL}/api/tarjeta-sanitaria/order/resendBuyConf`;
  const body = {
    id: id,
  };
  const sendEmail: boolean = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  $loader?.classList.add("none");
  if (!sendEmail) {
    Swal.fire({
      icon: "error",
      title: "Email not sent",
    });
  }
};

const wait = async function (ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const _printPDF = async (
  message: string,
  email: string,
  $loader: HTMLElement
) => {
  const $emailPDF = <HTMLElement>document.querySelector("#page");
  $emailPDF.innerHTML = message;
  await html2pdf($emailPDF, {
    jsPDF: {
      format: "a4",
      y: 0,
    },
    imageType: "image/jpeg",
    output: `${email}.pdf`,
  });
  $loader?.classList.add("none");
  $emailPDF.innerHTML = "";
};
