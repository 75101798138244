import styles from "./Customers.module.css";
import "./colosseum.scss";

import { CButton, CCol, CContainer, CRow } from "@coreui/react";
import { IsLogin } from "../../../features/login/IsLogin";
import { Fragment, useEffect, useState } from "react";
import {
  requestData,
  paymentIncompletedToCompleted,
  downloadBill,
} from "./_customers_Colosseum";
import { Colosseum_CustomersFilters } from "./Filters";
import { Colosseum_CustomersDataTable } from "./DataTable/DataTable";
import { ConfirmEdit } from "./ConfirmEdit";
import { FileModal } from "./ConfirmFile";
import { reportToXlsx } from "../../../features/report-xlsx/report-xlsx";
import { ManualPurchaseModal } from "./ManualPurchase";
// import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
// import { useOrders } from "./hooks/useOrders";
import { ColosseumOrder } from "./types";
import { ColosseumRefundModal } from "./RefundModal";

const defaultForm = {
  email: "",
  status: "",
  firstName: "",
  lastName: "",
  visitTime: "",
  timeRange: "",
  ticketsDate: "",
  trackingNumber: "",
  purchaseMail: "",
};
const today = new Date();

export const CustomersColosseum = () => {
  IsLogin();
  const status = [
    "completed",
    "await bot",
    "await ok",
    "without card",
    "await mail",
    "avaibility mail",
    "revised",
    "on hold",
    "refund",
    "to review",
    "payment incompleted",
  ];
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFloating, setShowFloating] = useState(false);
  const [fileName, setFileName] = useState<{
    order?: string;
    name?: string;
    file?: any;
  }>({});
  const [purchaseId, setPurchaseId] = useState("");
  const [filters, setFilters] = useState({
    minCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    maxCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    status: status,
    indCriteria: "email",
    indValue: "",
  });
  const [form, setForm] = useState(defaultForm);
  const [showModal, setShowModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundOrder, setRefundOrder] = useState<{ [x: string]: any }>({});
  const [changes, setChanges] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [individualSearch, setIndividualSearch] = useState(false);
  const [orderBy, setOrderBy] = useState("purchaseDate");
  const [errorOrders, setErrorOrders] = useState<ColosseumOrder[]>([]);

  // const { orders, filter, setFilter, editStatus } = useOrders();

  useEffect(() => {
    if (showFloating) {
      setTimeout(() => {
        setShowFloating(false);
      }, 2000);
    }
  }, [showFloating]);

  useEffect(() => {
    if (fileName.file && fileName.file !== "") setShowFileModal(true);
  }, [fileName]);

  useEffect(() => {
    setData([]);
    dataSagrada().then((e) => setData(e));
  }, []);

  useEffect(() => {
    const errOrders = data
      .filter((order) => ["on hold", "to review"].includes(order.status))
      .sort((a, b) => {
        const arrDateA = a.ticketsDate.split("/");
        const arrDateB = b.ticketsDate.split("/");
        if (arrDateA.length === 3 && arrDateB.length === 3) {
          if (
            new Date(arrDateA[2], arrDateA[1], arrDateA[0]).getTime() <
            new Date(arrDateB[2], arrDateB[1], arrDateB[0]).getTime()
          )
            return -1;
          if (
            new Date(arrDateA[2], arrDateA[1], arrDateA[0]).getTime() >
            new Date(arrDateB[2], arrDateB[1], arrDateB[0]).getTime()
          )
            return 1;
        }
        if (
          Number(a.timeRange.split(":")[0]) < Number(b.timeRange.split(":")[0])
        )
          return -1;
        if (
          Number(a.timeRange.split(":")[0]) > Number(b.timeRange.split(":")[0])
        )
          return 1;
        return 0;
      });
    setErrorOrders(errOrders);
  }, [data]);

  const dataSagrada = async () => {
    return await requestData(filters);
  };

  const searchHandler = async () => {
    const newData = await requestData(filters);
    setIndividualSearch(false);
    setData(newData);
    setEdit(!edit);
  };

  const searchOneHandler = async () => {
    const newData = await requestData(filters, true);
    setIndividualSearch(true);
    setData(newData);
    setEdit(!edit);
  };

  const refundHandler = (order: ColosseumOrder) => {
    setRefundOrder(order);
    setShowRefundModal(true);
  };

  const exportCSVHandler = () => {
    reportToXlsx(
      data.map((order: any) => {
        const tickets = order.tickets.find((el: any) => el.type === "entrada");

        return {
          Status: order.status,
          "First Name": order.client.firstName,
          "Last Name": order.client.lastName,
          Email: order.client.email,
          "Phone Number": order.client.phoneNumber,
          Address: order.client.address,
          Postcode: order.client.postcode,
          Country: order.client.country,
          "ID Number": order.client.idNumber,
          // "Transactional ID": order.qontoIds
          //   .map((el: any) => String(el))
          //   .join(", "),
          // "Card Number": order.cardnumber
          //   ? `**** **** **** ${order.cardnumber.slice(-4)}`
          //   : "-",
          // "Qonto Amount": order.qontoAmount
          //   .map((el: any) => String(el))
          //   .join(", "),
          // "Order ID": order.wc_id,
          Amount: order.totalPrice,
          Tickets: tickets ? tickets.amount : 0,
          "Tickets Date": order.ticketsDate,
          // "T. under 30": under30 ? under30.amount : 0,
          // "T. children": children ? children.amount : 0,
          "Bought At": new Date(order.createdAt).toLocaleString("en-GB"),
          "Time Range": order.timeRange,
          // "Visit Time": order.visitTime,
          Logs: order.logs.map((el: any) => JSON.stringify(el)).toString(),
        };
      })
    );
  };

  const downloadBillsHandler = async () => {
    setLoading(true);
    const arrIds = data
      .filter((order: any) => order.hasBillSaved)
      .map((order: any) => order._id);
    arrIds.forEach(async (_id: string) => {
      await downloadBill([_id]);
    });
    setLoading(false);
  };

  const onSaveAttempt = (changes: any) => {
    setChanges(changes);
    setShowModal(true);
  };

  const onManualPurchase = (id: string) => {
    setPurchaseId(id);
    setShowPurchaseModal(true);
  };

  const orderByHandler = (e: any) => {
    setOrderBy(orderBy === "purchaseDate" ? "visitDate" : "purchaseDate");
  };

  return localStorage.getItem("colosseumToken") ? (
    <Fragment>
      <Colosseum_CustomersFilters
        filters={filters}
        setFilters={setFilters}
        searchHandler={searchHandler}
        searchOneHandler={searchOneHandler}
      />
      <CContainer className={"d-flex mt-4"}>
        {/* <CButton
          onClick={paymentIncompletedToCompleted}
          size="sm"
          color="success"
          className="ml-1"
        >
          Payment incompleted to Completed
        </CButton> */}
        <CButton
          onClick={exportCSVHandler}
          size="sm"
          color="info"
          className="ml-1"
        >
          Export to CSV
        </CButton>
        {/* <CButton
          onClick={downloadBillsHandler}
          size="sm"
          color="info"
          className={`ml-1 ${loading ? "loading" : ""}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <span className={`${loading ? "mr-2" : ""}`}>Download Bills</span>
        </CButton> */}
        {/* <CButton
          onClick={orderByHandler}
          size="sm"
          color="dark"
          className={`ml-1 ${loading ? "loading" : ""}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <span className={`${loading ? "mr-2" : ""}`}>
            {orderBy === "purchaseDate" ? "Errors View" : "Default View"}
          </span>
        </CButton> */}
      </CContainer>
      <CContainer fluid>
        <CRow className="mt-2 ml-0 mr-0">
          <CCol>
            <Colosseum_CustomersDataTable
              orderBy={orderBy}
              data={orderBy === "purchaseDate" ? data : errorOrders}
              edit={edit}
              setEdit={setEdit}
              setShowFloating={setShowFloating}
              setShowRefundModal={refundHandler}
              form={form}
              setForm={setForm}
              styles={styles}
              onSaveAttempt={onSaveAttempt}
              onManualPurchase={onManualPurchase}
              setFileName={setFileName}
            />
          </CCol>
        </CRow>
      </CContainer>
      <div
        className="bg-success"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: "5px 15px",
          display: showFloating ? "block" : "none",
        }}
      >
        Copied to clipboard.
      </div>
      <ConfirmEdit
        show={showModal}
        setShow={setShowModal}
        operationType="Edit"
        changes={changes}
        edit={edit}
        setEdit={setEdit}
        individualSearch={individualSearch}
        searchHandler={searchHandler}
        searchOneHandler={searchOneHandler}
      />
      <FileModal
        showFileModal={showFileModal}
        setShowFileModal={setShowFileModal}
        fileName={fileName}
        setFileName={setFileName}
      />
      <ColosseumRefundModal
        show={showRefundModal}
        setShow={setShowRefundModal}
        order={refundOrder}
        setOrder={setRefundOrder}
      ></ColosseumRefundModal>
      <ManualPurchaseModal
        showPurchaseModal={showPurchaseModal}
        setShowPurchaseModal={setShowPurchaseModal}
        purchaseId={purchaseId}
        setPurchaseId={setPurchaseId}
      ></ManualPurchaseModal>
    </Fragment>
  ) : (
    <Fragment>Unauthorired </Fragment>
  );
};
