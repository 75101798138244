import { CSpinner } from '@coreui/react';

export const Spinner = ({visible}: any) => {

  const style = visible ? 'block' : 'none';

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className={style}>
        <CSpinner color="info" />
      </div>
    </div>
  )
}