import { CButton, CModalFooter } from "@coreui/react";
import { FC, useEffect, useState } from "react";
import Modal from "../../../features/modal/Modal";
import { makeRefund } from "./_customers_Colosseum";

type Props = {
  show: boolean;
  setShow: Function;
  order: { [x: string]: any };
  setOrder: Function;
  // id: string
};

export const ColosseumRefundModal: FC<Props> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [reason, setReason] = useState("Tickets not received");
  const [log, setLog] = useState("");
  const handleCancel = () => {
    props.setShow(false);
  };

  useEffect(() => {
    if (props.show) {
      setLog("");
      setReason("Tickets not received");
      setAmount(undefined);
      console.log(amount);

      setProcessing(false);
    }
  }, [props.show]);

  const handleConfirm = async () => {
    const isRefundDone = await makeRefund(props.order._id, {
      amount: amount,
      refundType: amount === props.order.totalPrice ? "Total" : "Fee",
      reason: reason,
    });
    isRefundDone
      ? setLog("Refund done successfully")
      : setLog("Error in order processing");
    setTimeout(() => {
      setLog("");
      setProcessing(false);
      props.setShow(false);
    }, 1500);
  };

  return (
    <Modal show={props.show} toggleTrigger={props.setShow} title={"Refund"}>
      <div>
        <div className="sagrada-refund-form">
          <div>
            <h5>Reason: </h5>
          </div>
          <div>
            <select
              value={reason}
              onChange={({ target }) => setReason(target.value)}
              style={{ padding: "4px 7px" }}
            >
              <option value={"Missing order in API"}>
                Missing order in API
              </option>
              <option value={"Tickets not received"}>
                Tickets not received
              </option>
              <option value={"Customer request"}>Customer request</option>
            </select>
          </div>
        </div>
      </div>
      <div className="sagrada-refund-amount">
        <div>
          <h5>Amount: </h5>
        </div>
        {props.order.tickets && (
          <div className="sagrada-refund-amount">
            <div>
              <input
                type="radio"
                name="amount"
                value={amount}
                checked={amount === Number(props.order.totalPrice)}
                onClick={() => {
                  setAmount(Number(props.order.totalPrice));
                }}
              />{" "}
              Total ( {props.order.totalPrice} € )
            </div>
            <div>
              <input
                type="radio"
                name="amount"
                value={amount}
                checked={
                  amount ===
                  props.order.tickets.reduce((prev: number, next: any) => {
                    if (next.type === "adult")
                      return prev + Number(next.amount) * 10;
                    if (next.type === "under 30 years old")
                      return prev + Number(next.amount) * 12;
                    return prev;
                  }, 0)
                }
                onClick={() => {
                  setAmount(
                    props.order.tickets &&
                      props.order.tickets.reduce((prev: number, next: any) => {
                        if (next.type === "adult")
                          return prev + Number(next.amount) * 10;
                        if (next.type === "under 30 years old")
                          return prev + Number(next.amount) * 12;
                        return prev;
                      }, 0)
                  );
                }}
              />{" "}
              Only fees ({" "}
              {props.order.tickets &&
                props.order.tickets.reduce((prev: number, next: any) => {
                  if (next.type === "adult")
                    return prev + Number(next.amount) * 10;
                  if (next.type === "under 30 years old")
                    return prev + Number(next.amount) * 12;
                  return prev;
                }, 0)}{" "}
              € )
            </div>
          </div>
        )}
      </div>
      {log && <div>{log}</div>}
      <CModalFooter className="border-top-0">
        <CButton color="secondary" onClick={handleCancel} disabled={processing}>
          Cancel
        </CButton>
        <CButton
          className="text-light border-dark"
          disabled={processing || amount === undefined || !reason}
          onClick={handleConfirm}
          form="refundForm"
          style={{ backgroundColor: "rgba(0,0,21,0.2)" }}
        >
          Confirm
        </CButton>
      </CModalFooter>
    </Modal>
  );
};
