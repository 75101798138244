import { CButton, CModalFooter } from "@coreui/react";
import { FC, useEffect, useState } from "react";
import Modal from "../../../features/modal/Modal";
import { newManualPurchase } from "./_customers_Colosseum";

type Props = {
  showPurchaseModal: boolean;
  setShowPurchaseModal: Function;
  purchaseId: string;
  setPurchaseId: Function;
};

export const ManualPurchaseModal: FC<Props> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [log, setLog] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [email, setEmail] = useState("");
  const handleCancel = () => {
    props.setShowPurchaseModal(false);
  };

  useEffect(() => {
    if (props.showPurchaseModal) {
      setTrackingNumber("");
      setCardNumber("");
      setLog("");
      setEmail("");
      setProcessing(false);
    }
  }, [props.showPurchaseModal]);

  const handleConfirm = async () => {
    const isPurchasedDone = await newManualPurchase({
      id: props.purchaseId,
      trackingNumber,
      cardNumber,
      email,
    });

    isPurchasedDone
      ? setLog("Manual purchase registered successfully")
      : setLog("Error with API flow");
    setTimeout(() => {
      setLog("");
      setTrackingNumber("");
      setEmail("");
      setCardNumber("");
      setProcessing(false);
      props.setShowPurchaseModal(false);
    }, 1500);
  };

  return (
    <Modal
      show={props.showPurchaseModal}
      toggleTrigger={props.setShowPurchaseModal}
      title={"Manual Purchase"}
    >
      <div>
        <div className="sagrada-input-form">
          <label htmlFor="trackingPurchase">New Tracking Number: </label>
          <input
            type="text"
            name=""
            value={trackingNumber}
            onChange={({ target }) => setTrackingNumber(target.value)}
            id="trackingPurchase"
          />
        </div>
        <div className="sagrada-input-form">
          <label htmlFor="cardPurchase">New Card Number: </label>
          <input
            type="number"
            name=""
            value={cardNumber}
            onChange={({ target }) => setCardNumber(target.value)}
            id="cardPurchase"
          />
        </div>
        <div className="sagrada-input-form">
          <label htmlFor="mailPurchase">New Purchase Mail: </label>
          <input
            type="text"
            name=""
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            id="mailPurchase"
          />
        </div>
      </div>
      {log && <div>{log}</div>}
      <CModalFooter className="border-top-0">
        <CButton color="secondary" onClick={handleCancel} disabled={processing}>
          Cancel
        </CButton>
        <CButton
          className="text-light border-dark"
          disabled={processing}
          onClick={handleConfirm}
          form="refundForm"
          style={{ backgroundColor: "rgba(0,0,21,0.2)" }}
        >
          Confirm
        </CButton>
      </CModalFooter>
    </Modal>
  );
};
