import { SagradaOrder } from "./types";
import axios from "axios";
import { subSeconds, addDays } from "date-fns";
import Swal from "sweetalert2";
import * as fs from "fs";
import fileDownload from "js-file-download";
import { to } from "await-to-js";

export const setCorrectMaxDate = (maxDate: Date) =>
  subSeconds(addDays(new Date(maxDate), 1), 1).toISOString();

export const requestData = async (
  filters: any,
  one: boolean = false
): Promise<SagradaOrder[]> => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  // const token = await authIQ();
  if (!token) return [];

  try {
    const { data } = one
      ? await axios.get(
          `${url}/api/orders?${filters.indCriteria}=${filters.indValue}`,
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
      : await axios.get(
          `${url}/api/orders?minDate=${filters.minCreatedAt.toISOString()}&maxDate=${setCorrectMaxDate(
            filters.maxCreatedAt
          )}&status=${filters.status.toString()}`,
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
    console.log(data);

    return data.map((el: any, i: number) => {
      const panelOrder = {
        ...el,
        client: el.client || {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          country: "",
          postcode: "",
        },
        fullName: el.client
          ? `${el.client.firstName} ${el.client.lastName}`
          : "",
        email: el.client.email,
        createdAt: new Date(el.createdAt).toLocaleString(),
      };

      return panelOrder;
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const paymentIncompletedToCompleted = async () => {
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, update it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const url = `${process.env.REACT_APP_API_SAGRADA}/api/orders/payment-incompleted-to-completed`;
      const token = localStorage.getItem("sagradaToken");
      const isOrdesToCompleted = await axios.post(url, "", {
        headers: { Authorization: "Bearer " + token },
      });
      if (isOrdesToCompleted) {
        Swal.fire("Update!", "", "success");
      } else {
        Swal.fire("Error", "error");
      }
    }
  });
};

export const swalResetTickets = (_id: string) => {
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, update it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const url = process.env.REACT_APP_API_SAGRADA;
      const token = localStorage.getItem("sagradaToken");
      if (!token) {
        Swal.fire("Error", "error");
        return;
      }
      const { data }: { data: boolean } = await axios.get(
        `${url}/api/orders/sendTickets/${_id}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      if (data) {
        Swal.fire("Update!", "", "success");
      } else {
        Swal.fire("Error2", "error");
      }
    }
  });
};

export const resendTickets = async (_id: string) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  // const token = await authIQ();
  if (!token) return "false";

  try {
    const { data }: { data: boolean } = await axios.get(
      `${url}/api/orders/sendTickets/${_id}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return `${data}`;
  } catch (error) {
    console.log(error);
    return "false";
  }
};

export const updateOrder = async (_id: any, order: any) => {
  const arrTitularProperties = ["email", "firstName", "lastName"];
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  const keys = Object.keys(order);
  const preBody: [string, any][] = keys.map((key) => {
    return arrTitularProperties.includes(key)
      ? [`client.${key}`, order[key]]
      : [key, order[key]];
  });
  const body = Object.fromEntries(new Map(preBody));
  // const token = await authIQ();
  if (!token) return null;
  try {
    await axios.patch(`${url}/api/orders/${_id}`, body, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const retrySendEmail = async (_id: any) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  if (!token) return false;

  try {
    await axios.get(`${url}/api/orders/${_id}/retry`, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const authIQ = async (pwd: string) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const localUser = localStorage.getItem("username");
  try {
    const { data } = await axios.post(`${url}/auth/login`, {
      email: `${localUser}`,
      password: pwd,
    });
    return data.access_token;
  } catch (error) {
    return null;
  }
};

export const downloadBill = async (id: string[]) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  if (!token) return false;

  const query = `?id=${id.join("&id=")}`;

  try {
    const response = await axios.get(`${url}/api/orders/bills${query}`, {
      headers: { Authorization: "Bearer " + token },
      responseType: "blob",
    });

    if (!response || !response.data) return false;

    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", id.length === 1 ? `bill.pdf` : `tickets.zip`); //or any other extension
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(`${url}/api/orders/bills${query}`);
    return true;
  } catch (error) {
    return false;
  }
};

export const downloadTicket = async (id: string[], fileName: string) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  if (!token) return false;

  const query = `?id=${id.join("&id=")}`;

  try {
    const response = await axios.get(`${url}/api/orders/tickets${query}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
    });

    if (!response || !response.data) return false;

    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTLM element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      id.length === 1 ? `${fileName}.pdf` : `tickets.zip`
    ); //or any other extension
    document.body.appendChild(link);

    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(`${url}/api/orders/tickets${query}`);

    return true;
  } catch (error) {
    return false;
  }
};

export const uploadFile = async (fileName: {
  order: string;
  name: string;
  file: File;
}) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  if (!token) return false;

  try {
    const form = new FormData();
    form.append("files", fileName.file, fileName.file.name);

    const [err, response] = await to(
      axios.patch(`${url}/api/orders/${fileName.order}/file`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
    );
    return err || (response && !response.data) ? false : true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const addLog = async (_id: string, body: any) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  const agent = localStorage.getItem("username");
  // console.log("Edit order>>" + parsed);

  try {
    const response = await axios.post(
      `${url}/api/orders/logs`,
      {
        _id,
        status: "-",
        accion: "Edit order>>" + body,
        by: agent?.split("@")[0],
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );

    if (!response || !response.data) return false;
  } catch (error) {
    return false;
  }
};

export const newManualPurchase = async (manualPurchaseData: {
  [x: string]: string;
}) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");
  const agent = localStorage.getItem("username");

  try {
    const response = await axios.post(
      `${url}/api/orders/manual`,
      {
        _id: manualPurchaseData.id,
        cardNumber: manualPurchaseData.cardNumber,
        trackingNumber: manualPurchaseData.trackingNumber,
        agent: agent?.split("@")[0],
        email: manualPurchaseData.email,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (!response || !response.data) return false;

    return response.data ? true : false;
  } catch (error) {
    return false;
  }
};

export const makeRefund = async (_id: string, refund: any) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");

  try {
    const response = await axios.patch(
      `${url}/api/orders/${_id}/refund`,
      {
        amount: refund.amount,
        refundType: refund.refundType,
        reason: refund.reason,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    console.log(response.data);

    if (!response || !response.data || response.data === false) return false;

    return response.data ? true : false;
  } catch (error) {
    return false;
  }
};

export const getScreenshotsBot = async (wc_ref: string) => {
  const url = process.env.REACT_APP_API_SAGRADA;
  const token = localStorage.getItem("sagradaToken");

  try {
    const response = await axios.get(
      `${url}/api/orders/screenshots/${wc_ref}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (!response || !response.data || response.data === false) return false;

    response.data.forEach((screenshot: string, i: number) => {
      fileDownload(Buffer.from(screenshot, "base64"), `${i + 1}_${wc_ref}.png`);
    });

    return response.data ? true : false;
  } catch (error) {
    return false;
  }
};
