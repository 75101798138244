import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { clearState, fetchUserBytoken, userSelector } from '../../slices/userSlice';
import { TramitesSelector } from '../../slices/tramitesSlice';
import { UspostalTestSelector } from '../../slices/uspostaltestSlice';

export const IsLogin = () => {
  const history = useHistory();
  
  const dispatch = useDispatch();
  const { hasErrors } = useSelector(TramitesSelector);
  const uspostalTest = useSelector(UspostalTestSelector);
  const uspostalTestError = uspostalTest.hasErrors;
  const { isError } = useSelector(userSelector);
  useEffect(() => {
    dispatch(fetchUserBytoken({ 
      token: localStorage.getItem('token'),
      username: localStorage.getItem('username')
    }));
  }, [hasErrors, uspostalTestError]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      history.push('/login');
    }
  }, [isError]);
}