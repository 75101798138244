import { CButton, CModalBody, CModalFooter } from "@coreui/react";
import React, { useState, ChangeEvent } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import Modal from "../../../features/modal/Modal";
import { changeStatusByRefund } from "./_customersTramites";

type RefundInputs = {
  reason: string;
  otherReason: string;
  amount: string;
};

interface Props {
  show: boolean;
  setShow: Function;
  orderData: {
    value: string;
    project: string;
    id: string;
    order_total: string;
  };
}

const reasonOpts = [
  {
    value: "Time Out",
    label: "Time Out",
  },
  {
    value: "Movil no registrado o distinto",
    label: "Móvil no registrado/distinto",
  },
  {
    value: "Datos erroneos",
    label: "Datos erróneos",
  },
  {
    value: "Tarjeta en vigor",
    label: "Tarjeta en vigor",
  },
  {
    value: "Bug SS",
    label: "Bug SS",
  },
  {
    value: "Cobro duplicado",
    label: "Cobro duplicado",
  },
  {
    value: "DNI o fecha no registrados",
    label: "DNI/Fecha no registrados",
  },
  {
    value: "Error formato",
    label: "Error formato",
  },
  {
    value: "Solicitud Rechazada por SS",
    label: "Solicitud Rechazada por SS",
  },
  {
    value: "SMS incorrecto",
    label: "SMS incorrecto",
  },
  {
    value: "Bug Itcubation",
    label: "Bug Itcubation",
  },
  {
    value: "Confusion web oficial",
    label: "Confusión web oficial",
  },
  {
    value: "Beneficiarios",
    label: "Beneficiarios",
  },
  {
    value: "Prueba",
    label: "Prueba",
  },
  {
    value: "Otro",
    label: "Otro",
  },
];

export const TE_RefundForm: React.FC<Props> = ({
  show,
  setShow,
  orderData,
}) => {
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [calling, setCalling] = useState(false);
  const [log, setLog] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RefundInputs>();
  const onSubmit: SubmitHandler<RefundInputs> = async (refundData) => {
    setCalling(true);
    const isRefundDone = await changeStatusByRefund(refundData, orderData);
    isRefundDone
      ? setLog("Refund realizado con éxito.")
      : setLog("Error en procesamiento de datos.");
    setTimeout(() => {
      toggleAndReset();
    }, 2000);
  };

  const toggleAndReset = () => {
    setShow(!show);
    setReason("");
    setOtherReason("");
    setRefundAmount("");
    setCalling(false);
    setLog("");
  };

  const onChangeReasonHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setReason(value);
    setOtherReason("");
  };

  const onChangeOtherReasonHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setOtherReason(e.target.value);
  };

  const onChangeRefundAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRefundAmount(e.target.value);
  };

  return (
    <Modal
      show={show}
      title="Refund confirmation"
      toggleTrigger={toggleAndReset}
    >
      <CModalBody>
        <form
          className="d-flex flex-column justify-content-between"
          style={{ backgroundColor: "#3c4b64", height: "100%" }}
          action="#"
          id="refundForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="d-flex flex-column align-items-left">
            <div className="w-75 text-left">
              <h4>Razón </h4>
            </div>
            <select
              className="custom-select w-75"
              {...register("reason")}
              disabled={calling}
              value={reason}
              onChange={onChangeReasonHandler}
            >
              <option value="">...</option>
              {reasonOpts.map(({ value, label }, i) => (
                <option key={i} value={value}>
                  {label}
                </option>
              ))}
            </select>
            {reason === "Otro" && (
              <input
                className="form-control w-75 mt-3 mb-4"
                type="text"
                required
                minLength={25}
                {...register("otherReason")}
                placeholder="Especifica..."
                value={otherReason}
                disabled={calling}
                onChange={onChangeOtherReasonHandler}
              />
            )}
          </div>
          <div className="d-flex pt-3 flex-column text-left">
            <div className="w-75 text-left">
              <h4>Cantidad </h4>
            </div>
            <div className="pl-1">
              <input
                checked={refundAmount === "Partial"}
                className="mt-2 mr-2 mb-2"
                type="radio"
                {...register("amount")}
                value="Partial"
                disabled={calling}
                id="partial-refund"
                onChange={onChangeRefundAmount}
              />
              <span>
                Partial ({(+orderData.order_total * 0.8).toFixed(2)} €)
              </span>
            </div>
            <div className="pl-1">
              <input
                checked={refundAmount === "Total"}
                className="mt-2 mr-2 mb-2"
                type="radio"
                disabled={calling}
                {...register("amount")}
                value="Total"
                id="total-refund"
                onChange={onChangeRefundAmount}
              />
              <span>Total ({(+orderData.order_total * 1).toFixed(2)} €)</span>
            </div>
          </div>
        </form>
      </CModalBody>
      <CModalFooter className="border-top-0">
        <CButton color="secondary" onClick={toggleAndReset} disabled={calling}>
          Cancel
        </CButton>
        <CButton
          className="text-light border-dark"
          disabled={
            refundAmount === "" ||
            reason === "" ||
            (reason === "Otro" && otherReason === "") ||
            calling
          }
          type="submit"
          form="refundForm"
          style={{ backgroundColor: "rgba(0,0,21,0.2)" }}
        >
          Confirm
        </CButton>{" "}
        <div id="refundLog" className="d-block w-100 pt-1">
          {log && <span>{log}</span>}
        </div>
      </CModalFooter>
    </Modal>
  );
};
