
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const initialState = {
  loading: false,
  hasErrors: false,
  uspostalTests: [],
}

// A slice for recipes with our 3 reducers
const uspostalTestSlice = createSlice({
  name: 'uspostalTests',
  initialState,
  reducers: {
    getUspostalTest: state => {
      state.loading = true
    },
    getUspostalTestSuccess: (state, { payload }) => {
      state.uspostalTests = payload
      state.loading = false
      state.hasErrors = false
    },
    getUspostalTestFailure: state => {
      state.loading = false
      state.hasErrors = true
      state.uspostalTests = []
    },
  },
})
export const { getUspostalTest, getUspostalTestSuccess, getUspostalTestFailure } = uspostalTestSlice.actions

// A selector
export const UspostalTestSelector = (state: { uspostalTests: any }) => state.uspostalTests

// The reducer
export default uspostalTestSlice.reducer

// Asynchronous thunk action
export function fetchUspostalTest(url: string = '') {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(getUspostalTest());
    try {
      const token = localStorage.getItem('token');
      const getData = await axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const { data } = getData;
      dispatch(getUspostalTestSuccess(data));
    } catch (error) {
      console.log(error);
      dispatch(getUspostalTestFailure());
    }
  }
}





