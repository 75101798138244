const _nav = [
  {
    _tag: "CSidebarNavDropdown",
    name: "Reports",
    route: "/reports",
    icon: "cil-chart-pie",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "US Postal Service",
        to: "/reports/uspostaltest",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Tramites",
        to: "/reports/tramites",
      },
      {
        _tag: "CSidebarNavItem",
        name: "IQ",
        to: "/reports/iq",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Vitals",
        to: "/reports/vitals",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Sagrada",
        to: "/reports/sagrada",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Colosseum",
        to: "/reports/colosseum",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Parc Guell",
        to: "/reports/parcguell",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Customers",
    route: "/reports",
    icon: "cil-puzzle",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "US Postal Service",
        to: "/customers/uspostaltest",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Tramites",
        to: "/customers/tramites",
      },
      {
        _tag: "CSidebarNavItem",
        name: "IQ",
        to: "/customers/iq",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Vitals",
        to: "/customers/vitals",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Sagrada",
        to: "/customers/sagrada",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Colosseum",
        to: "/customers/colosseum",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Parc Guell",
        to: "/customers/parcguell",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Support",
    route: "/reports",
    icon: "cil-puzzle",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "US Postal Service",
        to: "/support/uspostaltest",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Tramites",
        to: "/support/tramites",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Invoices",
    route: "/reports",
    icon: "cil-puzzle",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "US Postal Service",
        to: "/invoices/uspostaltest",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Tramites",
        to: "/invoices/tramites",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Cards",
    to: "/cards",
  },
];

export default _nav;
