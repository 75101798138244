import { CCallout, CCol, CRow } from "@coreui/react";
import { Fragment, useEffect, useState } from "react";
import { APIParcGuell } from "./_reports_ParcGuell";
import { IQ_ReportFilters } from "./Filters";
import { IsLogin } from "../../../features/login/IsLogin";

const today = new Date();

export const Reports_ParcGuell = () => {
  IsLogin();
  const [data, setData] = useState({
    total: 0,
    status: {
      completed: 0,
      "await bot": 0,
      "await ok": 0,
      "await mail": 0,
      revised: 0,
      refund: 0,
      "on hold": 0,
      "payment incompleted": 0,
      "to review": 0,
    },
    // averageBotPurchaseTime: "0",
    // averageAttempts: {
    //   averageAttemptsBot: "0",
    //   averageAttemptsCustomer: "0"
    // },
    // ordersManualPurchase: 0,
    // tickets: {
    //   adult: 0,
    //   under30: 0,
    //   under11: 0,
    // },
  });
  const [filters, setFilters] = useState({
    minCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    maxCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
  });
  const [state, setState] = useState([
    {
      startDate: filters.minCreatedAt,
      endDate: filters.maxCreatedAt,
      key: "selection",
    },
  ]);

  useEffect(() => {
    setFilters({
      ...filters,
      minCreatedAt: state[0].startDate,
      maxCreatedAt: state[0].endDate,
    });
  }, [state]);

  useEffect(() => {
    dataSagrada().then((e: any) => setData(e));
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const dataSagrada = async () => {
    const Api = new APIParcGuell();
    return await Api.requestData(filters);
  };

  const searchHandler = async () => {
    const Api = new APIParcGuell();
    const newData = await Api.requestData(filters);
    setData(newData);
  };

  return localStorage.getItem("parcGuellToken") ? (
    <Fragment>
      <IQ_ReportFilters
        filters={filters}
        setFilters={setFilters}
        searchHandler={searchHandler}
      />
      <CRow className="p-3">
        <CCol sm="1.5 ml-3 mr-4">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Total Orders</strong>
            <br />
            <span className="text-muted">{data ? data.total : 0}</span>
          </CCallout>
        </CCol>
        {/* <CCol sm="1.5 mr-4">
          <CCallout color="secondary" className={"bg-white"}>
            <strong className="">Pending</strong>
            <br />
            <span className="text-muted">{data ? data.pending : 0}</span>
          </CCallout>
        </CCol> */}
        <CCol sm="1.5 mr-4">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Completed</strong>
            <br />
            <span className="text-muted">
              {data ? data.status.completed : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="warning" className={"bg-white"}>
            <strong className="">Await Bot</strong>
            <br />
            <span className="text-muted">
              {data ? data.status["await bot"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="warning" className={"bg-white"}>
            <strong className="">Await OK</strong>
            <br />
            <span className="text-muted">
              {data ? data.status["await ok"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="warning" className={"bg-white"}>
            <strong className="">Await Mail</strong>
            <br />
            <span className="text-muted">
              {data ? data.status["await mail"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="success" className={"bg-white"}>
            <strong className="">Revised</strong>
            <br />
            <span className="text-muted">{data ? data.status.revised : 0}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">On Hold</strong>
            <br />
            <span className="text-muted">
              {data ? data.status["on hold"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="secondary" className={"bg-white"}>
            <strong className="">To Review</strong>
            <br />
            <span className="text-muted">
              {data ? data.status["to review"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">Payment Incompleted</strong>
            <br />
            <span className="text-muted">
              {data ? data.status["payment incompleted"] : 0}
            </span>
          </CCallout>
        </CCol>
      </CRow>
      {/* <CRow>
        <CCol>
          <table id="sagrada-tickets-table">
            <thead>
              <tr>
                <td>Adult</td>
                <td>Under 30</td>
                <td>Child</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>
              {data ? (
                <>
                  <tr>
                    <td>{data.tickets.adult}</td>
                    <td>{data.tickets.under30}</td>
                    <td>{data.tickets.under11}</td>
                    <td>
                      {Object.values(data.tickets).reduce(
                        (prev, next) => prev + next,
                        0
                      )}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCol>
        <CCol>
          <table id="sagrada-tickets-table">
            <thead>
              <tr>
                <td>Avg. Bot Purchase Time</td>
              </tr>
            </thead>
            <tbody>
              {data.averageBotPurchaseTime ? (
                <>
                  <tr>
                    <td>{data.averageBotPurchaseTime}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCol>
        <CCol>
          <table id="sagrada-tickets-table">
            <thead>
              <tr>
                <td>Avg. Bot Attempts</td>
                <td>Avg. Customer Attempts</td>
              </tr>
            </thead>
            <tbody>
              {data.averageAttempts ? (
                <>
                  <tr>
                    <td>{data.averageAttempts.averageAttemptsBot}</td>
                    <td>{data.averageAttempts.averageAttemptsCustomer}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCol>
        <CCol>
          <table id="sagrada-tickets-table">
            <thead>
              <tr>
                <td>Manual Purchase</td>
              </tr>
            </thead>
            <tbody>
              {data.ordersManualPurchase ? (
                <>
                  <tr>
                    <td>{data.ordersManualPurchase}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCol>
      </CRow> */}
    </Fragment>
  ) : (
    <Fragment>Unauthorized </Fragment>
  );
};
