
import { CCol, CFormGroup, CSelect } from '@coreui/react';

export const CustomSelect = ({options}: any) => {
  return(
    <CFormGroup row className="m-1 w-50">
      <CCol xs="10" md="6">
        <CSelect name="select" id="select">
          <option key='-' value='-'>Project</option>
          {
            options.map((option: string, index: any) => {
              return <option key={index} value={option}>{option}</option>
            })
          }
        </CSelect>
      </CCol>
    </CFormGroup>
  )
} 