import { FC } from "react";
import { OrderInputProps } from "../types";

export const OrderInput: FC<OrderInputProps> = ({
  edit,
  value,
  children,
  name,
  id,
  handleChange,
  styles,
  select = false,
  dateInput = false,
}) => {
  const setDataValue = () => {
    const date: string = value.split("/").reverse().join("-");
    return date;
  };
  const setMaxDate = () => {
    const today = new Date();
    let dd: string | number = today.getDate();
    let mm: string | number = today.getMonth() + 3;
    const yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
   }
   
   if (mm < 10) {
      mm = '0' + mm;
   }

   return yyyy + '-' + mm + '-' + dd;
  };
  return (
    <div className={styles.row}>
      <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>
      {edit ? (
        <div>
          {!select && !dateInput ? (
            <input
              style={{
                border: 0,
                borderBottom: "1px solid black",
                background: "transparent",
                padding: 0,
              }}
              type="text"
              name={name}
              id={name + "_" + id}
              value={value}
              onChange={handleChange}
            />
          ) : !dateInput ? (
            <select
              style={{
                border: 0,
                borderBottom: "1px solid black",
                background: "transparent",
                padding: 0,
              }}
              value={value}
              name={name}
              id={name + "_" + id}
              onChange={handleChange}
            >
              <option value="9:00-11:45">9:00 - 11:45</option>
              <option value="12:00-14:45">12:00 - 14:45</option>
              <option value="15:00-19:15">15:00 - 19:15</option>
            </select>
          ) : (
            <input
              style={{
                border: 0,
                borderBottom: "1px solid black",
                background: "transparent",
                padding: 0,
              }}
              type="date"
              name={name}
              max={setMaxDate()}
              id={name + "_" + id}
              value={setDataValue()}
              onChange={handleChange}
            />
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
};
