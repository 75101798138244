import { CModal, CModalHeader } from "@coreui/react";
import React from "react";

interface Props {
  title: string,
  show: boolean,
  toggleTrigger: Function,
}

const Modal: React.FC<Props> = ({ title, show, toggleTrigger, children }) => {
  return (
    <CModal
      show={show}
      onClose={toggleTrigger}
      centered={true}
      className='text-light'
      style={{ backgroundColor: '#3c4b64' }}
    >
      <CModalHeader
        closeButton
        className='border-bottom-0'
        style={{ backgroundColor: 'rgba(0,0,21,0.2)' }}
      >
        <h3 className='m-1'>{title}</h3>
      </CModalHeader>
      {children}
    </CModal>
  )
}

export default Modal;