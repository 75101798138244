import { CBadge, CButton, CCardBody, CCol, CCollapse, CContainer, CDataTable, CForm, CFormGroup, CInputCheckbox, CLabel, CRow, CSpinner } from "@coreui/react";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IsLogin } from "../../../features/login/IsLogin";
import { Search } from "../../../features/search/Search";
import { CustomSelect } from "../../../features/select/Select";
import { Spinner } from "../../../features/spinner/Spinner";
import { UspostalTestSelector } from "../../../slices/uspostaltestSlice";
import { SpecificSearch } from './../../../features/search/SpecificSearch';
import { USPostal_RefundForm } from "./USPostalRefundForm";
import { data, changeStatus, remove, trackUser, downloadMail, showTrackLog } from "./_customersUspostalTest";
import { reportToXlsx } from '../../../features/report-xlsx/report-xlsx'
import { USPostal_ResendCredentials } from "./USPostalResendCredentials";

export function CustomersUspostal() {
  IsLogin();

  const { uspostalTests, loading } = useSelector(UspostalTestSelector);
  let checkboxStatus = ['completed', 'chargeback', 'refund'];

  const [details, setDetails]: any[] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [orderModalData, setOrderModalData] = useState<{value: string, project: string, id: string, order_total: string, email: string}>({ value: '', project: '', id: '', order_total: '', email: '' });
  const refundButtonhandler = (e: React.ChangeEvent<HTMLButtonElement>) => {
    const { value } = e.target;
    const { project, id, order_total } = e.target.dataset;
    if (project && id && order_total) {
      setOrderModalData({ value, project, id, order_total, email: '' });
      setShowModal(!showModal);
    }
  }
  const resendButtonhandler = (e: React.ChangeEvent<HTMLButtonElement>) => {
    const { value } = e.target;
    const { project, id, order_total, email } = e.target.dataset;
    if (project && id && order_total && email) {
      setOrderModalData({ value, project, id, order_total, email });
      setShowModal2(!showModal2);
    }
  }
  const [items, setItems] = useState(uspostalTests)

  const toggleDetails = (index: any) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const fields = [
    { key: "name" },
    { key: "date_created", label: "date created" },
    { key: "id_wc", label: "id woocommerce" },
    { key: "email" },
    { key: "status", _style: { width: "10%" } },
    { key: "show_details", label: "", _style: { width: "1%" }, sorter: false, filter: false, },
  ];

  const getBadge = (status: any) => {
    switch (status) {
      case "completed":
        return "success";
      case "chargeback":
        return "danger";
      case "refund":
        return "secondary";
      default:
        return "primary";
    }
  };

  const HandleReportToXlsx = () => {
    reportToXlsx(items)
  }

  useEffect(() => {
    const usersData = data(uspostalTests);
    setItems(usersData)
  }, [uspostalTests])
  return (
    <>
    <CContainer>
      <CCol className="d-flex mb-1" xs="12" md="12">
        <CustomSelect
          options={[
            'uspostaltest',
          ]}
        />
        <Search showCompare={false} />
      </CCol>
      <CRow className="mb-5 col-sm-10">
        <CCol sm="12">
          <CForm className="d-flex" action="" method="post">
            <CFormGroup variant="checkbox" className="d-flex ">
              {
                checkboxStatus.map((element, index) => {
                  return <CCol key={index} sm="6" className="d-flex">
                    <CInputCheckbox className="checkboxStatus" id={element} name={element} defaultChecked={true} value={element} />
                    <CLabel variant="checkbox" className="form-check-label" htmlFor={element}>{element}</CLabel>
                  </CCol>
                })
              }
            </CFormGroup>
          </CForm>
        </CCol>
      </CRow>
      <SpecificSearch
        options={[
          'name',
          'email',
          'id_wc'
        ]}
        project='uspostaltest'
      />
      <div className="d-flex">
        <CButton
          onClick={HandleReportToXlsx}
          size="sm"
          color="success"
          className="ml-1"
        >
          Download report
        </CButton>
      </div>
      <Spinner visible={loading} />
      {loading ? (
        ""
      ) : (
        <CDataTable
          items={items}
          fields={fields}
          columnFilter
          tableFilter
          footer
          itemsPerPageSelect
          itemsPerPage={10}
          hover
          sorter
          pagination
          scopedSlots={{
            status: (item: any) => (
              <td>
                <CBadge color={getBadge(item.status)}>{item.status}</CBadge>
              </td>
            ),
            show_details: (item: any, index: any) => {
              return (
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      toggleDetails(index);
                    }}
                  >
                    {details.includes(index) ? "Hide" : "Show"}
                  </CButton>
                </td>
              );
            },
            details: (item: any, index: any) => {
              return (
                <Customer item={item} details={details} index={index} refundHandler={refundButtonhandler} resendHandler={resendButtonhandler}/>
              );
            },
          }}
        />
      )}
    </CContainer>
    <USPostal_RefundForm show={showModal} setShow={setShowModal} orderData={orderModalData} />
    <USPostal_ResendCredentials show={showModal2} setShow={setShowModal2} orderData={orderModalData} />
    </>
  );
}

type RefundForm = {
  show: boolean,
  setShow: Function,
  orderData: any
}

type CustomerLogs = {
  item: any,
  details: any,
  index: any,
  refundHandler: Function
  resendHandler: Function
}


const Customer: FC<CustomerLogs> = ({ item, details, index, refundHandler, resendHandler }) => {
  const [showLogs, setShowLogs] = useState([]);
  const renderLogs = (log: any) =>
    <tr>
      <td>{log.email}</td>
      <td>{log.date}</td>
      <td>{log.ip}</td>
      <td>{log.url}</td>
    </tr>
  const trackLogHandler = async (e: any) => {
    const { value } = e.target;
    const newTrack = await showTrackLog(value);
    setShowLogs(newTrack);
  }
  return (
    <CCollapse show={details.includes(index)}>
      <CCardBody>
        <div className="mb-4">
          <CButton onClick={remove} id={item.id} size="sm" color="danger" className="ml-1">
            Permanent Delete
          </CButton>
          <CButton size="sm" color="warning" className="ml-1">
            Update
          </CButton>
          <CButton onClick={changeStatus} id={item.id} value="refund" size="sm" color="secondary" className="ml-1">
            Refund
          </CButton>
          {item.saleId && <CButton  onClick={refundHandler} data-id={item.id} data-project={item.project} data-order_total={item.order_total} value="refund" size="sm" color="secondary" className="ml-1">
            Refund New
          </CButton>}
          {(item.status === 'completed') && <CButton  onClick={resendHandler} data-id={item.id} data-project={item.project} data-order_total={item.order_total} data-email={item.email} value="credentials" size="sm" color="success" className="ml-1">
            Resend Credentials
          </CButton>}
          <CButton onClick={trackUser} id={item.id} value={item.email} size="sm" color="info" className="ml-1 ">
            Download Logs
          </CButton>
          <CButton onClick={trackLogHandler} id={item.id} value={item.email} size="sm" color="info" className="ml-1 ">
            Show Track
          </CButton>
          <CButton onClick={downloadMail} id={item.id} value={item.email} size="sm" color="info" className="ml-1">
            Download mail
          </CButton>
          <div className="d-block">
            <CSpinner data-id={item.id} className="download m-auto none" color="info" />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start">
          <h6 className="text-left"><span className="font-weight-bold">Order Total:</span> {item.order_total}</h6>
          <h6 className="text-left"><span className="font-weight-bold">Payment method:</span> {item.paymentMethod}</h6>
          <h6 className="text-left"><span className="font-weight-bold">Product:</span> {item.product}</h6>
          <h6 className="text-left"><span className="font-weight-bold">Project:</span> {item.project}</h6>
          <h6 className="text-left"><span className="font-weight-bold">Password:</span> {item.password}</h6>
        </div>
        {/* add text for downloadMail */}
        {showLogs.length !== 0 && <table className='table table-sm table-light mt-2 table-borderless'>
          <thead className='thead-dark'>
            <tr>
              <th>Email</th>
              <th>Date</th>
              <th>IP</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            {showLogs.map(renderLogs)}
          </tbody>
        </table>}
        <div id="page"></div>
      </CCardBody>
    </CCollapse>
  );
}
