
import { useDispatch } from "react-redux";
import { CButton, CContainer, CCol, CForm, CFormGroup, CInput, CLabel, CRow, CSelect } from '@coreui/react';

import { fetchTramites } from "../../slices/tramitesSlice";
import { fetchUspostalTest } from "../../slices/uspostaltestSlice";
import { submit } from './_search';

export const SpecificSearch = ({options, project}: any) => {
  const dispatch = useDispatch();
  
  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    let $project: any = document.querySelector("#select");
    if(!$project || $project.value === '-'){
      alert('Choose project')
      return;
    }
    let $selectSearch = document.querySelector("#selectSearch") as HTMLSelectElement;
    let $selectSearchValue = document.querySelector("#selectSearchValue") as HTMLInputElement;
    if(!$selectSearch || $selectSearch.value === '-' || $selectSearchValue.value === ''){
      alert('Choose type of search')
      return;
    }
    let { url } = submit();
    url = `${url}/order?${$selectSearch.value}=${$selectSearchValue.value}`;
    if(project === 'uspostaltest') {
      dispatch(fetchUspostalTest(url))
    }else {
      dispatch(fetchTramites(url));
    }
  }

  return (
    <CContainer>
      <CRow>
        <CCol sm="12">
          <CForm action="" method="post" onSubmit={handleOnSubmit}>
            <CFormGroup className="d-flex align-items-center w-75">
              <CSelect name="select" id="selectSearch">
                <option key='-' value='-'>Select search</option>
                {
                  options.map((option: string, index: any) => {
                    return <option key={index} value={option}>{
                      option === 'id_wc' ? 'id woocommerce' : option  &&
                      option === 'numberrefss' ? 'number ref SS' : option &&
                      option === 'orderid' ? 'order id' : option
                      }</option>
                  })
                }
              </CSelect>
       
              <CLabel htmlFor="nf-date" className="w-50">Value to search for</CLabel>
              <CInput
                type="text"
                id="selectSearchValue"
                name="selectSearchValue"
                placeholder="Enter value.."
              />
            <CButton 
              type='submit'
              key='button1'
              color='info'
              size='sm'
              className="m-2"
            >Search</CButton>
            </CFormGroup>
          </CForm>
        </CCol>
      </CRow>

    </CContainer>
  )
}