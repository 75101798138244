import styles from "./Customers.module.css";
import { Fragment, useEffect, useState } from "react";
import { IsLogin } from "../../../features/login/IsLogin";
import { VitalsCustomersFilters } from "./Filters";
import { requestData } from "./_customersVitals";
import { CCol, CContainer, CRow } from "@coreui/react";
import { VitalsCustomersDataTable } from "./Datatable/DataTable";
import { ConfirmEdit } from "./ConfirmEdit";

const defaultForm = { status: "" };
const today = new Date();
export const CustomersVitals = () => {
  IsLogin();
  const status = [
    "pending",
    "revised",
    "refund",
    "onhold",
    "completed",
    "timeout",
    "chargeback",
  ];

  const [filters, setFilters] = useState({
    minCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    maxCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    status: status.filter((el) => el !== "pending" && el !== "onhold"),
    indCriteria: "orderId",
    indValue: "",
  });
  const [edit, setEdit] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [data, setData]: any[] = useState([]);
  const [individualSearch, setIndividualSearch] = useState(false);
  const [showFloating, setShowFloating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [changes, setChanges]: any = useState({});

  useEffect(() => {
    if (showFloating) {
      setTimeout(() => {
        setShowFloating(false);
      }, 2000);
    }
  }, [showFloating]);

  useEffect(() => {
    setData([]);
    dataIQ().then((e) => setData(e));
  }, []);

  const dataIQ = async () => {
    return await requestData(filters);
  };

  const searchHandler = async () => {
    const newData = await requestData(filters);
    setIndividualSearch(false);
    setData(newData);
    setEdit(!edit);
  };

  const searchOneHandler = async () => {
    const newData = await requestData(filters, true);
    setIndividualSearch(true);
    setData(newData);
    setEdit(!edit);
  };

  const onSaveAttempt = (changes: any) => {    
    setChanges(changes);
    setShowModal(true);
  };

  return localStorage.getItem("vitalsToken") ? (
    <Fragment>
      <VitalsCustomersFilters
        filters={filters}
        setFilters={setFilters}
        searchHandler={searchHandler}
        searchOneHandler={searchOneHandler}
      />
      <CContainer>
        <CRow className="mt-5 ml-0 mr-0">
          <CCol>
            <VitalsCustomersDataTable
              data={data}
              edit={edit}
              setEdit={setEdit}
              setShowFloating={setShowFloating}
              form={form}
              setForm={setForm}
              styles={styles}
              onSaveAttempt={onSaveAttempt}
            />
          </CCol>
        </CRow>
      </CContainer>
      <ConfirmEdit
        show={showModal}
        setShow={setShowModal}
        operationType="Edit"
        changes={changes}
        edit={edit}
        setEdit={setEdit}
        individualSearch={individualSearch}
        searchHandler={searchHandler}
        searchOneHandler={searchOneHandler}
      />
    </Fragment>
  ) : (
    <Fragment>Unauthorized</Fragment>
  );
};
