
import { CHeader, CHeaderBrand, CHeaderNav, CHeaderNavItem, CHeaderNavLink } from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { Logout } from './../login/Logout';

export const Header = () => {
  return (
    <CHeader withSubheader className="mb-4">
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo"/>
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard"></CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem  className="px-3">
          <CHeaderNavLink to="/users"></CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>
      <Logout />
    </CHeader>
  )
  
}

