import { FC } from "react";
import { OrderInputProps } from "../types";

export const OrderInput: FC<OrderInputProps> = ({
  edit,
  value,
  children,
  name,
  id,
  handleChange,
  styles,
  select = false,
}) => {
  return (
    <div className={styles.row}>
      <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>
      {edit ? (
        <div>
          {!select ? (
            <input
              style={{
                border: 0,
                borderBottom: "1px solid black",
                background: "transparent",
                padding: 0,
              }}
              type="text"
              name={name}
              id={name + "_" + id}
              value={value}
              onChange={handleChange}
            />
          ) : (
            <select
              style={{
                border: 0,
                borderBottom: "1px solid black",
                background: "transparent",
                padding: 0,
              }}
              value={value}
              name={name}
              id={name + "_" + id}
              onChange={handleChange}
            >
              <option value="pending">Pending</option>
              <option value="revised">Revised</option>
              <option value="refund">Refund</option>
              <option value="onhold">On Hold</option>
              <option value="chargeback">Changeback</option>
            </select>
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
};
