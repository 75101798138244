import Modal from "../../../features/modal/Modal";
import { FC, Fragment, useEffect, useState } from "react";
import { CButton, CModalBody, CModalFooter } from "@coreui/react";
import { updateOrder } from "./_customersVitals";

type Props = {
  show: boolean;
  setShow: Function;
  changes: any;
  operationType: string;
  setEdit: Function;
  edit: boolean;
  searchHandler: Function;
  searchOneHandler: Function;
  individualSearch: boolean;
};

export const ConfirmEdit: FC<Props> = ({
  show,
  setShow,
  changes,
  operationType,
  setEdit,
  edit,
  searchHandler,
  searchOneHandler,
  individualSearch,
}) => {
  const [processing, setProcessing] = useState(false);
  const [log, setLog] = useState("");
  const handleCancel = () => {
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      setLog("");
      setProcessing(false);
    }
  }, [show]);

  const handleConfirm = async () => {
    setProcessing(true);
    const newOrder = Object.values(changes)
      .filter((el, i) => i !== 0)
      .reduce(
        (obj: any, item: any) => ({ ...obj, [item.field]: item.new }),
        {}
      );
    const _id = Object.values(changes)[0];
    const isUpdated = await updateOrder(_id, newOrder);
    if (isUpdated) {
      setLog(
        `Order updated successfully.`
      );
    } else {
      setLog("Error on update");
    }
    setTimeout(() => {
      setShow(false);
      setEdit(!edit);
      individualSearch ? searchOneHandler() : searchHandler();
    }, 1500);
  };

  const renderRows = (el: any, i: number) => {
    return (
      <tr key={i}>
        <td>{el.old}</td>
        <td>{el.new}</td>
      </tr>
    );
  };

  return (
    <Modal
      show={show}
      toggleTrigger={setShow}
      title={`Confirmation: ${operationType}`}
    >
      <Fragment>
        <CModalBody>
          <div>
            <table className="table table-light table-striped table-borderless table-hover">
              <thead className="thead-dark">
                <tr>
                  <th>Old</th>
                  <th>New</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(changes)
                  .filter((el, i) => i !== 0)
                  .map(renderRows)}
              </tbody>
            </table>
          </div>
          {log && (
            <div>
              <h5>
                {log.split(".").length > 1 ? (
                  <>
                    {log.split(".")[0]}
                    <br />
                    <br />
                    {log.split(".")[1]}
                  </>
                ) : (
                  log
                )}
              </h5>
            </div>
          )}
        </CModalBody>
        {!log && <h5>Confirm operation?</h5>}
        <CModalFooter className="border-top-0">
          <CButton
            color="secondary"
            onClick={handleCancel}
            disabled={processing}
          >
            Cancel
          </CButton>
          <CButton
            className="text-light border-dark"
            disabled={processing}
            onClick={handleConfirm}
            form="refundForm"
            style={{ backgroundColor: "rgba(0,0,21,0.2)" }}
          >
            Confirm
          </CButton>
        </CModalFooter>
      </Fragment>
    </Modal>
  );
};
