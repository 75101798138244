import { CCallout, CCol, CContainer, CRow } from "@coreui/react";
import { Fragment, useEffect, useState } from "react";
import { authIQ, requestData } from "./_reports_IQ";
import { IQ_ReportFilters } from "./Filters";
import { IsLogin } from "../../../features/login/IsLogin";

const today = new Date();

export const Reports_IQ = () => {
  IsLogin();
  const [data, setData] = useState({
    total: 0,
    refund: 0,
    revised: 0,
    chargeback: 0,
    pending: 0,
    onhold: 0,
  });
  const [filters, setFilters] = useState({
    minCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    maxCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
  });
  const [state, setState] = useState([
    {
      startDate: filters.minCreatedAt,
      endDate: filters.maxCreatedAt,
      key: "selection",
    },
  ]);

  useEffect(() => {
    setFilters({
      ...filters,
      minCreatedAt: state[0].startDate,
      maxCreatedAt: state[0].endDate,
    });
  }, [state]);

  useEffect(() => {
    // setData();
    dataIQ().then((e: any) => setData(e));
  }, []);

  const dataIQ = async () => {
    return await requestData(filters);
  };

  const searchHandler = async () => {
    const newData = await requestData(filters);
    setData(newData);
  };

  return localStorage.getItem("IQToken") ? (
    <Fragment>
      <IQ_ReportFilters
        filters={filters}
        setFilters={setFilters}
        searchHandler={searchHandler}
      />
      <CRow className="p-3">
        <CCol sm="1.5 ml-3 mr-4">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Total Orders</strong>
            <br />
            <span className="text-muted">{data ? data.total : 0}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="success" className={"bg-white"}>
            <strong className="">Completed</strong>
            <br />
            <span className="text-muted">{data ? data.revised : 0}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">Chargeback</strong>
            <br />
            <span className="text-muted">{data ? data.refund : 0}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">Refund</strong>
            <br />
            <span className="text-muted">{data ? data.chargeback : 0}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="secondary" className={"bg-white"}>
            <strong className="">Pending</strong>
            <br />
            <span className="text-muted">{data ? data.pending : 0}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="secondary" className={"bg-white"}>
            <strong className="">On Hold</strong>
            <br />
            <span className="text-muted">{data ? data.onhold : 0}</span>
          </CCallout>
        </CCol>
      </CRow>
    </Fragment>
  ) : (
    <Fragment>Unauthorized </Fragment>
  );
};
