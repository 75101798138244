import { CButton, CCol, CCollapse, CContainer, CRow } from "@coreui/react";
import { FC, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { FilterProps } from "./types";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// @ts-ignore
import * as locales from "react-date-range/dist/locale";

export const IQ_ReportFilters: FC<FilterProps> = ({
  filters,
  setFilters,
  searchHandler,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [state, setState] = useState([
    {
      startDate: filters.minCreatedAt,
      endDate: filters.maxCreatedAt,
      key: "selection",
    },
  ]);
  useEffect(() => {
    setFilters({
      ...filters,
      minCreatedAt: state[0].startDate,
      maxCreatedAt: state[0].endDate,
    });
  }, [state]);
  const showHandler = () => setShowFilters(!showFilters);
  const resetFiltersHandler = () => {
    const d = new Date();
    setState([
      {
        startDate: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
        endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
        key: "selection",
      },
    ]);
    setFilters({
      minCreatedAt: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
      maxCreatedAt: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
    });
  };

  return (
    <CContainer fluid>
      <CRow>
        <CCol>
          <h3>Filters</h3>
        </CCol>
        <CCol>
          <CButton color="primary" onClick={showHandler} className={"m-1"}>
            {showFilters ? "Close filters" : "Open filters"}
          </CButton>
          <CButton
            color="primary"
            onClick={resetFiltersHandler}
            className={"m-1"}
          >
            Reset filters
          </CButton>
        </CCol>
        <CCol>
          <CButton onClick={searchHandler} color="primary" className={"m-1"}>
            Search
          </CButton>
        </CCol>
      </CRow>
      <CCollapse show={showFilters}>
        <CRow>
          <CCol sm="12" md="12" lg="6">
            <DateRangePicker
              onChange={(item: any) => {
                setState([item.selection]);
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              weekStartsOn={1}
              locale={locales.enGB}
              maxDate={new Date()}
              ranges={state}
              direction="horizontal"
            />
          </CCol>
        </CRow>
      </CCollapse>
    </CContainer>
  );
};
