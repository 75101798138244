import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// export const signupUser = createAsyncThunk(
//   'users/signupUser',
//   async ({ username, password }: any, thunkAPI) => {
//     try {
//       const response = await fetch(
//         'https:/a',
//         {
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             username,
//             password,
//           }),
//         }
//       );
//       let data = await response.json();

//       if (response.status === 200) {
//         localStorage.setItem('token', data.token);
//         return { ...data, username: username };
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (e) {
//       console.log('Error', e.response.data);
//       return thunkAPI.rejectWithValue(e.response.data);
//     }
//   }
// );

export const loginUser = createAsyncThunk(
  "users/login",

  async ({ username, password }: any, thunkAPI) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRAMITES_BACKEND_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://stage-panel.itcubation.com",
          },
          body: JSON.stringify({
            username,
            password,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("username", username);

        localStorage.removeItem("IQToken");
        try {
          const { data: dataIQ } = await axios.post(
            `${process.env.REACT_APP_IQTEST_BACKEND_URL}/auth/login`,
            {
              email: username,
              password,
            }
          );
          if (dataIQ) {
            localStorage.setItem("IQToken", dataIQ.access_token);
          } else {
            console.log("Unable to create IQ Token");
          }
        } catch (error) {
          console.log(error);
          console.log("Unable to create IQ Token");
        }

        localStorage.removeItem("vitalsToken");
        try {
          const { data: dataVitals } = await axios.post(
            `${process.env.REACT_APP_API_VITALS}/auth/login`,
            {
              email: username,
              password,
            }
          );
          if (dataVitals) {
            localStorage.setItem("vitalsToken", dataVitals.access_token);
          } else {
            console.log("Unable to create Vitals Token");
          }
        } catch (error) {
          console.log(error);
          console.log("Unable to create Vitals Token");
        }

        console.log("Start sagrada login");

        localStorage.removeItem("sagradaToken");
        try {
          const { data: dataSagrada } = await axios.post(
            `${process.env.REACT_APP_API_SAGRADA}/auth/login`,
            {
              email: username,
              password,
            }
          );
          if (dataSagrada) {
            localStorage.setItem("sagradaToken", dataSagrada.access_token);
          } else {
            console.log("Unable to create Sagrada Token");
          }
        } catch (error) {
          console.log(error);
          console.log("Unable to create Sagrada Token");
        }
        console.log("End sagrada login");

        console.log("Start colosseum login");

        localStorage.removeItem("colosseumToken");
        try {
          const { data: dataSagrada } = await axios.post(
            `${process.env.REACT_APP_API_COLOSSEUM}/auth/login`,
            {
              email: username,
              password,
            }
          );
          if (dataSagrada) {
            localStorage.setItem("colosseumToken", dataSagrada.access_token);
          } else {
            console.log("Unable to create Colosseum Token");
          }
        } catch (error) {
          console.log(error);
          console.log("Unable to create Colosseum Token");
        }
        console.log("End Colosseum login");

        console.log("Start Parc Guell login");

        localStorage.removeItem("parcGuellToken");
        try {
          const { data: dataParcGuell } = await axios.post(
            `${process.env.REACT_APP_API_PARC_GUELL}/auth/login`,
            {
              email: username,
              password,
            }
          );
          if (dataParcGuell) {
            localStorage.setItem("parcGuellToken", dataParcGuell.access_token);
          } else {
            console.log("Unable to create Parc Guell Token");
          }
        } catch (error) {
          console.log(error);
          console.log("Unable to create Parc Guell Token");
        }
        console.log("End Parc Guell login");

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchUserBytoken = createAsyncThunk(
  "users/fetchUserByToken",
  async ({ token, username }: any, thunkAPI) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRAMITES_BACKEND_URL}/api/user/${username}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "https://stage-panel.itcubation.com",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200 || response.status === 201) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    email: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    // [signupUser.fulfilled.type]: (state, { payload }) => {
    //   state.isFetching = false;
    //   state.isSuccess = true;
    //   state.email = payload.user.email;
    //   state.username = payload.user.name;
    // },
    // [signupUser.pending.type]: (state) => {
    //   state.isFetching = true;
    // },
    // [signupUser.rejected.type]: (state, { payload }) => {
    //   state.isFetching = false;
    //   state.isError = true;
    //   state.errorMessage = payload.message;
    // },
    [loginUser.fulfilled.type]: (state, { payload }) => {
      state.email = payload.email;
      state.username = payload.username;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected.type]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [loginUser.pending.type]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.pending.type]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.fulfilled.type]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.username = payload.username;
    },
    [fetchUserBytoken.rejected.type]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state: any) => state.user;
