import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


import './App.scss';
import { Sidebar } from './features/sidebar/Sidebar';
import { Header } from './features/header/Header';
import { Home } from './pages/home/Home';
import { ReportsTramites } from './pages/tramites/reports/ReportsTramites';
import { ReportsUspostalTest } from './pages/uspostal/reports/ReportsUspostalTest';
import { CustomersTramites } from './pages/tramites/customers/CustomersTramites';
import { CustomersUspostal } from './pages/uspostal/cusotmers/CustomersUspostal';
import Login from './features/login/Login';
import { PrivateRoute } from './features/private-route/PrivateRoute';
import { CustomersIQ } from './pages/iq/customers/Customers_IQ';
import { Reports_IQ } from './pages/iq/reports/Reports_IQ';
import { CustomersVitals } from './pages/vitals/customers/CustomersVitals';
import { Reports_Vitals } from './pages/vitals/reports/Reports_Vitals';
import { Reports_Sagrada } from './pages/sagrada/reports/Reports_Sagrada';
import { CustomersSagrada } from './pages/sagrada/customers/Customers_Sagrada';
// import { MainReports } from './pages/reports/Reports';
import { Cards } from './pages/cards/Cards';
import { CustomersColosseum } from './pages/colosseum/customers/Customers_Colosseum';
import { Reports_Colosseum } from './pages/colosseum/reports/Reports_Colosseum';
import { CustomersParcGuell } from './pages/parc-guell/customers/Customers_ParcGuell';
import { Reports_ParcGuell } from './pages/parc-guell/reports/Reports_Colosseum';

function App() {
  return (
    <Router>
        <div className="App">
          <Sidebar /> 
          <div className="c-wrapper">
            <Header />
            <Switch>
              <PrivateRoute exact path="/" component={Home} /> 
              <PrivateRoute exact path="/customers/iq" component={CustomersIQ} /> 
              {/* <PrivateRoute exact path="/reports/main" component={MainReports} />  */}
              <PrivateRoute exact path="/reports/iq" component={Reports_IQ} /> 
              <PrivateRoute exact path="/reports/tramites" component={ReportsTramites} />
              <PrivateRoute exact path="/reports/uspostaltest" component={ReportsUspostalTest} />
              <PrivateRoute exact path="/reports/vitals" component={Reports_Vitals} />
              <PrivateRoute exact path="/reports/sagrada" component={Reports_Sagrada} />
              <PrivateRoute exact path="/reports/colosseum" component={Reports_Colosseum} />
              <PrivateRoute exact path="/reports/parcguell" component={Reports_ParcGuell} />
              <PrivateRoute exact path="/customers/tramites" component={CustomersTramites} />
              <PrivateRoute exact path="/customers/uspostaltest" component={CustomersUspostal} />
              <PrivateRoute exact path="/customers/vitals" component={CustomersVitals} />
              <PrivateRoute exact path="/customers/sagrada" component={CustomersSagrada} />
              <PrivateRoute exact path="/customers/colosseum" component={CustomersColosseum} />
              <PrivateRoute exact path="/customers/parcguell" component={CustomersParcGuell} />
              <PrivateRoute exact path="/cards" component={Cards} />
              <Route exact path="/login" component={Login} />
            </Switch>
          </div>
        </div>
    </Router>
  );
}

export default App;
