
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import navigation from './itemsNav';
import styles from './Sidebar.module.css';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../slices/userSlice';

export const Sidebar = () => {

  const { isSuccess } = useSelector(userSelector);

  useEffect(() => {
    
  }, [isSuccess]);

  return (
    <Fragment>
      {isSuccess === false ? '' :
       <CSidebar>
          <CSidebarBrand className="logo c-sidebar-brand d-md-down-none active" to="/">
            <div className={styles.logo}></div>
            <CIcon
              className="c-sidebar-brand-full"
              name="logo-negative"
              height={35}
            />
            <CIcon
              className="c-sidebar-brand-minimized"
              name="sygnet"
              height={35}
            />
          </CSidebarBrand>
          <CSidebarNav>
            <CCreateElement
              items={navigation}
              components={{
                CSidebarNavDivider,
                CSidebarNavDropdown,
                CSidebarNavItem,
                CSidebarNavTitle
              }}
            />
          </CSidebarNav>
          <CSidebarMinimizer className="c-d-md-down-none"/>
        </CSidebar>
      }
     
    </Fragment>
  )
}

