import Modal from "../../../features/modal/Modal";
import { FC, Fragment, useEffect, useState } from "react";
import { CButton, CModalBody, CModalFooter } from "@coreui/react";
import { update } from "./_customersTramites";

type Props = {
  show: boolean;
  setShow: Function;
  data: any;
  operationType: string;
  id: string;
  project: string;
  setEdit: Function;
  preview: any;
};

export const ConfirmEdit: FC<Props> = ({
  setEdit,
  id,
  project,
  show,
  setShow,
  data: form,
  operationType,
  preview,
}) => {
  const [processing, setProcessing] = useState(false);
  const [log, setLog] = useState("");
  const handleCancel = () => {
    setShow(false);
  };
  const diffEdit = [Object.entries(preview), Object.entries(form)];
  const diff = diffEdit[0]
    .map((el, i) =>
      diffEdit[0][i][1] !== diffEdit[1][i][1]
        ? [diffEdit[0][i][0], diffEdit[0][i][1], diffEdit[1][i][1]]
        : null
    )
    .filter((el) => el !== null);
  useEffect(() => {
    if (show) {
      setLog("");
      setProcessing(false);
    }
  }, [show]);

  const handleConfirm = () => {
    setProcessing(true);
    onUpdateConfirmation(id, project);
  };

  const onUpdateConfirmation = async (id: string, project: string) => {
    const body = {
      titular: {
        nombre: form.nombre,
        primerApellido: form.primerApellido,
        segundoApellido: form.segundoApellido,
        movil: form.movil,
        date: form.date,
        numeroSeguridadSocial: {
          parte1NAf: form.parte1NAf,
          parte2NAf: form.parte2NAf,
        },
        email: form.email,
        identificacion: {
          tipo: form.tipo,
          numero: form.numeroDni,
          caducidad: form.caducidad,
        },
        domicilio: {
          tipoVia: form.tipoVia,
          nombreVia: form.nombreVia,
          numero: form.numero,
          bis: form.bis,
          bloque: form.bloque,
          escalera: form.escalera,
          piso: form.piso,
          puerta: form.puerta,
          cp: form.cp,
          provincia: form.provincia,
          localidad: form.localidad,
          pais: form.pais,
        },
      },
      sms: form.sms,
      numeroReferenciaSS: form.numeroReferenciaSS,
      status: form.status,
      comment: form.comment,
      error: form.error,
      tipoSolicitud: form.tipoSolicitud,
    };
    const updated = await update(body, id, project, diff);
    if (updated) {
      setLog(
        `Order updated successfully. The changes will be visible on the next filter query.`
      );
    } else {
      setLog("Error on update");
    }
    setTimeout(() => {
      setShow(false);
      setEdit(false);
    }, 3000);
  };

  const renderRows = (el: any, i: number) => {
    return (
      <tr key={i}>
        <td>{el[0]}</td>
        <td>{el[1]}</td>
        <td>{el[2]}</td>
      </tr>
    );
  };

  return (
    <Modal
      show={show}
      toggleTrigger={setShow}
      title={`Confirmation: ${operationType}`}
    >
      <Fragment>
        <CModalBody>
          {!log ? (
            <div>
              <table className="table table-light table-striped table-borderless table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Attribute</th>
                    <th>Old</th>
                    <th>New</th>
                  </tr>
                </thead>
                <tbody>{Object.values(diff).map(renderRows)}</tbody>
              </table>
            </div>
          ) : (
            <div>
              <h5>
                {log.split(".").length > 1 ? (
                  <>
                    {log.split(".")[0]}
                    <br />
                    <br />
                    {log.split(".")[1]}
                  </>
                ) : (
                  log
                )}
              </h5>
            </div>
          )}
        </CModalBody>
        {!log && <h5>Confirm operation?</h5>}
        <CModalFooter className="border-top-0">
          <CButton
            color="secondary"
            onClick={handleCancel}
            disabled={processing}
          >
            Cancel
          </CButton>
          <CButton
            className="text-light border-dark"
            disabled={processing}
            onClick={handleConfirm}
            form="refundForm"
            style={{ backgroundColor: "rgba(0,0,21,0.2)" }}
          >
            Confirm
          </CButton>
        </CModalFooter>
      </Fragment>
    </Modal>
  );
};
