
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const initialState = {
  loading: false,
  hasErrors: false,
  tramites: [],
}

// A slice for recipes with our 3 reducers
const tramitesSlice = createSlice({
  name: 'tramites',
  initialState,
  reducers: {
    getTramites: state => {
      state.loading = true
    },
    getTramitesSuccess: (state, { payload }) => {
      state.tramites = payload
      state.loading = false
      state.hasErrors = false
    },
    getTramitesFailure: state => {
      state.loading = false
      state.hasErrors = true
      state.tramites = []
    },
  },
})
export const { getTramites, getTramitesSuccess, getTramitesFailure } = tramitesSlice.actions

// A selector
export const TramitesSelector = (state: { tramites: any }) => state.tramites

// The reducer
export default tramitesSlice.reducer

// Asynchronous thunk action
export function fetchTramites(url: string = '') {
  return async (dispatch: (arg0: any) => void) => {
    dispatch(getTramites());
    try {
      const token = localStorage.getItem('token');
      const getData = await axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const { data } = getData;
      dispatch(getTramitesSuccess(data));
    } catch (error) {      
      console.log(error);
      dispatch(getTramitesFailure());
    }
  }
}





