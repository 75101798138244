import XLSX from "xlsx";

export const reportToXlsx = async (orders: any[]) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(orders);
  XLSX.utils.book_append_sheet(wb, ws, "");
  let date = new Date();
  let nameFile = `report-${date.getDate()}_${
    date.getMonth() + 1
  }_${date.getFullYear()}.xlsx`;
  XLSX.writeFile(wb, nameFile);
};