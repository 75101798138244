import {
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CDataTable,
} from "@coreui/react";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { useSelector } from "react-redux";
import { data } from "../../../pages/tramites/customers/_customersTramites";
import { TramitesSelector } from "../../../slices/tramitesSlice";
import { Spinner } from "../../spinner/Spinner";
import {
  chartsWeek,
  chartsMonth,
  chartsDay,
  chartsHour,
  chartsCR,
} from "./_charts";

export const Charts = () => {
  const { tramites, loading } = useSelector(TramitesSelector);

  // const dataWeek = chartsWeek(tramites);
  // const dataMonth = chartsMonth(tramites);
  const dataDay = chartsDay(tramites);
  // const dataHours = chartsHour(tramites);

  const dataCR = chartsCR(tramites);

  const renderTable = (el: any, i: number) => {
    return (
      <tr key={i}>
        <td>{el}</td>
        <td>{dataCR.total[i]}</td>
        <td>{dataCR.revised[i]}</td>
        <td>{dataCR.data[i]} %</td>
      </tr>
    );
  };

  const addTotalAR = () => {
    if (!dataCR.labels.length) return null;
    const total = dataCR.total.reduce((a: number, b: number) => a + b);
    const revised = dataCR.revised.reduce((a: number, b: number) => a + b);
    return <tr>
      <td>Total</td>
      <td>{total}</td>
      <td>{revised}</td>
      <td>{((revised/total) * 100).toFixed(2)} %</td>
    </tr>
  }

  return (
    <div className="container-fluid">
      <Spinner visible={loading} />
      {loading ? (
        ""
      ) : (
        <CCardGroup columns style={{ columnCount: 1 }}>
          <CCard style={{maxHeight: "550px"}}>
            <CCardHeader>Acceptance Ratio</CCardHeader>
            <CCardBody style={{ display: "flex", maxHeight: "500px" }}>
              <CCard style={{ width: "50%" }}>
                <CCardHeader>Graph</CCardHeader>
                <CCardBody style={{height: "100%", display: "flex", alignItems: "center"}}>
                  <CChartLine
                  style={{height: "fit-content", width: "100%"}}
                    datasets={[
                      {
                        label: "AR",
                        backgroundColor: "rgb(0,216,255,0.9)",
                        data: dataCR.data,
                        hidden: false,
                        borderColor: "rgb(0,216,255,0.9)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgb(0,216,255,0.9)",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "rgb(0,216,255,0.9)",
                        pointHoverBorderColor: "rgba(220,220,220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        fill: false,
                        lineTension: 0.1,
                      },
                    ]}
                    options={{
                      tooltips: {
                        enabled: true,
                      },
                    }}
                    labels={dataCR.labels}
                  />
                </CCardBody>
              </CCard>

              {/* <CCard
                style={{
                  width: "50%",
                  maxHeight: "350px",
                }}
              > */}
                {/* <CCardHeader>AR Table</CCardHeader> */}
                <div style={{ width: "50%", overflow: "auto", maxHeight: "inherit"}}>
                <table className="ar_table" style={{overflowY: "scroll"}}>
                  <thead style={{ background: "lightgray" }}>
                    <tr>
                      <th>Day</th>
                      <th>Total</th>
                      <th>Revised</th>
                      <th>AR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataCR.labels.map(renderTable)}
                    {addTotalAR()}
                  </tbody>
                </table>
                </div>
              {/* </CCard> */}
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>Orders per days</CCardHeader>
            <CCardBody>
              <CChartLine
                datasets={[
                  {
                    label: "Pending",
                    backgroundColor: "rgb(0,216,255,0.9)",
                    data: dataDay.pending,
                    hidden: true,
                    borderColor: "rgb(0,216,255,0.9)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    pointBorderColor: "rgb(0,216,255,0.9)",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgb(0,216,255,0.9)",
                    pointHoverBorderColor: "rgba(220,220,220,1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    fill: false,
                    lineTension: 0.1,
                  },
                  {
                    label: "Revised",
                    backgroundColor: "rgb(46,232,8,0.8)",
                    data: dataDay.revised,
                    borderColor: "rgb(46,232,8,0.8)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    pointBorderColor: "rgb(46,232,8,0.8)",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgb(46,232,8,0.8)",
                    pointHoverBorderColor: "rgba(220,220,220,1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,

                    fill: false,
                    lineTension: 0.1,
                  },
                  {
                    label: "Refund",
                    backgroundColor: "rgb(128,129,128,0.8)",
                    data: dataDay.refund,
                    borderColor: "rgb(128,129,128,0.8)s",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: "miter",
                    pointBorderColor: "rgb(128,129,128,0.8)s",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgb(128,129,128,0.8)s",
                    pointHoverBorderColor: "rgba(220,220,220,1)",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,

                    fill: false,
                    lineTension: 0.1,
                  },
                ]}
                options={{
                  tooltips: {
                    enabled: true,
                  },
                }}
                labels={dataDay.labels}
              />
            </CCardBody>
          </CCard>
        </CCardGroup>
      )}
    </div>
  );
};
