import styles from "./Customers.module.css";
import {
  CBadge,
  CButton,
  CCol,
  CCollapse,
  CContainer,
  CDataTable,
  CRow,
} from "@coreui/react";
import { IsLogin } from "../../../features/login/IsLogin";
import { ChangeEventHandler, FC, Fragment, useEffect, useState } from "react";
import { authIQ, requestData, setCorrectMaxDate } from "./_customers_IQ";
import { IQ_CustomersFilters } from "./Filters";
import { IQ_CustomersDataTable } from "./DataTable/DataTable";
import { ConfirmEdit } from "./ConfirmEdit";

const defaultForm = {
  name: "",
  lastName: "",
  age: "",
  email: "",
  status: "",
};
const today = new Date();

export const CustomersIQ = () => {
  IsLogin();
  const status = ["pending", "revised", "refund", "onhold", "chargeback"];
  const [edit, setEdit] = useState(false);
  const [showFloating, setShowFloating] = useState(false);
  const [filters, setFilters] = useState({
    minCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    maxCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    status: status.filter((el) => el !== "pending" && el !== "onhold"),
    indCriteria: "orderId",
    indValue: "",
  });
  const [form, setForm] = useState(defaultForm);
  const [showModal, setShowModal] = useState(false);
  const [changes, setChanges]: any = useState({});
  const [data, setData]: any[] = useState([]);
  const [individualSearch, setIndividualSearch] = useState(false);

  useEffect(() => {
    if (showFloating) {
      setTimeout(() => {
        setShowFloating(false);
      }, 2000);
    }
  }, [showFloating]);

  useEffect(() => {
    setData([]);
    dataIQ().then((e) => setData(e));
  }, []);

  const dataIQ = async () => {
    return await requestData(filters);
  };

  const searchHandler = async () => {
    const newData = await requestData(filters);
    setIndividualSearch(false);
    setData(newData);
    setEdit(!edit);
  };

  const searchOneHandler = async () => {
    const newData = await requestData(filters, true);
    setIndividualSearch(true);
    setData(newData);
    setEdit(!edit);
  };

  const onSaveAttempt = (changes: any) => {
    setChanges(changes);
    setShowModal(true);
  };

  return localStorage.getItem("IQToken") ? (
    <Fragment>
      <IQ_CustomersFilters
        filters={filters}
        setFilters={setFilters}
        searchHandler={searchHandler}
        searchOneHandler={searchOneHandler}
      />
      <CContainer fluid>
        <CRow className="mt-5 ml-0 mr-0">
          <CCol>
            <IQ_CustomersDataTable
              data={data}
              edit={edit}
              setEdit={setEdit}
              setShowFloating={setShowFloating}
              form={form}
              setForm={setForm}
              styles={styles}
              onSaveAttempt={onSaveAttempt}
            />
          </CCol>
        </CRow>
      </CContainer>
      <div
        className="bg-success"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: "5px 15px",
          display: showFloating ? "block" : "none",
        }}
      >
        Copied to clipboard.
      </div>
      <ConfirmEdit
        show={showModal}
        setShow={setShowModal}
        operationType="Edit"
        changes={changes}
        edit={edit}
        setEdit={setEdit}
        individualSearch={individualSearch}
        searchHandler={searchHandler}
        searchOneHandler={searchOneHandler}
      />
    </Fragment>
  ) : (
    <Fragment>Unauthorired </Fragment>
  );
};
