export const statusCount = (tramites: any) => {
  _data = {
    pending: 0,
    timeout: 0,
    failed: 0,
    failedAddress: 0,
    failedMatch: 0,
    revised: 0,
    refund: 0,
    completed: 0,
    validCard: 0,
    dniError: 0,
    notRegistered: 0,
    withoutMobile: 0,
    rejected: 0,
    onHold: 0,
    awaitResponse: 0,
    awaitBot: 0,
  };
  for (let i = 0; i < tramites.length; i++) {
    console.log(tramites[i]);

    if (!tramites[i].order.parentId) _sumStatusInData(tramites[i]);
  }
  return _data;
};

export const tagsCount = (tramites: any) => {
  const test: { [x: string]: { total: number; perc: number } } = {
    no_Tag: {
      total: tramites.filter((el: any) => el.order.tag === "" || !el.order.tag)
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "" || !el.order.tag)
          .length / tramites.length
      ),
    },
    no_Introduce: {
      total: tramites.filter((el: any) => el.order.tag === "No Introduce")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "No Introduce").length /
          tramites.length
      ),
    },
    no_introduce: {
      total: tramites.filter((el: any) => el.order.tag === "No introduce")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "No introduce").length /
          tramites.length
      ),
    },
    beneficiario_no_Cubierto: {
      total: tramites.filter(
        (el: any) => el.order.tag === "Beneficiario no Cubierto"
      ).length,
      perc: Number(
        tramites.filter(
          (el: any) => el.order.tag === "Beneficiario no Cubierto"
        ).length / tramites.length
      ),
    },
    error_Desconocido: {
      total: tramites.filter((el: any) => el.order.tag === "Error desconocido")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Error desconocido")
          .length / tramites.length
      ),
    },
    beneficiario_Inexistente: {
      total: tramites.filter(
        (el: any) => el.order.tag === "Beneficiario Inexistente"
      ).length,
      perc: Number(
        tramites.filter(
          (el: any) => el.order.tag === "Beneficiario Inexistente"
        ).length / tramites.length
      ),
    },
    IPF_y_NAF: {
      total: tramites.filter((el: any) => el.order.tag === "IPF y NAF").length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "IPF y NAF").length /
          tramites.length
      ),
    },
    SS_Caida: {
      total: tramites.filter((el: any) => el.order.tag === "SS Caída").length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "SS Caída").length /
          tramites.length
      ),
    },
    situacion_Solicitante: {
      total: tramites.filter(
        (el: any) => el.order.tag === "Situación Solicitante"
      ).length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Situación Solicitante")
          .length / tramites.length
      ),
    },
    datos_Identificacion: {
      total: tramites.filter(
        (el: any) => el.order.tag === "Datos Identificación"
      ).length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Datos Identificación")
          .length / tramites.length
      ),
    },
    tarjeta_en_Vigor: {
      total: tramites.filter((el: any) => el.order.tag === "Tarjeta en Vigor")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Tarjeta en Vigor")
          .length / tramites.length
      ),
    },
    "codigo/PIN_Incorrecto": {
      total: tramites.filter(
        (el: any) => el.order.tag === "Código/PIN Incorrecto"
      ).length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Código/PIN Incorrecto")
          .length / tramites.length
      ),
    },
    movil_Distinto: {
      total: tramites.filter((el: any) => el.order.tag === "Móvil distinto")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Móvil distinto").length /
          tramites.length
      ),
    },
    sin_Numero_Movil: {
      total: tramites.filter(
        (el: any) => el.order.tag === "Sin Número de móvil"
      ).length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Sin Número de móvil")
          .length / tramites.length
      ),
    },
    "DNI/Fecha_no_Registrado": {
      total: tramites.filter(
        (el: any) => el.order.tag === "DNI/Fecha no registrado"
      ).length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "DNI/Fecha no registrado")
          .length / tramites.length
      ),
    },
    SMS_Incorrecto: {
      total: tramites.filter((el: any) => el.order.tag === "SMS Incorrecto")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "SMS Incorrecto").length /
          tramites.length
      ),
    },
    error_Direccion: {
      total: tramites.filter((el: any) => el.order.tag === "Error Dirección")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Error Dirección")
          .length / tramites.length
      ),
    },
    datos_Identificativos: {
      total: tramites.filter(
        (el: any) => el.order.tag === "Datos Identificativos"
      ).length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Datos Identificativos")
          .length / tramites.length
      ),
    },
    peticion_Rechazada: {
      total: tramites.filter((el: any) => el.order.tag === "Petición Rechazada")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Petición Rechazada")
          .length / tramites.length
      ),
    },
    error_Formato: {
      total: tramites.filter((el: any) => el.order.tag === "Error Formato")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "Error Formato").length /
          tramites.length
      ),
    },
    nSS_Incorrecto: {
      total: tramites.filter((el: any) => el.order.tag === "nSS Incorrecto")
        .length,
      perc: Number(
        tramites.filter((el: any) => el.order.tag === "nSS Incorrecto").length /
          tramites.length
      ),
    },
  };

  const compare = (a: any, b: any) =>
    a[1].perc > b[1].perc ? -1 : b[1].perc > a[1].perc ? 1 : 0;
  // console.log(Object.values(test).map((el) => +(el * 100).toFixed(2)));
  const callback = Object.entries(test).sort(compare);
  return callback;
};

const _sumStatusInData = (tramite: any): void => {
  if (tramite.onHold) {
    _data.onHold = _data.onHold + 1;
  }
  if (tramite.awaitResponse) {
    _data.awaitResponse = _data.awaitResponse + 1;
  }
  if (tramite.dniError) {
    _data.dniError = _data.dniError + 1;
  }
  if (tramite.notRegistered) {
    _data.notRegistered = _data.notRegistered + 1;
  }
  if (tramite.withoutMobile) {
    _data.withoutMobile = _data.withoutMobile + 1;
  }
  if (tramite.rejected) {
    _data.rejected = _data.rejected + 1;
  }
  if (tramite.pending) {
    _data.pending = _data.pending + 1;
  }
  if (tramite.timeout) {
    _data.timeout = _data.timeout + 1;
  }
  if (tramite.failed) {
    _data.failed = _data.failed + 1;
  }
  if (tramite.failedAddress) {
    _data.failedAddress = _data.failedAddress + 1;
  }
  if (tramite.validCard) {
    _data.validCard = _data.validCard + 1;
  }
  if (tramite.failedMatch) {
    _data.failedMatch = _data.failedMatch + 1;
  }
  if (tramite.revised) {
    _data.revised = _data.revised + 1;
  }
  if (tramite.refund) {
    _data.refund = _data.refund + 1;
  }
  if (tramite.completed) {
    _data.completed = _data.completed + 1;
  }
  if (tramite.order.status === "await bot") {
    _data.awaitBot = _data.awaitBot + 1;
  }
};

let _data: any = {
  pending: 0,
  timeout: 0,
  failed: 0,
  failedAddress: 0,
  failedMatch: 0,
  revised: 0,
  refund: 0,
  completed: 0,
  validCard: 0,
  dniError: 0,
  notRegistered: 0,
  withoutMobile: 0,
  rejected: 0,
  onHold: 0,
  awaitResponse: 0,
  awaitBot: 0,
};
