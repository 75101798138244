import { CButton, CModalFooter } from "@coreui/react";
import { FC, useEffect, useState } from "react";
import Modal from "../../../features/modal/Modal";
import { makeRefund } from "./_customers_Sagrada";

type Props = {
  show: boolean;
  setShow: Function;
  order: { [x: string]: any };
  setOrder: Function;
  // id: string
};

export const SagradaRefundModal: FC<Props> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [reason, setReason] = useState("Tickets not received");
  const [otherReason, setOtherReason] = useState("");
  const [log, setLog] = useState("");
  const handleCancel = () => {
    props.setShow(false);
  };

  useEffect(() => {
    if (props.show) {
      setLog("");
      setReason("Tickets not received");
      setOtherReason("");
      setAmount(undefined);
      setProcessing(false);
    }
  }, [props.show]);

  const handleConfirm = async () => {
    const isRefundDone = await makeRefund(props.order._id, {
      amount: amount,
      refundType: amount === props.order.totalPrice ? "Total" : "Fee",
      reason: reason === "Otros" ? otherReason : reason,
    });
    isRefundDone
      ? setLog("Refund done successfully")
      : setLog("Error in order processing");
    setTimeout(() => {
      props.setShow(false);
    }, 1500);
  };

  return (
    <Modal show={props.show} toggleTrigger={props.setShow} title={"Refund"}>
      <div>
        <div className="sagrada-refund-form">
          <div>
            <h5>Reason: </h5>
          </div>
          <div>
            <select
              value={reason}
              onChange={({ target }) => setReason(target.value)}
              className="selectReason"
              style={{
                padding: "4px 7px",
                background: "transparent",
                color: "#fff",
                border: 0,
                borderBottom: "2px solid #fff",
                outline: "none",
              }}
            >
              <option value={"Peticion del cliente"}>
                Petición del cliente
              </option>
              <option value={"No hay disponibilidad"}>
                No hay disponibilidad
              </option>
              <option value={"Error en el pago"}>Error en el pago</option>
              <option value={"Mail del cliente bloqueado"}>
                Mail del cliente bloqueado
              </option>
              <option value={"Await bot"}>Await bot</option>
              <option value={"Chromewebdata"}>Chromewebdata</option>
              <option value={"Suspicious order"}>Suspicious order</option>
              <option value={"Otros"}>Otros</option>
            </select>
          </div>
        </div>
        {reason === "Otros" ? (
          <div className="sagrada-refund-form">
            <input
              type="text"
              name=""
              id=""
              placeholder="Other reason..."
              className="otherReason"
              value={otherReason}
              onChange={({ target }) => setOtherReason(target.value)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="sagrada-refund-amount">
        <div>
          <h5>Amount: </h5>
        </div>
        {props.order.tickets && (
          <div className="sagrada-refund-amount">
            <div>
              <input
                type="radio"
                name="amount"
                value={amount}
                checked={amount === Number(props.order.totalPrice)}
                onClick={() => {
                  setAmount(Number(props.order.totalPrice));
                }}
              />{" "}
              Total ( {props.order.totalPrice} € )
            </div>
            <div>
              <input
                type="radio"
                name="amount"
                value={amount}
                checked={
                  amount ===
                  props.order.tickets.reduce((prev: number, next: any) => {
                    if (next.type === "adult")
                      return prev + Number(next.amount) * 10;
                    if (next.type === "under 30 years old")
                      return prev + Number(next.amount) * 12;
                    return prev;
                  }, 0)
                }
                onClick={() => {
                  setAmount(
                    props.order.tickets &&
                      props.order.tickets.reduce((prev: number, next: any) => {
                        if (next.type === "adult")
                          return prev + Number(next.amount) * 10;
                        if (next.type === "under 30 years old")
                          return prev + Number(next.amount) * 12;
                        return prev;
                      }, 0)
                  );
                }}
              />{" "}
              Only fees ({" "}
              {props.order.tickets &&
                props.order.tickets.reduce((prev: number, next: any) => {
                  if (next.type === "adult")
                    return prev + Number(next.amount) * 10;
                  if (next.type === "under 30 years old")
                    return prev + Number(next.amount) * 12;
                  return prev;
                }, 0)}{" "}
              € )
            </div>
          </div>
        )}
      </div>
      {log && <div>{log}</div>}
      <CModalFooter className="border-top-0">
        <CButton color="secondary" onClick={handleCancel} disabled={processing}>
          Cancel
        </CButton>
        <CButton
          className="text-light border-dark"
          disabled={processing || amount === undefined || !reason}
          onClick={handleConfirm}
          form="refundForm"
          style={{ backgroundColor: "rgba(0,0,21,0.2)" }}
        >
          Confirm
        </CButton>
      </CModalFooter>
    </Modal>
  );
};
