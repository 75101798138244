import { Card } from "./../types/Card.type";
import axios from "axios";
import { to } from "await-to-js";

const url = process.env.REACT_APP_API_SAGRADA;
const getToken = () => {
  return localStorage.getItem("sagradaToken");
};

const headers = () => {
  return { headers: { Authorization: `Bearer ${getToken()}` } };
};

const requestCards = async () => {
  const [err, response] = await to(
    axios.get<Card[]>(`${url}/api/card`, headers())
  );
  if (err || !response) return null;
  console.log(response.data);
  return response.data;
};

const postCards = async (
  cards: { numberCard: number; cvv: string; month: string; year: string }[]
) => {
  const [err, response] = await to(
    axios.post<any>(`${url}/api/card/cascade`, cards, headers())
  );
  if (err || !response) return false;
  console.log(response.data);
  return true;
};

const deleteCard = async (numberCard: number) => {
  const [err, response] = await to(
    axios.delete<any>(`${url}/api/card/${numberCard}`, headers())
  );
  if (err || !response) return false;
  console.log(response.data);
  return true;
};

export default {
  requestCards,
  postCards,
  deleteCard,
};
