import Select from "react-select";
import { Charts } from "../../../features/tramites/charts/Charts";
import { Search } from "../../../features/search/Search";
import { CustomSelect } from "../../../features/select/Select";
import { CCol, CCallout, CRow, CForm, CFormGroup } from "@coreui/react";
import { useSelector } from "react-redux";
import { TramitesSelector } from "../../../slices/tramitesSlice";
import { statusCount, tagsCount } from "./_reportsTramites";
import { IsLogin } from "../../../features/login/IsLogin";
import { CChart } from "@coreui/react-chartjs";

export const ReportsTramites = () => {
  const { tramites } = useSelector(TramitesSelector);

  const status = statusCount(tramites);
  const tags = tagsCount(tramites);
  const defaultOptions = [
    { value: "dni error", label: "dni error" },
    { value: "not registered", label: "not registered" },
    { value: "without mobile", label: "without mobile" },
    { value: "rejected", label: "rejected" },
    { value: "time out", label: "time out" },
    { value: "failed", label: "failed" },
    { value: "failed address", label: "failed address" },
    { value: "failed no match", label: "falied no match" },
    { value: "valid card", label: "valid card" },
    { value: "revised", label: "revised" },
    { value: "completed", label: "completed" },
    { value: "refund", label: "refund" },
    { value: "on hold", label: "on hold" },
    { value: "await response", label: "await response" },
    { value: "await bot", label: "await bot" },
  ];
  const options = [{ value: "pending", label: "pending" }, ...defaultOptions];

  IsLogin();

  const renderTags = (
    el: [
      string,
      {
        total: number;
        perc: number;
      }
    ],
    i: number
  ) => {
    return (
      <tr>
        <td>
          {el[0].charAt(0).toUpperCase() +
            el[0].substring(1).replaceAll("_", " ")}
        </td>
        <td>{(el[1].perc * 100).toFixed(2)} %</td>
        <td>{el[1].total}</td>
      </tr>
    );
  };

  return (
    <div>
      <CCol className="d-flex" xs="12" md="12">
        <CustomSelect options={["Tarjeta sanitaria", "Vida laboral"]} />
        <Search showCompare={true} />
      </CCol>

      <CRow className="p-3">
        <CCol sm="1.5 ml-3 mr-4">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Total Orders</strong>
            <br />
            <span className="text-muted">
              {status.failed +
                status.failedAddress +
                status.failedMatch +
                status.validCard +
                status.pending +
                status.timeout +
                status.revised +
                status.completed +
                status.refund +
                status.dniError +
                status.notRegistered +
                status.withoutMobile +
                status.rejected +
                status.onHold +
                status.awaitBot +
                status.awaitResponse}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="success" className={"bg-white"}>
            <strong className="">Revised</strong>
            <br />
            <span className="text-muted">{status.revised}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Rejected</strong>
            <br />
            <span className="text-muted">{status.rejected}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">Refund</strong>
            <br />
            <span className="text-muted">{status.refund}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">On Hold</strong>
            <br />
            <span className="text-muted">{status.onHold}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4 ml-3">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Await Response</strong>
            <br />
            <span className="text-muted">{status.awaitResponse}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4 ml-3">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Await Bot</strong>
            <br />
            <span className="text-muted">{status.awaitBot}</span>
          </CCallout>
        </CCol>
      </CRow>

      <CRow className="mb-3 col-sm-10">
        <CCol sm="12">
          <CForm className="d-flex" action="" method="post">
            <CFormGroup variant="checkbox" className="d-flex ">
              <Select
                defaultValue={defaultOptions}
                options={options}
                isMulti={true}
                placeholder="Select status..."
              />
            </CFormGroup>
          </CForm>
        </CCol>
      </CRow>
      {tramites.length ? (
        <div className="col-md-10">
          <h4>Tags</h4>
          <div className="chart-wrapper">
            <table>
              <thead>
                <tr>
                  <td>
                    <b>Tag</b>
                  </td>
                  <td>
                    <b>Perc</b>
                  </td>
                  <td>
                    <b>Absolute</b>
                  </td>
                </tr>
              </thead>
              <tbody>{tags.map(renderTags)}</tbody>
            </table>
          </div>
          <hr />
        </div>
      ) : null}
      <Charts />
    </div>
  );
};
