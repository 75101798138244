import axios from "axios";
import { addDays, subSeconds } from "date-fns";

export const setCorrectMaxDate = (maxDate: Date) =>
  subSeconds(addDays(maxDate, 1), 1);

export const authIQ = async (pwd: string) => {
  const url = process.env.REACT_APP_IQTEST_BACKEND_URL;
  const localUser = localStorage.getItem("username");
  try {
    const { data } = await axios.post(`${url}/auth/login`, {
      email: `${localUser}`,
      password: pwd,
    });
    return data.access_token;
  } catch (error) {
    return null;
  }
};

export const requestData = async (filters: any) => {
  const url = process.env.REACT_APP_IQTEST_BACKEND_URL;
  const token = localStorage.getItem("IQToken");
  // const token = await authIQ();
  if (!token) return null;

  try {
    const { data } = await axios.get(
      `${url}/orders/report/?minDate=${
        filters.minCreatedAt
      }&maxDate=${setCorrectMaxDate(filters.maxCreatedAt)}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
