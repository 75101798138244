import { Fragment } from "react";

import { IsLogin } from "../../features/login/IsLogin";
import { CCallout, CCol, CRow } from "@coreui/react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { useSagradaCards } from "./hooks/useCards";
import { columns } from "./data/columns";
import { Delete } from "@material-ui/icons";

export const Cards = () => {
  IsLogin();

  const {
    cards,
    counters,
    rows,
    itemsPerPage,
    importCards,
    onPageSizeChange,
    getApiCards,
    getCardsCounters,
    parseRaw,
    addNewCards,
    deleteCardHandler,
  } = useSagradaCards();

  const renderCallout = (val: [string, number], i: number) => {
    return (
      <CCallout
        color={["dark", "success", "warning", "danger"][i]}
        className={"bg-white summary-callout"}
      >
        <strong>{val[0].charAt(0).toUpperCase() + val[0].substring(1)}</strong>
        <span className="text-muted">{val[1]}</span>
      </CCallout>
    );
  };

  return (
    <Fragment>
      <CRow>
        <CCol>
          {/* <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={dataSource}
            style={gridStyle}
          /> */}
          {!importCards.length ? (
            <div className="row-cards-counters">
              <label htmlFor="file-upload" className="custom-file-upload">
                Custom Upload
              </label>
              <input
                style={{ display: "none" }}
                onChange={parseRaw}
                id="file-upload"
                type="file"
                accept=".xlsx, .xls, .csv"
              />
            </div>
          ) : (
            ""
          )}
          {importCards.length ? (
            <div className="cards-confirm">
              <div style={{ padding: "10px 0" }}>
                {importCards.length} new cards ready to be added.
              </div>
              <div style={{ padding: "10px 0" }}>
                <button onClick={addNewCards} style={{ padding: "5px 10px" }}>
                  Confirm
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row-cards-counters">
            {Object.entries(counters).map(renderCallout)}
          </div>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={[
                ...columns,
                {
                  field: "actions",
                  hide: false,
                  width: 100,
                  headerName: " ",
                  renderCell: (params) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {params.row.status === "Inactive" ? (
                          <Delete
                            className="actionButton"
                            onClick={() =>
                              deleteCardHandler(params.row.numberCard)
                            }
                          />
                        ) : (
                          ""
                        )}
                        {/* <button className="toInactive"></button>
                      <button className="toDeleted"></button> */}
                      </div>
                    );
                  },
                },
              ]}
              pageSize={itemsPerPage}
              onPageSizeChange={onPageSizeChange}
              pagination
              rowsPerPageOptions={[5, 10]}
              density="compact"
              disableColumnMenu={false}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </CCol>
      </CRow>
    </Fragment>
  );
};
