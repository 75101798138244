

export const submit = () => {
  const dateInit = _getDateInit();
  const dateFinish = _getDateFinish();
  let url = `${_project()}`;
  let $status: any = document.querySelectorAll('.css-12jo7m5');
  let status = '';
  let $project = document.querySelector("#select") as HTMLInputElement;
  let project = $project.value;
  for (let i = 0; i < $status.length; i++) {
    status += `&status=${$status[i].textContent}`;
  }
  return { dateInit, dateFinish, url, status, project };
} 

const _project = (): string  => {
  let $project = document.querySelector("#select") as HTMLInputElement;
  if($project.value.toLowerCase() === 'tarjeta sanitaria') {
    return process.env.REACT_APP_TARJETA_SANITARIA_URL || '';
  }
  if($project.value.toLowerCase() === 'vida laboral') {
    return process.env.REACT_APP_API_VIDA_LABORAL_URL || '';
  }
  if($project.value.toLowerCase() === 'uspostaltest') {
    return process.env.REACT_APP_USPOSTALTEST_BACKEND_URL || '';
  }
  return '';
}

const _getDateInit = (): string => {
  const today = `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`;
  let $dateInit: any = document.querySelector("#nf-date-init");
  if($dateInit) {
    return `&dateInit=${$dateInit.value}`;
  }
  return today 
}

const _getDateFinish = (): string => {
  const today = `${new Date().getMonth()}/${new Date().getDate()}/${new Date().getFullYear()}`;
  let $dateFinish: any = document.querySelector("#nf-date-finish");
  if($dateFinish) {
    return `&dateFinish=${$dateFinish.value}`;
  }
  return today
}

