import { CBadge, CButton, CCollapse, CDataTable } from "@coreui/react";
import { FC, useState } from "react";
import { Form, ColosseumOrder } from "../types";
import { downloadBill } from "../_customers_Colosseum";
import { OrderDetails } from "./OrderDetails";

export const Colosseum_CustomersDataTable: FC<{
  data: ColosseumOrder[];
  edit: boolean;
  setEdit: Function;
  setShowFloating: Function;
  setShowRefundModal: Function;
  form: Form;
  setForm: Function;
  styles: any;
  onSaveAttempt: Function;
  onManualPurchase: Function;
  setFileName: Function;
  orderBy: string;
}> = ({
  data,
  edit,
  setEdit,
  setShowFloating,
  setShowRefundModal,
  form,
  setForm,
  styles,
  onSaveAttempt,
  onManualPurchase,
  setFileName,
  orderBy,
}) => {
  const [details, setDetails]: any[] = useState([]);
  const fields = [
    { key: "fullName", _style: { width: "20%" } },
    { key: "email", _style: { width: "20%" }, filter: true },
    { key: "createdAt", _style: { width: "10%" }, filter: true },
    { key: "wc_ref", _style: { width: "10%" } },
    // { key: "product", _style: { width: "20%" } },
    { key: "status", _style: { width: "15%" } },
    // { key: "bill", _style: { width: "10%" }, filter: false },
    {
      key: "show_details",
      label: "",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];
  const fields2 = [
    { key: "email", _style: { width: "20%" }, filter: false },
    { key: "wc_ref", _style: { width: "10%" } },
    { key: "ticketsDate", _style: { width: "15%" }, filter: false },
    { key: "timeRange", _style: { width: "15%" }, filter: false },
    { key: "status", _style: { width: "15%" } },
    // { key: "bill", _style: { width: "10%" }, filter: false },
    {
      key: "show_details",
      label: "",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];

  const getBadge = (status: any) => {
    switch (status) {
      case "revised":
        return "success";
      case "onhold":
        return "secondary";
      case "refund":
        return "danger";
      case "chargeback":
        return "danger";
      default:
        return "primary";
    }
  };

  const rowToggle = (id: number) => {
    setEdit(!edit);
    const position = details.indexOf(id);
    position !== -1 ? setDetails([]) : setDetails([id]);
    setFileName("");
  };

  const pdfDownloadHandler = async (ids: string[]) => {
    downloadBill(ids);
  };

  return (
    <CDataTable
      items={data}
      fields={orderBy === "purchaseDate" ? fields : fields2}
      itemsPerPage={10}
      sorter
      columnFilter
      // columnFilterValue={[{ client: "client" }]}
      pagination
      onPageChange={() => setEdit(!edit)}
      scopedSlots={{
        status: (item: ColosseumOrder) => (
          <td>
            <CBadge color={getBadge(item.status)}>{item.status}</CBadge>
          </td>
        ),
        // bill: (item: ColosseumOrder) =>
        //   item.hasBillSaved ? (
        //     <td style={{ height: "18px" }}>
        //       <img
        //         src="/pdf.svg"
        //         style={{ width: "18px", height: "inherit", cursor: "pointer" }}
        //         onClick={() => pdfDownloadHandler([item._id])}
        //       />
        //     </td>
        //   ) : (
        //     <td style={{ height: "18px" }}>-</td>
        //   ),
        show_details: (item: ColosseumOrder, index: number) => {
          return (
            <td className="py-2">
              <CButton
                color="primary"
                variant="outline"
                shape="square"
                size="sm"
                onClick={() => {
                  rowToggle(index);
                }}
              >
                {details.includes(index) ? "Hide" : "Show"}
              </CButton>
            </td>
          );
        },
        details: (item: ColosseumOrder, index: number) => {
          return (
            <CCollapse show={details.includes(index)}>
              <OrderDetails
                activeEdit={edit}
                setActiveEdit={setEdit}
                order={item}
                setFloating={setShowFloating}
                setRefundModal={setShowRefundModal}
                form={form}
                setForm={setForm}
                styles={styles}
                onSaveAttempt={onSaveAttempt}
                onManualPurchase={onManualPurchase}
                setFileName={setFileName}
              />
            </CCollapse>
          );
        },
      }}
    />
  );
};
