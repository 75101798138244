import { IsLogin } from "../../features/login/IsLogin";

export function Home() {

  IsLogin();

  return (
    <div>
    </div>
  );
}
