import { CBadge } from "@coreui/react";
import { GridColDef } from "@material-ui/data-grid";

export const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "_id", hide: true, width: 230 },
  {
    field: "numberCard",
    headerName: "Card Number",
    width: 150,
    renderCell: (params) => {
      return `**** **** **** ${String(params.row.numberCard).slice(-4)}`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    align: "center",
    width: 120,
    renderCell: (params) => {
      return (
        <CBadge
          color={
            params.row.status === "Active"
              ? "success"
              : params.row.status === "Inactive"
              ? "warning"
              : "danger"
          }
        >
          {params.row.status}
        </CBadge>
      );
    },
    valueGetter: (params) => params.row.status,
  },
  {
    field: "numRevised",
    headerName: "Revised",
    type: "number",
    width: 130,
  },
  {
    field: "numOnHold",
    headerName: "On Hold",
    type: "number",
    width: 130,
  },
  {
    field: "lastUsed",
    headerName: "Last Use",
    type: "string",
    flex: 1,
    minWidth: 170,
  },
  {
    field: "lastTransactionId",
    headerName: "Last Transaction ID",
    type: "string",
    flex: 1,
    minWidth: 270,
  },
  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params: GridValueGetterParams) =>
  //     `${params.getValue(params.id, "firstName") || ""} ${
  //       params.getValue(params.id, "lastName") || ""
  //     }`,
  // },
];
