import { Charts } from "../../../features/uspostal/charts/Charts";
import { Search } from "../../../features/search/Search";
import { CustomSelect } from "../../../features/select/Select";
import {
  CCol,
  CCallout,
  CRow,
  CForm,
  CFormGroup,
  CInputCheckbox,
  CLabel,
} from "@coreui/react";
import { useSelector } from "react-redux";
import { UspostalTestSelector } from "../../../slices/uspostaltestSlice";
import {
  statusCount,
  conversionData,
  tableSummary,
} from "../../uspostal/reports/_reportsUspostalTest";
import { IsLogin } from "../../../features/login/IsLogin";
import { CChart } from "@coreui/react-chartjs";

export const ReportsUspostalTest = () => {
  const { uspostalTests } = useSelector(UspostalTestSelector);
  const status = statusCount(uspostalTests);

  const domainConversion = conversionData(uspostalTests);
  const tableData = tableSummary(uspostalTests, domainConversion);
  console.table(domainConversion);

  let checkboxStatus = ["completed", "chargeback", "refund"];

  IsLogin();

  const pie = {
    labels: domainConversion.map((log) => log.domain),
  };

  const randomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const tags = {
    datasets: [
      {
        //data: [tramites.filter((el: any) => (el.order.tag = "No Introduce")).length, 50, 100],
        data: domainConversion.map((log) => +(log.rate * 100).toFixed(2)),
        backgroundColor: domainConversion.map((log) => randomColor()),
        hoverOffset: 4,
      },
    ],
  };

  const renderConversionLogs = (row: {
    domain: string;
    orders: number;
    orderPct: number;
    orderPay: number;
    crPct: number;
  }) => {
    return (
      <tr>
        <td style={{ textAlign: "left" }}>
          <b>{row.domain}</b>
        </td>
        <td style={{ textAlign: "right" }}>{row.orders}</td>
        <td style={{ textAlign: "right" }}>{row.orderPct.toFixed(2)} %</td>
        <td style={{ textAlign: "right" }}>{row.orderPay}</td>
        <td style={{ textAlign: "right" }}>
          {!Number.isFinite(row.crPct) ? "-" : (row.crPct * 100).toFixed(2)} %
        </td>
      </tr>
    );
  };

  return (
    <div>
      <CCol className="d-flex" xs="12" md="12">
        <CustomSelect options={["uspostaltest"]} />
        <Search showCompare={true} />
      </CCol>

      <CRow className="p-3">
        <CCol sm="1.5 ml-3 mr-4">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Total Orders</strong>
            <br />
            <span className="text-muted">
              {status.chargeback + status.completed + status.refund}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="success" className={"bg-white"}>
            <strong className="">Completed</strong>
            <br />
            <span className="text-muted">{status.completed}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">Chargeback</strong>
            <br />
            <span className="text-muted">{status.chargeback}</span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="secondary" className={"bg-white"}>
            <strong className="">Refund</strong>
            <br />
            <span className="text-muted">{status.refund}</span>
          </CCallout>
        </CCol>
      </CRow>

      <CRow className="mb-3 col-sm-10">
        <CCol sm="12">
          <CForm className="d-flex" action="" method="post">
            <CFormGroup variant="checkbox" className="d-flex ">
              {checkboxStatus.map((element, index) => {
                return (
                  <CCol key={index} sm="6" className="d-flex">
                    <CInputCheckbox
                      className="checkboxStatus"
                      id={element}
                      value={element}
                      name={element}
                      defaultChecked={true}
                    />
                    <CLabel
                      variant="checkbox"
                      className="form-check-label"
                      htmlFor={element}
                    >
                      {element}
                    </CLabel>
                  </CCol>
                );
              })}
            </CFormGroup>
          </CForm>
        </CCol>
      </CRow>
      <div className="col-md-10">
        <div className="chart-wrapper" style={{ display: "flex" }}>
          {/* <div style={{ width: "50%" }}>
            <h4>Conversion Graph</h4>

            <CChart
              type="pie"
              datasets={tags.datasets}
              labels={pie.labels}
              options={{
                tooltips: {
                  custom: function (model) {
                    console.log(model);
                    if (model && model.body)
                      model.body[0].lines = [model.body[0].lines + " %"];
                    model.width = model.width + 15;
                  },
                },
              }}
            />
          </div> */}
          <div style={{ width: "100%" }}>
            {uspostalTests.length ? (
              <table className="conversion-table">
                <thead>
                  <tr>
                    <th colSpan={5}>Conversion Distribution</th>
                  </tr>
                  <tr style={{ background: "lightgray" }}>
                    <th style={{ textAlign: "left" }}>Web Trasaccional</th>
                    <th>Orders</th>
                    <th>% Orders</th>
                    <th>Order Pay</th>
                    <th>CR %</th>
                  </tr>
                </thead>
                <tbody>{tableData.map(renderConversionLogs)}</tbody>
              </table>
            ) : null}
          </div>
        </div>
        <hr />
      </div>
      <Charts />
    </div>
  );
};
