import { CCard, CCardBody, CCardGroup, CCardHeader } from "@coreui/react";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { useSelector } from "react-redux";
import { UspostalTestSelector } from "../../../slices/uspostaltestSlice";
import { Spinner } from "../../spinner/Spinner";
import { chartsWeek, chartsMonth, chartsDay, chartsHour } from "./_charts";

export const Charts = () => {
  const { uspostalTests, loading } = useSelector(UspostalTestSelector);

  const dataWeek = chartsWeek(uspostalTests);
  const dataMonth = chartsMonth(uspostalTests);
  const dataDay = chartsDay(uspostalTests);
  const dataHours = chartsHour(uspostalTests);

  return (
    <div className="container-fluid">
      <Spinner visible={loading} />
      {loading ? (
        ""
      ) : (
        <CCardGroup columns className="cols-2">
          {/* <CCard>
            <CCardHeader>Orders per months</CCardHeader>
            <CCardBody>
              <CChartBar
                datasets={[
                  {
                    label: "Completed",
                    backgroundColor: "rgb(46,232,8,0.5)",
                    data: dataMonth.completed,
                  },
                  {
                    label: "Chargeback",
                    backgroundColor: "rgb(46,232,8,0.8)",
                    data: dataMonth.chargeback,
                  },
                  {
                    label: "Refund",
                    backgroundColor: "rgb(128,129,128,0.8)",
                    data: dataMonth.refund,
                  },
                ]}
                options={{
                  tooltips: {
                    enabled: true,
                  },
                }}
                labels={dataMonth.labels}
              />
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader>Orders per weeks</CCardHeader>
            <CCardBody>
              <CChartBar
                datasets={[
                  {
                    label: "Completed",
                    backgroundColor: "rgb(46,232,8,0.5)",
                    data: dataWeek.completed,
                  },
                  {
                    label: "Chargeback",
                    backgroundColor: "rgb(46,232,8,0.8)",
                    data: dataWeek.chargeback,
                  },
                  {
                    label: "Refund",
                    backgroundColor: "rgb(128,129,128,0.8)",
                    data: dataWeek.refund,
                  },
                ]}
                options={{
                  tooltips: {
                    enabled: true,
                  },
                }}
                labels={dataWeek.labels}
              />
            </CCardBody>
          </CCard> */}

          {/* <CCard>
            <CCardHeader>Orders per days</CCardHeader>
            <CCardBody>
              <CChartBar
                datasets={[
                  {
                    label: "Chargeback",
                    backgroundColor: "rgb(46,232,8,0.8)",
                    data: dataDay.chargeback,
                  },
                  {
                    label: "Completed",
                    backgroundColor: "rgb(46,232,8,0.5)",
                    data: dataDay.completed,
                  },
                  {
                    label: "Refund",
                    backgroundColor: "rgb(128,129,128,0.8)",
                    data: dataDay.refund,
                  },
                ]}
                options={{
                  tooltips: {
                    enabled: true,
                  },
                }}
                labels={dataDay.labels}
              />
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader>Orders per hours</CCardHeader>
            <CCardBody>
              <CChartBar
                datasets={[
                  {
                    label: "Chargeback",
                    backgroundColor: "rgb(46,232,8,0.8)",
                    data: dataHours.chargeback,
                  },
                  {
                    label: "Completed",
                    backgroundColor: "rgb(46,232,8,0.5)",
                    data: dataHours.completed,
                  },
                  {
                    label: "Refund",
                    backgroundColor: "rgb(128,129,128,0.8)",
                    data: dataHours.refund,
                  },
                ]}
                options={{
                  tooltips: {
                    enabled: true,
                  },
                }}
                labels={dataHours.labels}
              />
            </CCardBody>
          </CCard> */}

          <CCard>
            <CCardHeader>Orders per days</CCardHeader>
            <CCardBody>
              <CChartLine
                datasets={[
                  {
                    label: "Chargeback",
                    backgroundColor: "rgb(46,232,8,0.8)",
                    data: dataDay.chargeback,
                    borderColor: 'rgb(46,232,8,0.8)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgb(46,232,8,0.8)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgb(46,232,8,0.8)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    fill: false,
                    lineTension: 0.1,
                  },
                  {
                    label: "Completed",
                    backgroundColor: "rgb(46,232,8,0.5)",
                    data: dataDay.completed,
                    borderColor: 'rgb(46,232,8,0.5)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgb(46,232,8,0.5)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgb(46,232,8,0.5)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    fill: false,
                    lineTension: 0.1,
                  },
                  {
                    label: "Refund",
                    backgroundColor: "rgb(128,129,128,0.8)",
                    data: dataDay.refund,
                    borderColor: 'rgb(128,129,128,0.8)s',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgb(128,129,128,0.8)s',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgb(128,129,128,0.8)s',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,      
                    fill: false,
                    lineTension: 0.1,
                  },
                ]}
                options={{
                  tooltips: {
                    enabled: true,
                  },
                }}
                labels={dataDay.labels}
              />
            </CCardBody>
          </CCard>

        </CCardGroup>
      )}
    </div>
  );
};
