import { CButton, CModalFooter } from "@coreui/react";
import { FC, useEffect, useState } from "react";
import Modal from "../../../features/modal/Modal";
import { uploadFile } from "./_customers_Colosseum";

type Props = {
  showFileModal: boolean;
  setShowFileModal: Function;
  fileName: { order?: string; name?: string; file?: any	 };
	setFileName: Function;
};

export const FileModal: FC<Props> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [log, setLog] = useState("");
  const handleCancel = () => {
    props.setShowFileModal(false);
  };

  useEffect(() => {
    if (props.showFileModal) {
      setLog("");
      setProcessing(false);
    }
  }, [props.showFileModal]);

  const handleConfirm = async () => {
    const isUpdated =
      props.fileName.file && props.fileName.name && props.fileName.order
        ? await uploadFile({
            file: props.fileName.file,
            name: props.fileName.name,
            order: props.fileName.order,
          })
        : false;
    isUpdated
      ? setLog("Tickets file updated successfully.")
      : setLog("Error on uploading");

    setTimeout(() => {
			setLog("");
      props.setShowFileModal(false);
			props.setFileName({})
    }, 1500);
  };

  return (
    <Modal
      show={props.showFileModal}
      toggleTrigger={props.setShowFileModal}
      title={`Ticket Uploading`}
    >
      <div className="mt-4">
        <div>
          <strong>Selected File:</strong> {props.fileName.name}
        </div>
        <div>
          <b>
            <span className="mt-3 mb-3" style={{ display: "block" }}>
              Replace actual <i>tickets file</i> with the selected one?
            </span>
          </b>
        </div>
      </div>
      {log && <div>{log}</div>}
      <CModalFooter className="border-top-0">
        <CButton color="secondary" onClick={handleCancel} disabled={processing}>
          Cancel
        </CButton>
        <CButton
          className="text-light border-dark"
          disabled={processing}
          onClick={handleConfirm}
          form="refundForm"
          style={{ backgroundColor: "rgba(0,0,21,0.2)" }}
        >
          Confirm
        </CButton>
      </CModalFooter>
    </Modal>
  );
};
