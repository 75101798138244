import XLSX from "xlsx";
import { useEffect, useState } from "react";
import { Card } from "../types/Card.type";
import { CardRow } from "../types/CardRow.type";
import API from "./_card";

export const useSagradaCards = () => {
  const [rows, setRows] = useState<CardRow[]>([]);
  const [cards, setCards] = useState<Card[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [importCards, setImportCards] = useState<
    { numberCard: number; cvv: string; month: string; year: string }[]
  >([]);
  const [counters, setCounters] = useState({
    total: 0,
    active: 0,
    inactive: 0,
    deleted: 0,
  });

  useEffect(() => {
    getApiCards();
  }, []);

  useEffect(() => {
    getCardsCounters();

    setRows(
      cards.map((card, i) => ({
        id: i,
        ...card,
        lastUsed: card.lastUsed
          ? new Date(card.lastUsed).toLocaleString("en-GB")
          : "",
      }))
    );
  }, [cards]);

  const getApiCards = async (): Promise<void> => {
    const cards = await API.requestCards();
    if (cards)
      setCards(
        cards.sort((prev, next) => {
          if (prev.status === "Active" && prev.status !== next.status)
            return -1;
          if (prev.status !== "Active" && prev.status !== next.status) return 1;
          return 0;
        })
      );
  };

  const getCardsCounters = () => {
    const newCounters = cards.reduce(
      (acc, next) => {
        const status = next.status;
        return {
          total: acc.total + 1,
          active: status === "Active" ? acc.active + 1 : acc.active,
          inactive: status === "Inactive" ? acc.inactive + 1 : acc.inactive,
          deleted: status === "Deleted" ? acc.deleted + 1 : acc.deleted,
        };
      },
      {
        total: 0,
        active: 0,
        inactive: 0,
        deleted: 0,
      }
    );
    setCounters(newCounters);
  };

  const onPageSizeChange = (page: number) => setItemsPerPage(page);

  const parseRaw = (e: any) => {
    var reader = new FileReader();
    reader.onload = function (e: any) {
      GetTableFromExcel(e.target.result);
    };
    reader.readAsBinaryString(e.target.files[0]);
    // const rootFile = e.currentTarget.value;
    // const target = e.target as HTMLInputElement;
    // const file: File = (target.files as FileList)[0];

    // const res = XLSX.read(file, { type: "array" });
    // console.log(Buffer.from(e.target.files[0]));
  };

  const GetTableFromExcel = (data: any) => {
    var workbook = XLSX.read(data, {
      type: "binary",
    });
    //get the name of First Sheet.
    var Sheet = workbook.SheetNames[0];
    var excelRows: any[] = XLSX.utils.sheet_to_json(workbook.Sheets[Sheet]);
    // let difference = arr1.filter(x => !arr2.includes(x));
    const currCards = cards.map((card) => card.numberCard);
    const newCards = excelRows
      .filter((row: any) => !currCards.includes(Number(row["Nº TARJETA"])))
      .map((row) => ({
        numberCard: Number(row["Nº TARJETA"]),
        month: String(row["EXP DATE"].split("/")[0]),
        year: String(row["EXP DATE"].split("/")[1]),
        cvv: String(row["CVV"]),
      }));
    setImportCards(newCards);

    // console.log(
    //   excelRows.filter(
    //     (row: any, i: number, self: any[]) => self.indexOf(row) === i
    //   )
    // );
  };

  const addNewCards = async () => {
    const isDone = await API.postCards(importCards);
    console.log(isDone);
    setImportCards([]);
    getApiCards();
    return isDone;
  };

  const deleteCardHandler = async (numberCard: number) => {
    await API.deleteCard(numberCard);
    getApiCards();
  };

  return {
    cards,
    counters,
    rows,
    itemsPerPage,
    importCards,

    getApiCards,
    getCardsCounters,
    onPageSizeChange,
    parseRaw,
    addNewCards,
    deleteCardHandler,
  };
};
