import { CCallout, CCard, CCol, CCollapse, CRow } from "@coreui/react";
import { Fragment, useEffect, useState } from "react";
import { requestData } from "./_reports_Sagrada";
import { IQ_ReportFilters } from "./Filters";
import { IsLogin } from "../../../features/login/IsLogin";

const today = new Date();

interface ReportData {
  total: number;
  numberOfOrdersByStatus: { [x: string]: number };
  averageBotPurchaseTime: string;
  averageAttempts: {
    averageAttemptsBot: string;
    averageAttemptsCustomer: string;
  };
  ordersManualPurchase: number;
  tickets: {
    adult: number;
    under30: number;
    under11: number;
  };
  refundData: {
    reasons: string[];
    counter: {
      total: number;
      partial: number;
    };
  };
}

export const Reports_Sagrada = () => {
  IsLogin();
  const [data, setData] = useState<ReportData>({
    total: 0,
    numberOfOrdersByStatus: {
      refund: 0,
      pending: 0,
      completed: 0,
      ["await bot"]: 0,
      ["await ok"]: 0,
      ["await mail"]: 0,
      revised: 0,
      ["on hold"]: 0,
      ["payment incompleted"]: 0,
      ["to review"]: 0,
    },
    averageBotPurchaseTime: "0",
    averageAttempts: {
      averageAttemptsBot: "0",
      averageAttemptsCustomer: "0",
    },
    ordersManualPurchase: 0,
    tickets: {
      adult: 0,
      under30: 0,
      under11: 0,
    },
    refundData: {
      reasons: [],
      counter: {
        total: 0,
        partial: 0,
      },
    },
  });
  const [sortedRefundReasons, setSortedRefundReasons] = useState<{
    [x: string]: number;
  }>({});
  const [filters, setFilters] = useState({
    minCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
    maxCreatedAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ),
  });
  const [state, setState] = useState([
    {
      startDate: filters.minCreatedAt,
      endDate: filters.maxCreatedAt,
      key: "selection",
    },
  ]);

  useEffect(() => {
    setFilters({
      ...filters,
      minCreatedAt: state[0].startDate,
      maxCreatedAt: state[0].endDate,
    });
  }, [state]);

  useEffect(() => {
    // setData();
    dataSagrada().then((e: any) => setData(e));
  }, []);

  useEffect(() => {
    console.log(data);
    const reasonsSummary: { [x: string]: number } = {};
    data.refundData.reasons.forEach(
      (reason) => (reasonsSummary[reason] = (reasonsSummary[reason] || 0) + 1)
    );

    // Object.keys(reasonsSummary)
    setSortedRefundReasons(reasonsSummary);
  }, [data]);

  const dataSagrada = async () => {
    return await requestData(filters);
  };

  const searchHandler = async () => {
    const newData = await requestData(filters);
    setData(newData);
  };

  const renderRefundReasonRows = (reason: string) => {
    return (
      <tr>
        <td>{reason}</td>
        <td>{sortedRefundReasons[reason]}</td>
      </tr>
    );
  };

  return localStorage.getItem("sagradaToken") ? (
    <Fragment>
      <IQ_ReportFilters
        filters={filters}
        setFilters={setFilters}
        searchHandler={searchHandler}
      />
      <CRow className="p-3">
        <CCol sm="1.5 ml-3 mr-4">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Total Orders</strong>
            <br />
            <span className="text-muted">{data ? data.total : 0}</span>
          </CCallout>
        </CCol>
        {/* <CCol sm="1.5 mr-4">
          <CCallout color="secondary" className={"bg-white"}>
            <strong className="">Pending</strong>
            <br />
            <span className="text-muted">{data ? data.pending : 0}</span>
          </CCallout>
        </CCol> */}
        <CCol sm="1.5 mr-4">
          <CCallout color="info" className={"bg-white"}>
            <strong className="">Completed</strong>
            <br />
            <span className="text-muted">
              {data ? data.numberOfOrdersByStatus["completed"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="warning" className={"bg-white"}>
            <strong className="">Await Bot</strong>
            <br />
            <span className="text-muted">
              {data ? data.numberOfOrdersByStatus["await bot"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="warning" className={"bg-white"}>
            <strong className="">Await OK</strong>
            <br />
            <span className="text-muted">
              {data ? data.numberOfOrdersByStatus["await ok"] : 0}
            </span>
          </CCallout>
        </CCol>
        {/* <CCol sm="1.5 mr-4">
          <CCallout color="warning" className={"bg-white"}>
            <strong className="">Await Mail</strong>
            <br />
            <span className="text-muted">{data ? data.awaitMail : 0}</span>
          </CCallout>
        </CCol> */}
        <CCol sm="1.5 mr-4">
          <CCallout color="success" className={"bg-white"}>
            <strong className="">Revised</strong>
            <br />
            <span className="text-muted">
              {data ? data.numberOfOrdersByStatus["revised"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">On Hold</strong>
            <br />
            <span className="text-muted">
              {data ? data.numberOfOrdersByStatus["on hold"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="secondary" className={"bg-white"}>
            <strong className="">To Review</strong>
            <br />
            <span className="text-muted">
              {data ? data.numberOfOrdersByStatus["to review"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">Payment Incompleted</strong>
            <br />
            <span className="text-muted">
              {data ? data.numberOfOrdersByStatus["payment incompleted"] : 0}
            </span>
          </CCallout>
        </CCol>
        <CCol sm="1.5 mr-4">
          <CCallout color="danger" className={"bg-white"}>
            <strong className="">Refund</strong>
            <br />
            <span className="text-muted">
              {data ? data.numberOfOrdersByStatus["refund"] : 0}
            </span>
          </CCallout>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <table id="sagrada-tickets-table">
            <thead>
              <tr>
                <td>Adult</td>
                <td>Under 30</td>
                <td>Child</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>
              {data ? (
                <>
                  <tr>
                    <td>{data.tickets.adult}</td>
                    <td>{data.tickets.under30}</td>
                    <td>{data.tickets.under11}</td>
                    <td>
                      {Object.values(data.tickets).reduce(
                        (prev, next) => prev + next,
                        0
                      )}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCol>
        <CCol>
          <table id="sagrada-tickets-table">
            <thead>
              <tr>
                <td>Avg. Bot Purchase Time</td>
              </tr>
            </thead>
            <tbody>
              {data.averageBotPurchaseTime ? (
                <>
                  <tr>
                    <td>{data.averageBotPurchaseTime}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCol>
        <CCol>
          <table id="sagrada-tickets-table">
            <thead>
              <tr>
                <td>Avg. Bot Attempts</td>
                <td>Avg. Customer Attempts</td>
              </tr>
            </thead>
            <tbody>
              {data.averageAttempts ? (
                <>
                  <tr>
                    <td>{data.averageAttempts.averageAttemptsBot}</td>
                    <td>{data.averageAttempts.averageAttemptsCustomer}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCol>
        <CCol>
          <table id="sagrada-tickets-table">
            <thead>
              <tr>
                <td>Manual Purchase</td>
              </tr>
            </thead>
            <tbody>
              {data.ordersManualPurchase ? (
                <>
                  <tr>
                    <td>{data.ordersManualPurchase}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <h4>Refund Summary</h4>
          <CCollapse show={true} style={{ display: "flex" }}>
            <CCard
              style={{ width: 100, height: "fit-content", margin: "0 5px" }}
            >
              <table style={{ border: "2px solid black" }}>
                <tbody>
                  <tr>
                    <th>Total</th>
                    <td>
                      {data.refundData ? data.refundData.counter.total : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Partial</th>
                    <td>
                      {data.refundData ? data.refundData.counter.partial : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCard>
            <CCard style={{ width: 300, margin: "0 5px" }}>
              <table style={{ border: "2px solid black" }}>
                <thead>
                  <tr>
                    <th colSpan={2}>Refund reasons</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(sortedRefundReasons).map(renderRefundReasonRows)}
                </tbody>
              </table>
            </CCard>
          </CCollapse>
        </CCol>
      </CRow>
    </Fragment>
  ) : (
    <Fragment>Unauthorized </Fragment>
  );
};
