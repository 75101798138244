import axios from "axios";
import { addDays, subSeconds } from "date-fns";

export const setCorrectMaxDate = (maxDate: Date) =>
  subSeconds(addDays(maxDate, 1), 1);

export const authSagrada = async (pwd: string) => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const localUser = localStorage.getItem("username");
  try {
    const { data } = await axios.post(`${url}/auth/login`, {
      email: `${localUser}`,
      password: pwd,
    });
    return data.access_token;
  } catch (error) {
    return null;
  }
};

export const requestData = async (
  filters: any
): Promise<{ total: number; status: any }> => {
  const url = process.env.REACT_APP_API_COLOSSEUM;
  const token = localStorage.getItem("colosseumToken");
  if (!token) throw new Error("No API");
  try {
    const { data } = await axios.get(
      `${url}/api/reports/report?minDate=${filters.minCreatedAt.toISOString()}&maxDate=${setCorrectMaxDate(
        filters.maxCreatedAt
      ).toISOString()}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log(data);

    return {
      total: Object.values(data).reduce<number>(
        (prev: any, next: any) => Number(prev) + Number(next),
        0
      ),
      status: data,
    };
  } catch (error) {
    console.log(error);
    return {
      total: 0,
      status: {
        completed: 0,
        "await bot": 0,
        "await ok": 0,
        "await mail": 0,
        revised: 0,
        refund: 0,
        "on hold": 0,
        "payment incompleted": 0,
        "to review": 0,
      },
    };
  }
};
